import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../configs/colorConfigs";
import { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import baseURL from "../../configs/api";
import axios from "axios";
import axois from "../../configs/axois";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import PleaseWaitPopup from "../../pages/PleaseWaitPopup";
import Webcam from "react-webcam";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useAuth from "../hooks/useAuth";
import { post_comment } from "../../services/comment";
import { getVehicleReplacementByReservationId, put_VehicleReplace } from "../../services/VehicleReplcae";

type AddReplaceVehicleModalProps = {
    replaceVehicleIsOpen: boolean;
    setReplaceVehicleIsOpen: (value: boolean) => void;
    reservation: any;
    setVehicleReplaceRefresh: (value: boolean) => void;
    setReservationUpdate: (value: boolean) => void;
}

const fuelLevelOptions: any = [];
for (let i = 0; i <= 8; i++) {
    fuelLevelOptions.push(<option key={i} value={i}>{i}</option>);
}

const currentDate = new Date();

const AddReplaceVehicleModal = ({ replaceVehicleIsOpen, setReplaceVehicleIsOpen, reservation, setVehicleReplaceRefresh, setReservationUpdate }: AddReplaceVehicleModalProps) => {
    const navigate = useNavigate();
    const { auth }: any = useAuth();
    const [isReturnInformation, setIsReturnInformation] = useState<boolean>(true);
    const [isNewVehicle, setIsNewVehicle] = useState<boolean>(true);
    const [vehicleId, setVehicleId] = useState<number>(0);
    const [vehicles, setVehicles] = useState<any[]>([]);
    const [vehicleLink, setVehicleLink] = useState<string>("");
    const [isVehiclesDataLoaded, setIsVehiclesDataLoaded] = useState<boolean>(false);
    const [returnLocationOptions, setReturnLocationOptions] = useState([]);
    const [returnLocations, setReturnLocations] = useState<string>("");
    const [fuelLevelAtPickup, setFuelLevelAtPickup] = useState<string>("0");
    const [odometerAtPickup, setOdometerAtPickup] = useState<string>("0");
    const [fuelLevelAtReturn, setFuelLevelAtReturn] = useState<string>("0");
    const [odometerAtReturn, setOdometerAtReturn] = useState<string>("0");
    const [isChargeFuelToClient, setIsChargeFuelToClient] = useState<boolean>(false);
    const [odometerShowSpan, setOdometerShowSpan] = useState<boolean>(false);
    const [returnDates, setReturnDates] = useState<string>("");
    const [returnTimes, setReturnTimes] = useState<string>("");
    const [recalculateRatesOnEarlyReturn, setRecalculateRatesOnEarlyReturn] = useState<boolean>(false);
    const [availableVehicleClass, setAvailableVehicleClass] = useState<any[]>([]);
    const [availableVehicle, setAvailableVehicle] = useState<any[]>([]);
    const [selectedVehicleClass, setSelectedVehicleClass] = useState<number>(0);
    const [selectedVehicle, setSelectedVehicle] = useState<number>(0);
    const [isFreeChange, setIsFreeChange] = useState<boolean>(false);
    const [newVehicleFuleLevel, setNewVehicleFuleLevel] = useState<string>("0");
    const [newVehicleOdometer, setNewVehicleOdometer] = useState<string>("0");
    const [returnVehicleFilePath, setReturnVehicleFilePath] = useState<any[]>([]);
    const [returnVehiclefileIndex, setReturnVehiclefileIndex] = useState<number>(-1);
    const [imagePopup, setImagePopup] = useState<boolean>(false);
    const [newVehicleFilePath, setNewVehicleFilePath] = useState<any[]>([]);
    const [newVehicleFileIndex, setNewVehicleFileIndex] = useState<number>(-1);
    const returnInputFileRef = useRef<HTMLInputElement>(null);
    const newInputFileRef = useRef<HTMLInputElement>(null);
    const webRef = useRef(null);
    const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
    const [returnVehicleNewFilePath, setReturnVehicleNewFilePath] = useState<any[]>([]);
    const [NewVehicleNewFilePath, setNewVehicleNewFilePath] = useState<any[]>([]);
    const [showWebcam, setShowWebcam] = useState<boolean>(false);
    const [currentWebCam, setCurrrentWebCam] = useState<string>("");
    const [currentPreview, setCurrrentPreview] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [replaceVehicleData, setReplaceVehicleData] = useState<any[]>([]);
    const [returnInfoMessage, setReturnInfoMessage] = useState<string>("");
    const [newInfoMessage, setNewInfoMessage] = useState<string>("");


    useEffect(() => {
        if (replaceVehicleIsOpen && reservation) {
            getVehicleReplacementByReservationId(reservation?.id)
                .then((res) => {
                    setReplaceVehicleData(res)
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [reservation, replaceVehicleIsOpen])

    useEffect(() => {
        if (replaceVehicleIsOpen && reservation && replaceVehicleData.length === 0) {
            setReturnDates(currentDate.toISOString().split("T")[0]);
            setReturnTimes(format(currentDate, "kk:mm"));
            setVehicleId(reservation.vehicleId);
            setReturnLocations(reservation?.returenLoationId);
            setVehicleId(reservation.vehicleId);
            setOdometerAtPickup(reservation?.odometerAtCheckOut);
            setFuelLevelAtPickup(reservation?.fuelLevelAtCheckOut);
        }
        else if (replaceVehicleIsOpen && reservation && replaceVehicleData.length > 0) {
            const currentVehicle = replaceVehicleData.filter((vehicle: any) => vehicle?.isVehicleReplaced === false)[0];

            setReturnDates(currentDate.toISOString().split("T")[0]);
            setReturnTimes(format(currentDate, "kk:mm"));
            setVehicleId(currentVehicle?.vehicleId);
            setReturnLocations(currentVehicle?.returenLoationId);
            setVehicleId(currentVehicle?.vehicleId);
            setOdometerAtPickup(currentVehicle?.odometerAtCheckOut);
            setFuelLevelAtPickup(currentVehicle?.fuelLevelAtCheckOut);

        }
    }, [reservation, replaceVehicleIsOpen, replaceVehicleData])


    useEffect(() => {
        if (replaceVehicleIsOpen) {
            axios
                .get(`${baseURL}vehicles`)
                .then((response) => {
                    setVehicles(() => response.data);
                    setIsVehiclesDataLoaded(true);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [replaceVehicleIsOpen]);

    useEffect(() => {
        if (replaceVehicleIsOpen) {
            axios
                .get(`${baseURL}location`)
                .then((response) => {
                    setReturnLocationOptions(() =>
                        response.data
                            .filter(
                                (data: any) => data.branchId === parseInt(reservation?.branchId)
                            )
                            .map((item: any) => {
                                return {
                                    value: item.id,
                                    label: item.locationName,
                                    branchId: item.branchId,
                                };
                            })
                    );


                })
                .catch((error) => {
                    console.error(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replaceVehicleIsOpen]);

    useEffect(() => {
        if (reservation && replaceVehicleIsOpen) {
            setNewVehicleFilePath([]);
            setReturnVehicleFilePath([]);
            if (replaceVehicleData.length === 0) {
                axois
                    .get(`${baseURL}fileUploadData/Reservation/${reservation.id}`)
                    .then((response) => {
                        setReturnVehicleFilePath(() =>
                            response.data
                                .filter((data: any) => data.tabName === "Checkin")
                                .map((item: any) => {
                                    return {
                                        id: item.id,
                                        fileName: item.fileName.startsWith("data:image/png;base64,")
                                            ? item.fileName
                                            : `${baseURL}reservationFile/Get?name=` +
                                            item.fileName,
                                    };
                                })
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            else {
                axois
                    .get(`${baseURL}fileUploadData/Checkin-VehicleReplacement/${replaceVehicleData.filter((vehicle: any) => vehicle?.isVehicleReplaced === false)[0]?.id}`)
                    .then((response) => {
                        setReturnVehicleFilePath(() =>
                            response.data
                                .map((item: any) => {
                                    return {
                                        id: item.id,
                                        fileName: item.fileName.startsWith("data:image/png;base64,")
                                            ? item.fileName
                                            : `${baseURL}reservationFile/Get?name=` +
                                            item.fileName,
                                    };
                                })
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        }
    }, [reservation, replaceVehicleIsOpen, replaceVehicleData]);

    useEffect(() => {
        if (vehicleId > 0 && isVehiclesDataLoaded) {

            setVehicleLink(vehicles.find((item: any) => item.id === vehicleId)?.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleId, isVehiclesDataLoaded]);

    useEffect(() => {
        if (parseInt(odometerAtReturn) <= Number(odometerAtPickup)) {
            setOdometerShowSpan(true);
        } else {
            setOdometerShowSpan(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [odometerAtReturn]);

    useEffect(() => {
        if (reservation) {
            axios
                .get(
                    `${baseURL}availableVehicleClass/${reservation?.branchId}`
                )
                .then((response) => {
                    setAvailableVehicleClass(() => response.data.map((item: any) => {
                        return {
                            value: item.id,
                            label: item.name,
                        }
                    }));

                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [reservation]);

    useEffect(() => {
        if (selectedVehicleClass > 0) {

            axios
                .get(
                    `${baseURL}vehicles/GetAvailableVehiclesByVehicleClassId/${selectedVehicleClass}`
                )
                .then((response) => {
                    setAvailableVehicle(() =>
                        response.data.map((item: any) => {
                            return {
                                value: item.id,
                                label: item.name,
                                fuel: item.fuelLevel,
                                odometer: item.odometer
                            }
                        }))
                    setSelectedVehicle(0);
                    setNewVehicleOdometer("0");
                    setNewVehicleFuleLevel("0");
                })
                .catch((error) => {
                    console.error(error);
                });

        }
    }, [selectedVehicleClass])


    useEffect(() => {
        if (selectedVehicle > 0 && availableVehicle.length > 0) {
            setNewVehicleOdometer(() => availableVehicle.filter((item: any) => item.value === selectedVehicle)[0]?.odometer)
            setNewVehicleFuleLevel(() => availableVehicle.filter((item: any) => item.value === selectedVehicle)[0]?.fuel)
        }
    }, [selectedVehicle, availableVehicle])

    const onReturnVehicleFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPleaseWaitPopup(true);
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            const formData = new FormData();
            formData.append("ImageFile", selectedFiles[0]);

            axios
                .post(`${baseURL}reservationFile/upload`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                })
                .then((response) => {
                    const newFilePath: any = {
                        id: 0,
                        fileName:
                            `${baseURL}reservationFile/Get?name=` +
                            response.data,
                    };

                    setReturnVehicleNewFilePath((prevPath: string[]) => [
                        ...prevPath,
                        newFilePath.fileName,
                    ]);
                    setReturnVehicleFilePath((prevPath: any[]) => [...prevPath, newFilePath]);
                    setPleaseWaitPopup(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            setPleaseWaitPopup(false);
        }
    };

    const onNewVehicleFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPleaseWaitPopup(true);
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            const formData = new FormData();
            formData.append("ImageFile", selectedFiles[0]);

            axios
                .post(`${baseURL}reservationFile/upload`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                })
                .then((response) => {
                    const newFilePath: any = {
                        id: 0,
                        fileName:
                            `${baseURL}reservationFile/Get?name=` +
                            response.data,
                    };

                    setNewVehicleNewFilePath((prevPath: string[]) => [
                        ...prevPath,
                        newFilePath.fileName,
                    ]);
                    setNewVehicleFilePath((prevPath: any[]) => [...prevPath, newFilePath]);
                    setPleaseWaitPopup(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            setPleaseWaitPopup(false);
        }
    };

    const setReservationUpdateVehicle = async () => {
        if (replaceVehicleData.length === 0) {
            await axios
                .put(
                    `${baseURL}reservation`,
                    JSON.stringify({
                        ...reservation,
                        chargeFuleToClient: isChargeFuelToClient,
                        returenLoationId: returnLocations,
                        fuelLevelAtCheckIn: fuelLevelAtReturn,
                        odometerAtCheckIn: odometerAtReturn,
                        dateTimeCheckIn: returnDates + "T" + returnTimes,
                        recalculateRatesOnEarlyReturn: recalculateRatesOnEarlyReturn,
                        isVehicleReplaced: true
                    }),
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    setPleaseWaitPopup(false);
                    setComment("");
                    setReplaceVehicleIsOpen(false);
                    setVehicleReplaceRefresh(true);
                    setReservationUpdate(true);
                    setReturnVehicleFilePath([]);
                    setNewVehicleFilePath([]);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
        else {
            const currentVehicle = replaceVehicleData.filter((vehicle: any) => vehicle?.isVehicleReplaced === false)[0];
            put_VehicleReplace({
                ...currentVehicle,
                chargeFuleToClient: isChargeFuelToClient,
                returenLoationId: returnLocations,
                fuelLevelAtCheckIn: fuelLevelAtReturn,
                odometerAtCheckIn: odometerAtReturn,
                dateTimeCheckIn: returnDates + "T" + returnTimes,
                recalculateRatesOnEarlyReturn: recalculateRatesOnEarlyReturn,
                isVehicleReplaced: true
            }, auth?.id)
                .then((response) => {
                    setPleaseWaitPopup(false);
                    setComment("");
                    setReplaceVehicleIsOpen(false);
                    setVehicleReplaceRefresh(true);
                    setReservationUpdate(true);
                    setReturnVehicleFilePath([]);
                    setNewVehicleFilePath([]);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }

    const handleSubmit = () => {
        if (odometerAtReturn > odometerAtPickup && parseInt(fuelLevelAtReturn) > 0 && parseInt(returnLocations) > 0) {
            setReturnInfoMessage("")
            if (selectedVehicleClass > 0 && selectedVehicle > 0 && parseInt(newVehicleFuleLevel) > 0 && parseInt(newVehicleOdometer) > 0) {
                setNewInfoMessage("")
                setPleaseWaitPopup(true);
                if (returnVehicleNewFilePath.length !== 0) {
                    returnVehicleNewFilePath.forEach((item: string, index: number) => {
                        axios
                            .post(
                                `${baseURL}fileUploadData`,
                                JSON.stringify({
                                    attributeName: "Reservation",
                                    attributeId: reservation?.id,
                                    fileName: item?.replace(
                                        `${baseURL}reservationFile/Get?name=`,
                                        ""
                                    ),
                                    tabName: "Checkin",
                                    userId: auth?.id ?? 0,
                                    createdDate: new Date(),
                                }),
                                {
                                    headers: { "Content-Type": "application/json" },
                                    withCredentials: true,
                                }
                            )
                            .then((response) => {
                                if (returnVehicleNewFilePath.length - 1 === index) {
                                    addNewVehicleReplace()
                                }
                            })
                            .catch((error) => {
                                console.error("Error:", error);
                            });
                    });
                }
                else {
                    addNewVehicleReplace()
                }
            }
            else {
                setNewInfoMessage("*Please fill the complete new vehicle information.")
            }
        }
        else {
            setReturnInfoMessage("*Please fill the complete return information.")
        }
    }

    const addNewVehicleReplace = () => {
        if (NewVehicleNewFilePath.length !== 0) {
            axios
                .post(
                    `${baseURL}vehicleReplacement?userId=${auth?.id}`,
                    {
                        reservationId: reservation?.id,
                        bookingNumber: reservation?.bookingNumber,
                        customerId: reservation?.reservationId,
                        pickupDate: reservation?.pickupDate,
                        pickupLoationId: reservation?.pickupLoationId,
                        walkInCustomer: reservation?.walkInCustomer,
                        vehicleClassId: selectedVehicleClass,
                        vehicleId: selectedVehicle,
                        totalPrice: reservation?.totalPrice,
                        totalRevenue: reservation?.totalPrice,
                        totalPaid: reservation?.totalPaid,
                        totalRefunded: reservation?.totalRefunded,
                        outstandingBalance: reservation?.outstandingBalance,
                        securityDepositAmount: reservation?.securityDepositAmount,
                        securityDepositAmountPaid: reservation?.securityDepositAmountPaid,
                        status: reservation?.status,
                        branchId: reservation?.branchId,
                        totalDays: reservation?.totalDays,
                        dailyRate: reservation?.dailyRate,
                        totalHours: reservation?.totalHours,
                        hourlyRate: reservation?.hourlyRate,
                        totalWeeks: reservation?.totalWeeks,
                        weeklyRate: reservation?.weeklyRate,
                        totalYears: reservation?.totalYears,
                        yearlyRate: reservation?.yearlyRate,
                        fuelLevelAtCheckOut: newVehicleFuleLevel,
                        odometerAtCheckOut: newVehicleOdometer,
                        dateTimeCheckOut: new Date().toISOString(),
                        localAddress: reservation?.localAddress,
                        createdBy: auth?.givenname,
                        createdAt: new Date(),
                        rentalUser: reservation?.rentalUser,
                        rentalAt: reservation?.rentalAt,
                        isVehicleReplaced: false,
                        isFreeChange: isFreeChange,
                        isExtended: reservation?.isExtended,
                        chargeFuleToClient: isChargeFuelToClient,
                        recalculateRatesOnEarlyReturn: recalculateRatesOnEarlyReturn
                    },
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((res: any) => {
                    if (comment !== "") {
                        post_comment(
                            JSON.stringify({
                                attributeName: "VehicleReplacement",
                                attributeId: res.data?.id,
                                notes: comment,
                                userId: auth?.id,
                                createdDate:
                                    format(new Date(), "yyyy-MM-dd") +
                                    "T" +
                                    format(new Date(), "KK:mm:ss"),
                            })).then(data => {
                                NewVehicleNewFilePath.forEach((item: string, index: number) => {
                                    axios
                                        .post(
                                            `${baseURL}fileUploadData`,
                                            JSON.stringify({
                                                attributeName: "Checkout-VehicleReplacement",
                                                attributeId: res.data?.id,
                                                fileName: item?.replace(
                                                    `${baseURL}reservationFile/Get?name=`,
                                                    ""
                                                ),
                                                tabName: "VehicleReplacement",
                                                userId: auth?.id ?? 0,
                                                createdDate: new Date(),
                                            }),
                                            {
                                                headers: { "Content-Type": "application/json" },
                                                withCredentials: true,
                                            }
                                        )
                                        .then((response) => {
                                            if (newVehicleFilePath.length - 1 === index) {
                                                setReservationUpdateVehicle();
                                            }
                                        })
                                        .catch((error) => {
                                            console.error("Error:", error);
                                        });
                                });

                            })
                            .catch(error => console.error("Error:", error));

                    }
                    else {
                        setReservationUpdateVehicle();
                    }

                })
                .catch((error) => {
                    console.error("Error:", error);
                })
        }
        else {
            axios
                .post(
                    `${baseURL}vehicleReplacement?userId=${auth?.id}`,
                    {
                        reservationId: reservation?.id,
                        bookingNumber: reservation?.bookingNumber,
                        customerId: reservation?.reservationId,
                        pickupDate: reservation?.pickupDate,
                        pickupLoationId: reservation?.pickupLoationId,
                        walkInCustomer: reservation?.walkInCustomer,
                        vehicleClassId: selectedVehicleClass,
                        vehicleId: selectedVehicle,
                        totalPrice: reservation?.totalPrice,
                        totalRevenue: reservation?.totalPrice,
                        totalPaid: reservation?.totalPaid,
                        totalRefunded: reservation?.totalRefunded,
                        outstandingBalance: reservation?.outstandingBalance,
                        securityDepositAmount: reservation?.securityDepositAmount,
                        securityDepositAmountPaid: reservation?.securityDepositAmountPaid,
                        status: reservation?.status,
                        branchId: reservation?.branchId,
                        totalDays: reservation?.totalDays,
                        dailyRate: reservation?.dailyRate,
                        totalHours: reservation?.totalHours,
                        hourlyRate: reservation?.hourlyRate,
                        totalWeeks: reservation?.totalWeeks,
                        weeklyRate: reservation?.weeklyRate,
                        totalYears: reservation?.totalYears,
                        yearlyRate: reservation?.yearlyRate,
                        fuelLevelAtCheckOut: newVehicleFuleLevel,
                        odometerAtCheckOut: newVehicleOdometer,
                        dateTimeCheckOut: new Date().toISOString(),
                        localAddress: reservation?.localAddress,
                        createdBy: auth?.givenname,
                        createdAt: new Date(),
                        rentalUser: reservation?.rentalUser,
                        rentalAt: reservation?.rentalAt,
                        isVehicleReplaced: false,
                        isFreeChange: isFreeChange,
                        isExtended: reservation?.isExtended,
                        chargeFuleToClient: isChargeFuelToClient,
                        recalculateRatesOnEarlyReturn: recalculateRatesOnEarlyReturn
                    },
                    {
                        headers: { "Content-Type": "application/json" },
                        withCredentials: true,
                    }
                )
                .then((res: any) => {
                    if (comment !== "") {
                        post_comment(
                            JSON.stringify({
                                attributeName: "VehicleReplacement",
                                attributeId: res.data?.id,
                                notes: comment,
                                userId: auth?.id,
                                createdDate:
                                    format(new Date(), "yyyy-MM-dd") +
                                    "T" +
                                    format(new Date(), "KK:mm:ss"),
                            })).then(data => {
                                setReservationUpdateVehicle();
                            })
                            .catch(error => console.error("Error:", error));

                    }
                    else {
                        setReservationUpdateVehicle();
                    }

                })
                .catch((error) => {
                    console.error("Error:", error);
                })
        }
    }

    return (
        <>
            <Dialog
                open={replaceVehicleIsOpen}

                PaperProps={{
                    style: {
                        maxWidth: "800px",
                        width: "100%"
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Add Vehicle Replacement
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                            onClick={() => setReplaceVehicleIsOpen(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>

                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >


                        <h5
                            style={{
                                paddingBottom: "0px",
                                marginBottom: "0px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                WebkitUserSelect: "none" /* Safari */,
                                msUserSelect: "none" /* IE 10 and IE 11 */,
                                userSelect: "none" /* Standard syntax */,
                            }}
                            onClick={() => setIsReturnInformation(!isReturnInformation)}
                        >
                            {isReturnInformation ? (
                                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                            ) : (
                                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                            )}{" "}
                            Return Information
                        </h5>
                        <hr />
                        {
                            isReturnInformation && (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                    }}
                                >

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Vehicle Link
                                                <br />
                                                <span
                                                    style={{
                                                        fontWeight: "lighter",
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        textDecoration: "underline",
                                                    }}
                                                    onClick={() =>
                                                        navigate("/fleet/vehicle/editvehicles", {
                                                            state: vehicleId,
                                                        })
                                                    }
                                                >
                                                    {vehicleLink}
                                                </span>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Return Location
                                                <br />
                                                <select
                                                    name="return location"
                                                    id="cars"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                        fontWeight: "lighter"
                                                    }}
                                                    value={returnLocations}
                                                    onChange={(e) => setReturnLocations(e.target.value)}
                                                    required
                                                >
                                                    <option value={0}>{" "}</option>
                                                    {returnLocationOptions.map((item: any) => {
                                                        return (
                                                            <option key={item.value} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Fuel level at Pickup
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{`${fuelLevelAtPickup} / 8`}</span>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Odometer at Pickup
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{odometerAtPickup}</span>
                                            </label>
                                        </div>

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                            }}>
                                                <div style={{
                                                    width: "50%",

                                                }}>
                                                    <label
                                                        htmlFor="amount"
                                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                                    >
                                                        Fuel level at Return
                                                        <br />
                                                        <select
                                                            name="return location"
                                                            id="cars"
                                                            style={{
                                                                width: "100%",
                                                                fontSize: "14px",
                                                                paddingBlock: "5px",
                                                                fontWeight: "lighter"
                                                            }}
                                                            value={fuelLevelAtReturn}
                                                            onChange={(e) =>
                                                                setFuelLevelAtReturn(e.target.value)
                                                            }
                                                            required
                                                        >
                                                            {fuelLevelOptions}
                                                        </select>
                                                    </label>
                                                </div>

                                                <div style={{ width: "50%" }}>
                                                    <label
                                                        htmlFor="chargeFule"
                                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                                    >
                                                        Charge fule to client?
                                                        <br />
                                                        <label htmlFor="chargeFuleYes" style={{ fontWeight: "lighter" }}>
                                                            <input type="radio" id="chargeFuleYes" name="chargeFule"
                                                                value="Yes"
                                                                checked={isChargeFuelToClient ? true : false}
                                                                onChange={(e) =>
                                                                    setIsChargeFuelToClient(e.target.value === "Yes" ? true : false)
                                                                } />{" "}
                                                            Yes</label>


                                                        <label htmlFor="chargeFuleNo" style={{ fontWeight: "lighter" }}>
                                                            <input type="radio" id="chargeFuleNo" name="chargeFule"
                                                                value="No"
                                                                checked={isChargeFuelToClient ? false : true}
                                                                onChange={(e) =>
                                                                    setIsChargeFuelToClient(e.target.value === "Yes" ? true : false)
                                                                }
                                                            />{" "}
                                                            No</label>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Odometer at Return
                                                <br />
                                                <input
                                                    type="text"
                                                    min={reservation ? reservation.odometerAtCheckOut : 0}
                                                    name="return-odometer"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                        fontWeight: "lighter"
                                                    }}
                                                    value={odometerAtReturn}
                                                    onChange={(e) => setOdometerAtReturn(e.target.value)}
                                                    required
                                                />

                                                <span
                                                    style={{
                                                        display: odometerShowSpan ? "inline-block" : "none",
                                                        color: "red",
                                                        paddingLeft: "5px",
                                                    }}
                                                >
                                                    *Must be getter than Odometer at Pickup
                                                </span>
                                            </label>
                                        </div>

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Return Date
                                                <br />
                                                <input
                                                    type="date"
                                                    name="return-date"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                        fontWeight: "lighter"
                                                    }}
                                                    value={returnDates}
                                                    onChange={(e) => setReturnDates(e.target.value)}
                                                    required
                                                />
                                            </label>
                                        </div>

                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                            }}>
                                                <div style={{
                                                    width: "50%",

                                                }}>
                                                    <label
                                                        htmlFor="amount"
                                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                                    >
                                                        Return Time
                                                        <br />
                                                        <input
                                                            type="time"
                                                            name="return-time"
                                                            style={{
                                                                width: "100%",
                                                                fontSize: "14px",
                                                                paddingBlock: "5px",
                                                                fontWeight: "lighter"
                                                            }}
                                                            value={returnTimes}
                                                            onChange={(e) => setReturnTimes(e.target.value)}
                                                            required
                                                        />
                                                    </label>
                                                </div>

                                                <div style={{ width: "50%" }}>
                                                    <label
                                                        htmlFor="recalculateEarlyReturn"
                                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                                    >
                                                        Recalculate rates on early Return?
                                                        <br />

                                                        <label htmlFor="recalculateEarlyReturnYes" style={{ fontWeight: "lighter" }}>
                                                            <input type="radio" id="recalculateEarlyReturnYes" name="recalculateEarlyReturn" value="Yes"
                                                                checked={recalculateRatesOnEarlyReturn ? true : false}
                                                                onChange={(e) =>
                                                                    setRecalculateRatesOnEarlyReturn(e.target.value === "Yes" ? true : false)
                                                                }
                                                            />{" "}
                                                            Yes</label>


                                                        <label htmlFor="recalculateEarlyReturnNo" style={{ fontWeight: "lighter" }}>
                                                            <input type="radio" id="recalculateEarlyReturnNo" name="recalculateEarlyReturn" value="No"
                                                                checked={recalculateRatesOnEarlyReturn ? false : true}
                                                                onChange={(e) =>
                                                                    setRecalculateRatesOnEarlyReturn(e.target.value === "Yes" ? true : false)
                                                                }
                                                            />{" "}
                                                            No</label>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "100%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Images
                                                <br />
                                                <div
                                                    style={{
                                                        maxWidth: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        flexWrap: "wrap"
                                                    }}
                                                >
                                                    {returnVehicleFilePath.map((item: any, outerIndex: number) => {
                                                        return (
                                                            <React.Fragment key={outerIndex}>
                                                                <img
                                                                    src={item.fileName}
                                                                    width={40}
                                                                    height={40}
                                                                    alt="repairOrder"
                                                                    onClick={() => {
                                                                        setReturnVehiclefileIndex(outerIndex);
                                                                        setImagePopup(true);
                                                                        setCurrrentPreview('return-vehicle');
                                                                    }}
                                                                />
                                                                <RemoveCircleIcon
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        color: colorConfigs.topbar.bg,
                                                                        fontWeight: "bold",
                                                                        "&: hover": {
                                                                            color: "red",
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            window.confirm(
                                                                                "Are you sure you want to delete this image?"
                                                                            )
                                                                        ) {
                                                                            let newFileSet: any[] = returnVehicleFilePath.filter(
                                                                                (_, index: number) => index !== outerIndex
                                                                            );

                                                                            if (returnVehicleFilePath[outerIndex].id === 0) {
                                                                                setReturnVehicleFilePath(() => newFileSet);
                                                                            } else {
                                                                                axios
                                                                                    .delete(
                                                                                        `${baseURL}fileUploadData?id=${returnVehicleFilePath[outerIndex].id}`
                                                                                    )
                                                                                    .then((response) => {
                                                                                        setReturnVehicleFilePath(() => newFileSet);
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        console.error("Error:", error);
                                                                                    });
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                                <input
                                                    type="file"
                                                    name="inputFileRef"
                                                    accept="image/*"
                                                    ref={returnInputFileRef}
                                                    onChangeCapture={onReturnVehicleFileChangeCapture}
                                                    style={{ display: "none" }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                        }}
                                                        onClick={(e) => {
                                                            if (returnInputFileRef.current !== null) {
                                                                returnInputFileRef.current.click();
                                                            }
                                                        }}
                                                    >
                                                        {" "}
                                                        <AddIcon
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "lighter",
                                                            }}
                                                        />
                                                        Add Image
                                                    </p>

                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                        }}
                                                        onClick={() => {
                                                            setShowWebcam(true)
                                                            setCurrrentWebCam('return-vehicle')
                                                        }}
                                                    >
                                                        {" "}
                                                        <LocalSeeIcon
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "bold",
                                                            }}
                                                        />
                                                    </p>
                                                </div>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>

                                        </div>

                                    </div>

                                    <span
                                        style={{
                                            display: "inline-block",
                                            color: "red",
                                            paddingLeft: "5px",
                                            fontSize: "12px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {returnInfoMessage}
                                    </span>

                                </div>
                            )
                        }

                    </div>

                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >


                        <h5
                            style={{
                                paddingBottom: "0px",
                                marginBottom: "0px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                WebkitUserSelect: "none" /* Safari */,
                                msUserSelect: "none" /* IE 10 and IE 11 */,
                                userSelect: "none" /* Standard syntax */,
                            }}
                            onClick={() => setIsNewVehicle(!isNewVehicle)}
                        >
                            {isNewVehicle ? (
                                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                            ) : (
                                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                            )}{" "}
                            New Vehicle
                        </h5>
                        <hr />
                        {
                            isNewVehicle && (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                New Vehicle Class
                                                <br />
                                                <select
                                                    name="new vehicle"
                                                    id="vehicle"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                        fontWeight: "lighter"
                                                    }}
                                                    value={selectedVehicleClass}
                                                    onChange={(e) =>
                                                        setSelectedVehicleClass(parseInt(e.target.value))
                                                    }
                                                    required
                                                >
                                                    <option value="0">{" "}</option>
                                                    {availableVehicleClass.map((item: any) => {
                                                        return (
                                                            <option key={item.value} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                            }}>
                                                <div style={{
                                                    width: "50%",

                                                }}>
                                                    <label
                                                        htmlFor="amount"
                                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                                    >
                                                        Replacement Vehicle
                                                        <br />
                                                        <select
                                                            name="return location"
                                                            id="cars"
                                                            style={{
                                                                width: "100%",
                                                                fontSize: "14px",
                                                                paddingBlock: "5px",
                                                                fontWeight: "lighter"
                                                            }}
                                                            value={selectedVehicle}
                                                            onChange={(e) => {
                                                                setSelectedVehicle(parseInt(e.target.value))
                                                            }
                                                            }
                                                            required
                                                        >
                                                            <option value="0">{" "}</option>
                                                            {availableVehicle.map((item: any) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </label>
                                                </div>

                                                <div style={{ width: "50%" }}>
                                                    <label
                                                        htmlFor="freeChange"
                                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                                    >
                                                        Free Change?
                                                        <br />

                                                        <label htmlFor="freeChangeYes" style={{ fontWeight: "lighter" }}>
                                                            <input type="radio" id="freeChangeYes" name="freeChange" value="Yes"
                                                                checked={isFreeChange ? true : false}
                                                                onChange={(e) =>
                                                                    setIsFreeChange(e.target.value === "Yes" ? true : false)
                                                                }
                                                            />{" "}
                                                            Yes</label>


                                                        <label htmlFor="freeChangeNo" style={{ fontWeight: "lighter" }}>
                                                            <input type="radio" id="freeChangeNo" name="freeChange" value="No"
                                                                checked={isFreeChange ? false : true}
                                                                onChange={(e) =>
                                                                    setIsFreeChange(e.target.value === "Yes" ? true : false)
                                                                }
                                                            />{" "}
                                                            No</label>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Fuel level
                                                <br />
                                                <select
                                                    name="return location"
                                                    id="cars"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                        fontWeight: "lighter"
                                                    }}
                                                    value={newVehicleFuleLevel}
                                                    onChange={(e) =>
                                                        setNewVehicleFuleLevel(e.target.value)
                                                    }
                                                    required
                                                >
                                                    {fuelLevelOptions}
                                                </select>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Current Odometer
                                                <br />
                                                <input
                                                    type="text"
                                                    name="current-odometer"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                        fontWeight: "lighter"
                                                    }}
                                                    value={newVehicleOdometer}
                                                    onChange={(e) => setNewVehicleOdometer(e.target.value)}
                                                    required
                                                />
                                            </label>
                                        </div>

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "100%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Images
                                                <br />
                                                <div
                                                    style={{
                                                        maxWidth: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        flexWrap: "wrap"
                                                    }}
                                                >
                                                    {newVehicleFilePath.map((item: any, outerIndex: number) => {
                                                        return (
                                                            <React.Fragment key={outerIndex}>
                                                                <img
                                                                    src={item.fileName}
                                                                    width={40}
                                                                    height={40}
                                                                    alt="repairOrder"
                                                                    onClick={() => {
                                                                        setNewVehicleFileIndex(outerIndex);
                                                                        setImagePopup(true);
                                                                        setCurrrentPreview('new-vehicle');
                                                                    }}
                                                                />
                                                                <RemoveCircleIcon
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        color: colorConfigs.topbar.bg,
                                                                        fontWeight: "bold",
                                                                        "&: hover": {
                                                                            color: "red",
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            window.confirm(
                                                                                "Are you sure you want to delete this image?"
                                                                            )
                                                                        ) {
                                                                            let newFileSet: any[] = newVehicleFilePath.filter(
                                                                                (_, index: number) => index !== outerIndex
                                                                            );

                                                                            if (newVehicleFilePath[outerIndex].id === 0) {
                                                                                setNewVehicleFilePath(() => newFileSet);
                                                                            } else {
                                                                                axios
                                                                                    .delete(
                                                                                        `${baseURL}fileUploadData?id=${newVehicleFilePath[outerIndex].id}`
                                                                                    )
                                                                                    .then((response) => {
                                                                                        setNewVehicleFilePath(() => newFileSet);
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        console.error("Error:", error);
                                                                                    });
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                                <input
                                                    type="file"
                                                    name="inputFileRef"
                                                    accept="image/*"
                                                    ref={newInputFileRef}
                                                    onChangeCapture={onNewVehicleFileChangeCapture}
                                                    style={{ display: "none" }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                        }}
                                                        onClick={(e) => {
                                                            if (newInputFileRef.current !== null) {
                                                                newInputFileRef.current.click();
                                                            }
                                                        }}
                                                    >
                                                        {" "}
                                                        <AddIcon
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "lighter",
                                                            }}
                                                        />
                                                        Add Image
                                                    </p>

                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                        }}
                                                        onClick={() => {
                                                            setShowWebcam(true)
                                                            setCurrrentWebCam('new-vehicle')
                                                        }}
                                                    >
                                                        {" "}
                                                        <LocalSeeIcon
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "bold",
                                                            }}
                                                        />
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <span
                                        style={{
                                            display: "inline-block",
                                            color: "red",
                                            paddingLeft: "5px",
                                            fontSize: "12px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {newInfoMessage}
                                    </span>
                                    <div style={{ width: "100%" }}>
                                        <label
                                            htmlFor="description"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Comments
                                            <br />
                                            <textarea
                                                name="comments"
                                                rows={4}
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                onChange={(e) => setComment(e.target.value)}
                                                value={comment}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>
                            )
                        }


                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "50%" }} />

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => {
                                    setReplaceVehicleIsOpen(false);
                                }}
                            >
                                Cancel
                            </Button>


                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "#FFFFFF",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "#FFFFFF",
                                    },
                                }}
                                onClick={() => handleSubmit()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showWebcam}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        marginTop: "-16%",
                    },
                }}
            >
                <DialogContent sx={{ padding: "0px" }}>
                    <Webcam
                        width={480}
                        height={320}
                        ref={webRef}
                        screenshotFormat="image/png"
                        audio={false}
                    />

                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => setShowWebcam(false)}
                            >
                                Cancel
                            </Button>
                        </div>

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "white",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                    },
                                }}
                                onClick={() => {
                                    if (currentWebCam === "return-vehicle") {
                                        if (webRef.current !== null) {
                                            //@ts-ignore
                                            const imageSrc = webRef.current.getScreenshot();
                                            setReturnVehicleNewFilePath((prevFileString: string[]) => [
                                                ...prevFileString,
                                                imageSrc,
                                            ]);

                                            const newFile: any = {
                                                id: 0,
                                                fileName: imageSrc,
                                            };
                                            setReturnVehicleFilePath((prevFileString: any[]) => [
                                                ...prevFileString,
                                                newFile,
                                            ]);
                                            setShowWebcam(false);
                                        }
                                    }
                                    else if (currentWebCam === "new-vehicle") {
                                        if (webRef.current !== null) {
                                            //@ts-ignore
                                            const imageSrc = webRef.current.getScreenshot();
                                            setNewVehicleNewFilePath((prevFileString: string[]) => [
                                                ...prevFileString,
                                                imageSrc,
                                            ]);

                                            const newFile: any = {
                                                id: 0,
                                                fileName: imageSrc,
                                            };
                                            setNewVehicleFilePath((prevFileString: any[]) => [
                                                ...prevFileString,
                                                newFile,
                                            ]);
                                            setShowWebcam(false);
                                        }
                                    }
                                }}
                            >
                                Capture an Image
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={imagePopup}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        marginTop: "-14%",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Vehicle Replacement
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                            onClick={() => setImagePopup(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                    <div
                        style={{
                            width: "100%",
                            height: "5%",
                            backgroundColor: colorConfigs.sidebar.bg,
                            paddingLeft: "8px",
                            fontSize: "14px",
                        }}
                    >
                        Preview
                    </div>
                    <hr style={{ marginTop: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>
                    {currentPreview === 'return-vehicle' ? returnVehicleFilePath.length !== 0 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "transparent",
                                    position: "absolute",
                                    padding: "10px",
                                    left: "0",
                                    top: "50%",
                                }}
                            >
                                <ArrowBackIosIcon
                                    sx={{
                                        cursor: returnVehiclefileIndex !== 0 ? "pointer" : "",
                                        opacity: returnVehiclefileIndex === 0 ? "0.5" : "1",
                                        color: "gray",
                                    }}
                                    onClick={() => {
                                        if (returnVehiclefileIndex !== 0) {
                                            setReturnVehiclefileIndex(returnVehiclefileIndex - 1);
                                        }
                                    }}
                                />
                            </div>
                            <img
                                width={700}
                                height={350}
                                src={returnVehicleFilePath[returnVehiclefileIndex]?.fileName}
                                alt="repairOrder"
                            />

                            <div
                                style={{
                                    backgroundColor: "transparent",
                                    position: "absolute",
                                    padding: "10px",
                                    right: "0",
                                    top: "50%",
                                    color: "gray",
                                }}
                            >
                                <ArrowForwardIosIcon
                                    sx={{
                                        cursor: returnVehiclefileIndex !== returnVehicleFilePath.length - 1 ? "pointer" : "",
                                        opacity: returnVehiclefileIndex === returnVehicleFilePath.length - 1 ? "0.5" : "1",
                                    }}
                                    onClick={() => {
                                        if (returnVehiclefileIndex !== returnVehicleFilePath.length - 1) {
                                            setReturnVehiclefileIndex(returnVehiclefileIndex + 1);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )
                        :
                        newVehicleFilePath.length !== 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: "5px",
                                }}
                            >
                                <div
                                    style={{
                                        backgroundColor: "transparent",
                                        position: "absolute",
                                        padding: "10px",
                                        left: "0",
                                        top: "50%",
                                    }}
                                >
                                    <ArrowBackIosIcon
                                        sx={{
                                            cursor: newVehicleFileIndex !== 0 ? "pointer" : "",
                                            opacity: newVehicleFileIndex === 0 ? "0.5" : "1",
                                            color: "gray",
                                        }}
                                        onClick={() => {
                                            if (newVehicleFileIndex !== 0) {
                                                setNewVehicleFileIndex(newVehicleFileIndex - 1);
                                            }
                                        }}
                                    />
                                </div>
                                <img
                                    width={700}
                                    height={350}
                                    src={newVehicleFilePath[newVehicleFileIndex]?.fileName}
                                    alt="repairOrder"
                                />

                                <div
                                    style={{
                                        backgroundColor: "transparent",
                                        position: "absolute",
                                        padding: "10px",
                                        right: "0",
                                        top: "50%",
                                        color: "gray",
                                    }}
                                >
                                    <ArrowForwardIosIcon
                                        sx={{
                                            cursor: newVehicleFileIndex !== newVehicleFilePath.length - 1 ? "pointer" : "",
                                            opacity: newVehicleFileIndex === newVehicleFilePath.length - 1 ? "0.5" : "1",
                                        }}
                                        onClick={() => {
                                            if (newVehicleFileIndex !== newVehicleFilePath.length - 1) {
                                                setNewVehicleFileIndex(newVehicleFileIndex + 1);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    }
                </DialogContent>
            </Dialog>

            <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
        </>
    )
}

export default AddReplaceVehicleModal;