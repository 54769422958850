import {
  Toolbar,
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import baseURL from "../../../../configs/api";
import colorConfigs from "../../../../configs/colorConfigs";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Webcam from "react-webcam";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import axois from "../../../../configs/axois";
import { handleCapture } from "../../../../utils/covertTheBase64FormatIntoImage";
import { validateMin } from "../../../../utils/validateMin";

export function AddEmailTemplate() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const [label, setLabel] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [isFilters, setIsFilters] = useState<boolean>(true);
  const [pickupLocations, setPickupLocations] = useState<string>("");
  const [pickupLocationsOptions, setPickupLocationsOptions] = useState([]);
  const [
    pickupLocationsOptionsOfParticular,
    setPickupLocationsOptionsOfParticular,
  ] = useState([]);
  const [returnLocations, setReturnLocations] = useState<string>("");
  const [returnLocationsOptions, setReturnLocationsOptions] = useState([]);
  const [
    returnLocationsOptionsOfParticular,
    setReturnLocationsOptionsOfParticular,
  ] = useState([]);
  const [branchId, setBranchId] = useState<string>("");
  const [vehicleClassId, setVehicleClassId] = useState<string>("");
  const [sendAfterXDays, setSendAfterXDays] = useState<string>("");
  const [repeatEveryXDays, setRepeatEveryXDays] = useState<string>("");
  const [attachments, setAttachments] = useState<string>("");
  const [itemTypeId, setItemTypeId] = useState<string>("");
  const [isAdvanced, setIsAdvanced] = useState<boolean>(true);
  const [isContent, setIsContent] = useState<boolean>(true);
  const [hourToSend, setHourToSend] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [cc, setCc] = useState<string>("");
  const [replyTo, setReplyTo] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [active, setActive] = useState<boolean>(true);
  const [sendAsSms, setSendAsSms] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");
  const [filePath, setFilePath] = useState<string[]>([]);
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  const [isImageSaved, setIsImageSaved] = useState<boolean>(false);
  const [primaryKey, setPrimaryKey] = useState<number>();

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}commonFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          const newFilePath: string =
            `${baseURL}commonFile/Get?name=` +
            response.data;
          setFilePath((prevPath: string[]) => [...prevPath, newFilePath]);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setPleaseWaitPopup(false);
    }
  };

  const [itemTypeOptions, setItemTypeOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}emailTemplateType`)
      .then((response) => {
        setItemTypeOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );

        setItemTypeId(response.data[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        setBranches(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );

        setBranchId(response.data[0].id);
        setPickupLocations(response.data[0].defaultLocationId);
        setReturnLocations(response.data[0].defaultLocationId);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const [vehicleClassOptions, setVehicleClassOptions] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}vehicleClass`)
      .then((response) => {
        setVehicleClassOptions(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );

        setVehicleClassId(response.data[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}emailTemplate`,
        JSON.stringify({
          branchId,
          name: label,
          emailTemplateTypeId: itemTypeId,
          sendAsSMS: sendAsSms,
          group,
          vehicleClassId,
          pickupLocationId: pickupLocations,
          returnLocationId: returnLocations,
          sendAfterXDays,
          hourToSend,
          attachments,
          startDate,
          repeatEvery: repeatEveryXDays,
          isActive: active,
          emailCC: cc,
          emailReplyTo: replyTo,
          emailSubject: subject,
          emailContent: content,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        setIsImageSaved(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    axois
      .get(`${baseURL}emailTemplate`)
      .then((response) => {
        setPrimaryKey(parseInt(response.data[0].id) + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (isImageSaved) {
      if (filePath.length !== 0) {
        filePath.forEach((item: string, index: number) => {
          axios
            .post(
              `${baseURL}fileUploadData`,
              JSON.stringify({
                attributeName: "EmailTemplate",
                attributeId: primaryKey,
                fileName: item.replace(
                  `${baseURL}commonFile/Get?name=`,
                  ""
                ),
                tabName: "EmailTemplate",
                userId: 1,
                createdDate: new Date(),
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then((response) => {
              if (filePath.length - 1 === index) {
                setPleaseWaitPopup(false);
                navigate(-1);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
      } else {
        navigate(-1);
      }
      setIsImageSaved(false);
    }
  }, [isImageSaved]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  useEffect(() => {
    axios
      .get(`${baseURL}location`)
      .then((response) => {
        setPickupLocationsOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.locationName,
              branchId: item.branchId,
            };
          })
        );

        setPickupLocationsOptionsOfParticular(() =>
          response.data
            .map((item: any) => {
              return {
                value: item.id,
                label: item.locationName,
                branchId: item.branchId,
              };
            })
            .filter((item: any) => item.branchId === parseInt(branchId))
        );

        setReturnLocationsOptions(() =>
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.locationName,
              branchId: item.branchId,
            };
          })
        );

        setReturnLocationsOptionsOfParticular(() =>
          response.data
            .map((item: any) => {
              return {
                value: item.id,
                label: item.locationName,
                branchId: item.branchId,
              };
            })
            .filter((item: any) => item.branchId === parseInt(branchId))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (branchId && pickupLocationsOptions)
      setPickupLocationsOptionsOfParticular(() =>
        pickupLocationsOptions.filter((item: any) => item.branchId === parseInt(branchId))
      );

    if (branchId && returnLocationsOptions)
      setReturnLocationsOptionsOfParticular(() =>
        returnLocationsOptions.filter((item: any) => item.branchId === parseInt(branchId))
      );
  }, [branchId]);

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <EmailIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Email Templates - Add Email Template
          </Typography>
        </Box>
      </Toolbar>

      <hr style={{ boxShadow: "0 20px 20px -20px #333" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingBottom: "24px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            General Settings
          </h5>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="label"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Label
                    <br />
                    <input
                      type="text"
                      name="label"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                      required
                    />
                  </label>

                  <div style={{ marginTop: "10px" }}>
                    <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                      Send As SMS
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="sendAsSms"
                        style={{ fontSize: "12px" }}
                        checked={sendAsSms ? true : false}
                        onChange={(e) =>
                          setSendAsSms(e.target.value === "Yes" ? true : false)
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="sendAsSms"
                        style={{ fontSize: "12px" }}
                        checked={sendAsSms ? false : true}
                        onChange={(e) =>
                          setSendAsSms(e.target.value === "Yes" ? true : false)
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="itemType"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Item Type
                    <br />
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isDisabled={false}
                      isLoading={false}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={false}
                      name="itemType"
                      value={itemTypeOptions.find(
                        (item: any) => item.value === parseInt(itemTypeId)
                      )}
                      onChange={(itemType: any) =>
                        setItemTypeId(itemType ? itemType.value : "")
                      }
                      options={itemTypeOptions}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="group"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Group
                    <br />
                    <input
                      type="text"
                      name="group"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={group}
                      onChange={(e) => setGroup(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsFilters(!isFilters)}
            >
              {isFilters ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Filters
            </h5>
            <hr />

            {isFilters && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="branchId"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Branch
                        <br />
                        <select
                          name="branchId"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={branchId}
                          onChange={(e) => setBranchId(e.target.value)}
                          required
                        >
                          {branches.map((item: any) => {
                            return (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    </div>

                    <div style={{ marginTop: "15px" }}>
                      <label
                        htmlFor="pickupLocations"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Pickup Locations
                        <br />
                        <select
                          name="pickupLocations"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={pickupLocations}
                          onChange={(e) => setPickupLocations(e.target.value)}
                        >
                          {pickupLocationsOptionsOfParticular.map(
                            (item: any) => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            }
                          )}
                        </select>
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="vehicleClass"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Vehicle Class
                        <br />
                        <Select
                          styles={{
                            menu: (base) => ({ ...base, fontSize: "12px" }),
                          }}
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={false}
                          name="vehicle"
                          value={vehicleClassOptions.find(
                            (item: any) => item.value === parseInt(vehicleClassId)
                          )}
                          onChange={(vehicle: any) =>
                            setVehicleClassId(vehicle ? vehicle.value : "")
                          }
                          options={vehicleClassOptions}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="returnLocations"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Return Locations
                        <br />
                        <select
                          name="returnLocations"
                          id="cars"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={returnLocations}
                          onChange={(e) => setReturnLocations(e.target.value)}
                        >
                          {returnLocationsOptionsOfParticular.map(
                            (item: any) => {
                              return (
                                <option value={item.value}>{item.label}</option>
                              );
                            }
                          )}
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsAdvanced(!isAdvanced)}
            >
              {isAdvanced ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Advanced
            </h5>
            <hr />

            {isAdvanced && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="sendAfterXDays"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Send After x Days
                        <br />
                        <input
                          type="number"
                          min={0}
                          name="sendAfterXDays"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={sendAfterXDays}
                          onChange={(e) => setSendAfterXDays(validateMin(e.target.value, 0).toString())}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="attachments"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Attachments
                        <br />
                        <input
                          type="text"
                          name="attachments"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={attachments}
                          onChange={(e) => setAttachments(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="startDate"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        Start Date
                        <input
                          type="date"
                          id="startDate"
                          value={startDate}
                          onChange={(event) => setStartDate(event.target.value)}
                          style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingBlock: "3px",
                          }}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="repeatEveryXDays"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Repeat Every x Days
                        <br />
                        <input
                          type="number"
                          min={0}
                          name="repeatEveryXDays"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={repeatEveryXDays}
                          onChange={(e) => setRepeatEveryXDays(validateMin(e.target.value, 0).toString())}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="hourToSend"
                        style={{ fontSize: "10px", fontWeight: "bold" }}
                      >
                        Hour to Send
                        <input
                          type="time"
                          value={hourToSend}
                          onChange={(event) =>
                            setHourToSend(event.target.value)
                          }
                          style={{
                            width: "100%",
                            fontSize: "12px",
                            paddingBlock: "3px",
                          }}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px", visibility: "hidden" }}>
                      <label
                        htmlFor="returnLocations"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Return Locations
                        <br />
                        <input
                          type="text"
                          name="returnLocations"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={returnLocations}
                          onChange={(e) => setReturnLocations(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px", visibility: "hidden" }}>
                      <label
                        htmlFor="returnLocations"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Return Locations
                        <br />
                        <input
                          type="text"
                          name="returnLocations"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={returnLocations}
                          onChange={(e) => setReturnLocations(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label style={{ fontSize: "10px", fontWeight: "bold" }}>
                        Active?
                      </label>{" "}
                      <br />
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="active"
                          style={{ fontSize: "12px" }}
                          checked={active ? true : false}
                          onChange={(e) =>
                            setActive(e.target.value === "Yes" ? true : false)
                          }
                        />
                        Yes
                      </label>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="No"
                          name="active"
                          style={{ fontSize: "12px" }}
                          checked={active ? false : true}
                          onChange={(e) =>
                            setActive(e.target.value === "Yes" ? true : false)
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsContent(!isContent)}
            >
              {isContent ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Content
            </h5>
            <hr />

            {isContent && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="cc"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        CC
                        <br />
                        <input
                          type="text"
                          name="cc"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={cc}
                          onChange={(e) => setCc(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="replyTo"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Reply To
                        <br />
                        <input
                          type="text"
                          name="replyTo"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={replyTo}
                          onChange={(e) => setReplyTo(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="subject"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Subject
                    <br />
                    <input
                      type="text"
                      name="subject"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </label>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="content"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Content
                  </label>
                  <br />

                  <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    onEditorStateChange={setEditorState}
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                  />
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="attachments"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Attachments
                    <br />
                    {filePath.length !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {filePath.map((item: string, outerIndex: number) => {
                          return (
                            <>
                              <img
                                src={item}
                                width={40}
                                height={40}
                                alt="repairOrder"
                                onClick={() => {
                                  setFileIndex(outerIndex);
                                  setImagePopup(true);
                                }}
                              />
                              <RemoveCircleIcon
                                sx={{
                                  fontSize: "14px",
                                  color: colorConfigs.topbar.bg,
                                  fontWeight: "bold",
                                  "&: hover": {
                                    color: "red",
                                  },
                                }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this image?"
                                    )
                                  ) {
                                    let newFileSet: string[] = filePath.filter(
                                      (_, index: number) => index !== outerIndex
                                    );
                                    setFilePath(() => newFileSet);
                                  }
                                }}
                              />
                            </>
                          );
                        })}
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputFileRef}
                      onChangeCapture={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => {
                          if (inputFileRef.current !== null) {
                            inputFileRef.current.click();
                          }
                        }}
                      >
                        {" "}
                        <AddIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                        Add Image
                      </p>

                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={() => setShowWebcam(true)}
                      >
                        {" "}
                        <LocalSeeIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => setShowWebcam(false)}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();

                    handleCapture(imageSrc, `${baseURL}commonFile/upload`, (imageFilePath: string) => {
                      if (imageFilePath) {
                        setFilePath((prevFileString: string[]) => [
                          ...prevFileString,
                          `${baseURL}commonFile/Get?name=${imageFilePath}`,
                        ]);
                      }
                    })

                    setShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Attachement</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {filePath.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  left: "0",
                  top: "50%",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    cursor: fileIndex !== 0 ? "pointer" : "",
                    opacity: fileIndex === 0 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== 0) {
                      setFileIndex(fileIndex - 1);
                    }
                  }}
                />
              </div>
              <img
                width={700}
                height={350}
                src={filePath[fileIndex]}
                alt="repairOrder"
              />

              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  right: "0",
                  top: "50%",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    cursor: fileIndex !== filePath.length - 1 ? "pointer" : "",
                    opacity: fileIndex === filePath.length - 1 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== filePath.length - 1) {
                      setFileIndex(fileIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}