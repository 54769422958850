import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Webcam from "react-webcam";
import SignatureCanvas from "react-signature-canvas";
import axois from "../../../configs/axois";
import AddDriver from "./AddDriver";
import { format } from "date-fns";
import useAuth from "../../../components/hooks/useAuth";
import PleaseWaitPopup from "../../PleaseWaitPopup";
import { handleCapture } from "../../../utils/covertTheBase64FormatIntoImage";
import { getVehicleReplacementByReservationId, put_VehicleReplace } from "../../../services/VehicleReplcae";
import { validateMin } from "../../../utils/validateMin";

type Props = {
  goToNextPage: (page: number) => void;
  reservation: any;
  reservationUpdate: () => void;
  signatureURL: any;
  setSignatureURL: (value: any) => void;
  initialsURL: any;
  setInitialsURL: (Value: any) => void;
  signatureChange: boolean;
  setSignatureChange: (value: boolean) => void;
  securityDepositAmountToPaid: string;
  outstandingBalance: number;
  vehicleClassId: string;
  branchId: string;
  pickupDate: string;
  pickupTime: string;
  returnDate: string;
  returnTime: string;
  isCompletedOrCancelled: boolean
  currentPage: string;
};

const fuelLevelOptions: any = [];
for (let i = 0; i <= 8; i++) {
  fuelLevelOptions.push(<option key={i} value={i}>{i}</option>);
}

export default function Checkout({
  goToNextPage,
  reservation,
  reservationUpdate,
  signatureURL,
  setSignatureURL,
  initialsURL,
  setInitialsURL,
  signatureChange,
  setSignatureChange,
  securityDepositAmountToPaid,
  outstandingBalance,
  vehicleClassId,
  branchId,
  pickupDate,
  pickupTime,
  returnDate,
  returnTime,
  isCompletedOrCancelled,
  currentPage
}: Props) {
  const { auth }: any = useAuth();
  const [isPickupInformation, setIsPickupInformation] = useState<boolean>(true);
  const [isSignatures, setIsSignatures] = useState<boolean>(true);
  const [vehicle, setVehicle] = useState<string>("");

  const [processPaymentBox, setProcessPaymentBox] = useState<boolean>(false);
  const [processSecurityDeposit, setProcessSecurityDeposit] =
    useState<boolean>(false);
  const [amount, setAmount] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [paymentCheck, setPaymentCheck] = useState<boolean>(false);

  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<any[]>([]);
  const [newFilePath, setNewFilePath] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [isImageSaved, setIsImageSaved] = useState<boolean>(false);
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

  const [pickupLocationOptions, setPickupLocationOptions] = useState([]);
  const [isPickupLocationUpdate, setIsPickupLocationUpdate] =
    useState<boolean>(false);

  const currentDate = new Date();
  const [pickUpDates, setPickUpDate] = useState(
    currentDate.toISOString().split("T")[0]
  );
  const [pickUpTimes, setPickUpTime] = useState(format(currentDate, "kk:mm"));
  const [pickupLocations, setPickupLocations] = useState<string>("");
  const [fuelLevelAtCheckOut, setFuelLevelAtCheckOut] = useState<string>("0");
  const [odometerAtCheckOut, setOdometerAtCheckOut] = useState<string>("");
  const [localAddress, setLocalAddress] = useState<string>("");
  const [recalculateRatesOnLatePickup, setRecalculateRatesOnLatePickup] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [isVehiclesDataLoaded, setIsVehiclesDataLoaded] = useState<boolean>(false);
  const [replaceVehicleData, setReplaceVehicleData] = useState<any[]>([]);
  const [isVehicleUpdated, setIsVehicleUpdated] = useState<boolean>(false);
  const [confirmIsClick, setConfirmIsClick] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  //Add Driver
  const [isDrivers, setIsDrivers] = useState<boolean>(true);
  const [drivers, setDrivers] = useState<any[]>([]);
  const [driverId, setDriverId] = useState<string>("0");
  const [isPrimaryDriver, setIsPrimaryDriver] = useState<boolean>(false);
  const [newDriverAdded, setNewDriverAdded] = useState<boolean>(true);
  const [addNewDiver, setAddNewDriver] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>("");
  const [customers, setCustomers] = useState<any[]>([]);
  const [extraInfoCustomers, setExtraInfoCustomers] = useState<any[]>([]);
  const [currentVehicleClassId, setCurrentVehicleClassId] = useState<number>(0);
  const [idTypes, setIdTypes] = useState<any[]>([]);

  useEffect(() => {
    if (currentPage === 'Check Out') {
      axois
        .get(`${baseURL}vehicles`)
        .then((response) => {
          setVehicles(() =>
            response.data.map((item: any) => {
              return {
                value: item.id,
                label: item.name,
                vehicleClassId: item.vehicleClassId,
                odometer: item.odometer,
                fuelLevel: item.fuelLevel,
              };
            })
          );

          setIsVehiclesDataLoaded(true);

        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [currentPage]);

  useEffect(() => {
    if (reservation && isVehiclesDataLoaded && currentPage === 'Check Out') {
      if (reservation?.isVehicleReplaced) {
        getVehicleReplacementByReservationId(reservation?.id)
          .then((res) => {
            setReplaceVehicleData(res);
            setIsVehicleUpdated(true)
          })
          .catch((error) => {
            console.error(error)
          })
      }
      else {
        setIsVehicleUpdated(true)
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation, isVehiclesDataLoaded, currentPage]);

  useEffect(() => {
    if (currentPage === 'Check Out') {
      axios
        .get(`${baseURL}location`)
        .then((response) => {
          setPickupLocationOptions(() =>
            response.data
              .filter(
                (data: any) => data.branchId === parseInt(reservation?.branchId)
              )
              .map((item: any) => {
                return {
                  value: item.id,
                  label: item.locationName,
                };
              })
          );

          setIsPickupLocationUpdate(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reservation, currentPage]);

  useEffect(() => {
    if (reservation && isPickupLocationUpdate && isVehicleUpdated && currentPage === 'Check Out') {
      if (replaceVehicleData.length > 0) {
        const currentVehicle = replaceVehicleData.filter((vehicle: any) => vehicle?.isVehicleReplaced === false)[0];
        setVehicle(currentVehicle?.vehicleId);
        setCurrentVehicleClassId(currentVehicle?.vehicleClassId)
        setOdometerAtCheckOut(currentVehicle?.odometerAtCheckOut);
        setFuelLevelAtCheckOut(currentVehicle?.fuelLevelAtCheckOut);
        setPickupLocations(currentVehicle?.pickupLoationId);
        setLocalAddress(currentVehicle?.localAddress);
      }
      else {
        setVehicle(reservation.vehicleId);
        setCurrentVehicleClassId(reservation?.vehicleClassId);
        setOdometerAtCheckOut(reservation.odometerAtCheckOut);
        setFuelLevelAtCheckOut(reservation.fuelLevelAtCheckOut);
        setPickupLocations(reservation.pickupLoationId);
        setLocalAddress(reservation?.localAddress);
      }
    }
  }, [reservation, isPickupLocationUpdate, isVehicleUpdated, currentPage]);


  useEffect(() => {
    if (parseInt(vehicle) > 0 && isVehiclesDataLoaded && currentPage === "Check Out") {
      setOdometerAtCheckOut(
        () =>
          vehicles.find((item: any) => item.value === parseInt(vehicle))
            ?.odometer
      );

      setFuelLevelAtCheckOut(
        () =>
          vehicles.find((item: any) => item.value === parseInt(vehicle))
            ?.fuelLevel
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservation, isVehiclesDataLoaded, currentPage, vehicle])

  useEffect(() => {
    if (reservation && isVehicleUpdated && currentPage === 'Check Out') {
      if (replaceVehicleData.length > 0) {
        axois
          .get(`${baseURL}fileUploadData/Checkout-VehicleReplacement/${replaceVehicleData.filter((vehicle: any) => vehicle?.isVehicleReplaced === false)[0]?.id}`)
          .then((response) => {
            setFilePath(() =>
              response.data
                .map((item: any) => {
                  return {
                    id: item.id,
                    fileName: `${baseURL}reservationFile/Get?name=${item.fileName}`,
                  };
                })
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
      else {
        axois
          .get(`${baseURL}fileUploadData/Reservation/${reservation?.id}`)
          .then((response) => {
            setFilePath(() =>
              response.data
                .filter((data: any) => data.tabName === "Checkout")
                .map((item: any) => {
                  return {
                    id: item.id,
                    fileName: `${baseURL}reservationFile/Get?name=${item.fileName}`,
                  };
                })
            );
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [reservation, isVehicleUpdated, currentPage]);

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isCompletedOrCancelled) {
      setPleaseWaitPopup(true);
      const selectedFiles = e.target.files as FileList;

      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        formData.append("ImageFile", selectedFiles[0]);
        axios
          .post(`${baseURL}reservationFile/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((response) => {
            const newFilePath: any = {
              id: 0,
              fileName:
                `${baseURL}reservationFile/Get?name=` +
                response.data,
            };

            setNewFilePath((prevPath: string[]) => [
              ...prevPath,
              newFilePath.fileName,
            ]);
            setFilePath((prevPath: any[]) => [...prevPath, newFilePath]);
            setPleaseWaitPopup(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        setPleaseWaitPopup(false);
      }
    }
  };

  let signaturePadRef = React.useRef<SignatureCanvas>(null);
  let initialsPadRef = React.useRef<SignatureCanvas>(null);

  const signatureFileRef = useRef<HTMLInputElement>(null);
  const onSignatureFileChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isCompletedOrCancelled) {
      setPleaseWaitPopup(true);
      const selectedFiles = e.target.files as FileList;

      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        formData.append("ImageFile", selectedFiles[0]);

        axios
          .post(`${baseURL}reservationFile/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((response) => {
            const newFilePath: any = {
              id: 0,
              fileName: response.data,
            };
            signaturePadRef.current?.clear();
            setSignatureURL(newFilePath);
            setPleaseWaitPopup(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        setPleaseWaitPopup(false);
      }
    }
  };

  const initialsFileRef = useRef<HTMLInputElement>(null);
  const onInitialsFileChangeCapture = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isCompletedOrCancelled) {
      setPleaseWaitPopup(true);
      const selectedFiles = e.target.files as FileList;

      if (selectedFiles.length !== 0) {
        const formData = new FormData();
        formData.append("ImageFile", selectedFiles[0]);

        axios
          .post(`${baseURL}reservationFile/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            withCredentials: true,
          })
          .then((response) => {
            const newFilePath: any = {
              id: 0,
              fileName: response.data,
            };

            initialsPadRef.current?.clear();
            setInitialsURL(newFilePath);
            setPleaseWaitPopup(false);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        setPleaseWaitPopup(false);
      }
    }
  };

  useEffect(() => {
    if (reservation && newDriverAdded) {
      axios
        .get(`${baseURL}reservationAdditionalDriver`, {
          params: { reservationId: reservation?.id },
        })
        .then((response) => {
          const filteredDrivers = response.data.filter(
            (item: any) => item.reservationId === reservation?.id
          );
          setDrivers(filteredDrivers);
          setNewDriverAdded(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reservation, newDriverAdded]);

  useEffect(() => {
    axios
      .get(`${baseURL}Customer`)
      .then((response) => {
        setCustomers(
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
              dob: item.birthday,
            };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [customerId]);

  useEffect(() => {
    axios
      .get(`${baseURL}customerExtraInformation`)
      .then((response) => {
        setExtraInfoCustomers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [customerId]);

  useEffect(() => {
    axios
      .get(`${baseURL}IdType`)
      .then((response) => {
        setIdTypes(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const calculateAge = (date: string) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isCompletedOrCancelled) {
      if (Number(securityDepositAmountToPaid) !== 0) {
        window.alert("Please clear the security deposit");
        return;
      }

      if (Number(outstandingBalance) !== 0) {
        window.alert("Please clear the outstanding balance");
        return;
      }

      if (confirmIsClick) {
        setPleaseWaitPopup(true);

        if (isSubmitting) {
          if (newFilePath.length !== 0) {
            newFilePath.forEach((item: string, index: number) => {
              if (replaceVehicleData.length > 0) {
                axios
                  .post(
                    `${baseURL}fileUploadData`,
                    JSON.stringify({
                      attributeName: "Checkout-VehicleReplacement",
                      attributeId: replaceVehicleData.filter((vehicle: any) => vehicle?.isVehicleReplaced === false)[0]?.id,
                      fileName: item?.replace(`${baseURL}reservationFile/Get?name=`, ""),
                      tabName: "VehicleReplacement",
                      userId: auth?.id ?? 0,
                      createdDate: new Date(),
                    }),
                    {
                      headers: { "Content-Type": "application/json" },
                      withCredentials: true,
                    }
                  )
                  .then(() => {
                    if (newFilePath.length - 1 === index) {
                      uploadSignature();
                    }
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                  });
              }
              else {
                axios
                  .post(
                    `${baseURL}fileUploadData`,
                    JSON.stringify({
                      attributeName: "Reservation",
                      attributeId: reservation?.id,
                      fileName: item?.replace(`${baseURL}reservationFile/Get?name=`, ""),
                      tabName: "Checkout",
                      userId: auth?.id ?? 0,
                      createdDate: new Date(),
                    }),
                    {
                      headers: { "Content-Type": "application/json" },
                      withCredentials: true,
                    }
                  )
                  .then(() => {
                    if (newFilePath.length - 1 === index) {
                      uploadSignature();
                    }
                  })
                  .catch((error) => {
                    const errorMessage = error?.response?.data?.message || error?.message || "File upload failed.";
                    window.alert(errorMessage);
                    setPleaseWaitPopup(false);
                  });
              }
            });
          } else {
            uploadSignature();
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!isCompletedOrCancelled) {
      if (isImageSaved) {
        if (replaceVehicleData.length > 0) {
          put_VehicleReplace(
            {
              ...replaceVehicleData.filter((vehicle: any) => vehicle?.isVehicleReplaced === false)[0],
              dateTimeCheckOut: pickUpDates + "T" + pickUpTimes,
              fuelLevelAtCheckOut,
              odometerAtCheckOut,
              localAddress,
              vehicleId: vehicle,
              status: "Rental",
              rentalUser: auth.givenname,
              rentalAt: new Date()
            }, auth?.id)
            .then((response) => {
              axios
                .put(
                  `${baseURL}reservation`,
                  {
                    ...reservation,
                    vehicleId: vehicle,
                    status: "Rental",
                    rentalUser: auth.givenname,
                    rentalAt: new Date()
                  },
                  {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                  }
                )
                .then((response) => {
                  setIsImageSaved(false);
                  setConfirmIsClick(false);
                  setPleaseWaitPopup(false);
                  reservationUpdate();
                  setNewFilePath([]);
                  goToNextPage(7);
                })
                .catch((error) => {
                  const errorMessage = error?.response?.data?.message ||
                    error?.message || "An error occurred while completing the reservation.";
                  window.alert(errorMessage);
                  setPleaseWaitPopup(false);
                  setIsImageSaved(false);
                });

            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
        else {
          axios
            .put(
              `${baseURL}reservation`,
              {
                ...reservation,
                dateTimeCheckOut: pickUpDates + "T" + pickUpTimes,
                fuelLevelAtCheckOut,
                odometerAtCheckOut,
                localAddress,
                vehicleId: vehicle,
                status: "Rental",
                rentalUser: auth.givenname,
                rentalAt: new Date()
              },
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then((response) => {
              setIsImageSaved(false);
              setConfirmIsClick(false);
              setPleaseWaitPopup(false);
              reservationUpdate();
              setNewFilePath([]);
              goToNextPage(7);
            })
            .catch((error) => {
              const errorMessage = error?.response?.data?.message ||
                error?.message || "An error occurred while completing the reservation.";
              window.alert(errorMessage);
              setPleaseWaitPopup(false);
              setIsImageSaved(false);
            });
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImageSaved]);

  const converTheBase64IntoFileAndUploadToServer = (canvas: HTMLCanvasElement, callback: any) => {
    if (!isCompletedOrCancelled) {
      canvas.toBlob((blob) => {
        if (blob) {
          const formData = new FormData();
          formData.append("ImageFile", blob, 'signature.png');
          axios
            .post(`${baseURL}reservationFile/upload`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
              withCredentials: true,
            })
            .then((response) => {
              callback(response.data);
            })
            .catch((error) => {
              console.error("Error:", error);
              callback("");
            });
        } else {
          console.error("Error: Blob is null");
          callback("");
        }
      }, 'image/png');
    }
  }

  const uploadSignature = () => {
    if (!isCompletedOrCancelled) {
      if (signatureURL && signatureURL?.id > 0) {
        uploadInitial();
        signaturePadRef.current?.clear();
      } else if (signatureURL && signatureURL?.id === 0) {
        axios
          .post(
            `${baseURL}fileUploadData`,
            JSON.stringify({
              attributeName: "Reservation",
              attributeId: reservation?.id,
              fileName: signatureURL.fileName,
              tabName: "Signature",
              userId: auth?.id ?? 0,
              createdDate: new Date(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            uploadInitial();
            signaturePadRef.current?.clear();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        const canvas = signaturePadRef.current?.getTrimmedCanvas();
        if (canvas) {
          converTheBase64IntoFileAndUploadToServer(canvas, (imageFilePath: string) => {
            if (imageFilePath) {
              axios
                .post(
                  `${baseURL}fileUploadData`,
                  JSON.stringify({
                    attributeName: "Reservation",
                    attributeId: reservation?.id,
                    fileName: imageFilePath,
                    tabName: "Signature",
                    userId: auth?.id ?? 0,
                    createdDate: new Date(),
                  }),
                  {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                  }
                )
                .then(() => {
                  uploadInitial();
                  signaturePadRef.current?.clear();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            } else {
              uploadInitial();
            }
          });

        } else {
          uploadInitial();
        }
      }
    }
  };

  const uploadInitial = () => {
    if (!isCompletedOrCancelled) {
      if (initialsURL && initialsURL?.id > 0) {
        setIsImageSaved(true);
        setSignatureChange(true);
        initialsPadRef.current?.clear();
      } else if (initialsURL && initialsURL?.id === 0) {
        axios
          .post(
            `${baseURL}fileUploadData`,
            JSON.stringify({
              attributeName: "Reservation",
              attributeId: reservation?.id,
              fileName: initialsURL.fileName,
              tabName: "Initials",
              userId: auth?.id ?? 0,
              createdDate: new Date(),
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          )
          .then(() => {
            setIsImageSaved(true);
            setSignatureChange(true);
            initialsPadRef.current?.clear();
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        const canvas = initialsPadRef.current?.getTrimmedCanvas();
        if (canvas) {
          converTheBase64IntoFileAndUploadToServer(canvas, (imageFilePath: string) => {
            if (imageFilePath) {
              axios
                .post(
                  `${baseURL}fileUploadData`,
                  JSON.stringify({
                    attributeName: "Reservation",
                    attributeId: reservation?.id,
                    fileName: imageFilePath,
                    tabName: "Initials",
                    userId: auth?.id ?? 0,
                    createdDate: new Date(),
                  }),
                  {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                  }
                )
                .then(() => {
                  setIsImageSaved(true);
                  setSignatureChange(true);
                  initialsPadRef.current?.clear();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            } else {
              setIsImageSaved(true);
              setSignatureChange(true);
            }
          });
        } else {
          setIsImageSaved(true);
          setSignatureChange(true);
        }
      }
    }
  };

  const deleteTheSignatureURL = async () => {
    if (!isCompletedOrCancelled) {
      if (
        window.confirm("Are you sure you want to delete this Signature?") === true
      ) {
        if (signatureURL?.id === 0) {
          setSignatureURL(null);
        } else {
          await axios
            .delete(`${baseURL}fileUploadData?id=${signatureURL?.id}`)
            .then(() => {
              setSignatureURL(null);
              setSignatureChange(true);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    }
  };

  const deleteTheInitialsURL = async () => {
    if (!isCompletedOrCancelled) {
      if (
        window.confirm("Are you sure you want to delete this Initials?") === true
      ) {
        if (initialsURL.id === 0) {
          setInitialsURL(null);
        } else {
          await axios
            .delete(`${baseURL}fileUploadData?id=${initialsURL?.id}`)
            .then(() => {
              setInitialsURL(null);
              setSignatureChange(true);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "24px",
        }}
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <h5
            style={{
              paddingBottom: "0px",
              marginBlock: "0px",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setIsPickupInformation(!isPickupInformation)}
          >
            {isPickupInformation ? (
              <ExpandMoreIcon sx={{ fontSize: "20px" }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
            )}{" "}
            Pickup Information
          </h5>
          <hr />

          <div>
            {isPickupInformation && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >
                    <label
                      htmlFor="vin"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Vehicle
                      <br />
                      <select
                        name="vin"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={vehicle}
                        onChange={(e) => setVehicle(e.target.value)}
                        required
                      >
                        <option value={""}> </option>
                        {vehicles.filter((vehicle: any) => vehicle.vehicleClassId === currentVehicleClassId).map((item: any) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </label>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="status"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Fuel Level
                        <br />
                        <select
                          name="status"
                          id="cars"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={fuelLevelAtCheckOut}
                          onChange={(e) =>
                            setFuelLevelAtCheckOut(e.target.value)
                          }
                          required
                        >
                          {fuelLevelOptions}
                        </select>
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="vehicleMake"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Pickup Date
                        <br />
                        <input
                          type="date"
                          name="vin"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={pickUpDates}
                          onChange={(e) => setPickUpDate(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div>
                      <label
                        htmlFor=" vehicleKey"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Pickup Location
                        <br />
                        <select
                          name="status"
                          id="cars"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={pickupLocations}
                          onChange={(e) => setPickupLocations(e.target.value)}
                          required
                        >
                          {pickupLocationOptions.map((item: any) => {
                            return (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor=" vehicle"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Odometer at Pickup
                        <br />
                        <input
                          type="number"
                          min={0}
                          name="vehicle"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={odometerAtCheckOut}
                          onChange={(e) =>
                            setOdometerAtCheckOut(validateMin(e.target.value, 0).toString())
                          }
                          required
                        />
                      </label>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "50%", marginRight: "3%" }}>
                        <label
                          htmlFor="year"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Pickup Time
                          <br />
                          <input
                            type="time"
                            name="year"
                            style={{
                              width: "100%",
                              fontSize: "14px",
                              paddingBlock: "5px",
                            }}
                            value={pickUpTimes}
                            onChange={(e) => setPickUpTime(e.target.value)}
                            required
                          />
                        </label>
                      </div>

                      <div style={{ width: "50%" }}>
                        <label
                          htmlFor="recalculateRatesOnLatePickup"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Recalculate rates on late Pickup?
                          <br />
                          <div>
                            <label style={{ fontSize: "12px" }}>
                              <input
                                type="radio"
                                value="Yes"
                                name="recalculateRatesOnLatePickup"
                                style={{ fontSize: "12px" }}
                                checked={recalculateRatesOnLatePickup ? true : false}
                                onChange={(e) =>
                                  setRecalculateRatesOnLatePickup(e.target.value === "Yes" ? true : false)
                                }
                              />{" "}
                              Yes
                            </label>
                            <label style={{ fontSize: "12px" }}>
                              <input
                                type="radio"
                                value="No"
                                name="recalculateRatesOnLatePickup"
                                style={{ fontSize: "12px" }}
                                checked={recalculateRatesOnLatePickup ? false : true}
                                onChange={(e) =>
                                  setRecalculateRatesOnLatePickup(e.target.value === "Yes" ? true : false)
                                }
                              />{" "}
                              No
                            </label>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor=" vehicle"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Local Address
                    <br />
                    <input
                      type="text"
                      name="vehicle"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={localAddress}
                      onChange={(e) => setLocalAddress(e.target.value)}
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="image"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Images
                    <br />
                    <div
                      style={{
                        maxWidth: "50%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        flexWrap: "wrap"
                      }}
                    >
                      {filePath.map((item: any, outerIndex: number) => {
                        return (
                          <React.Fragment key={outerIndex}>
                            <img
                              src={item.fileName}
                              width={40}
                              height={40}
                              alt="repairOrder"
                              onClick={() => {
                                setFileIndex(outerIndex);
                                setImagePopup(true);
                              }}
                            />
                            <RemoveCircleIcon
                              sx={{
                                fontSize: "14px",
                                color: colorConfigs.topbar.bg,
                                fontWeight: "bold",
                                "&: hover": {
                                  color: "red",
                                },
                              }}
                              onClick={() => {
                                if (!isCompletedOrCancelled) {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this image?"
                                    )
                                  ) {
                                    let newFileSet: any[] = filePath.filter(
                                      (_, index: number) => index !== outerIndex
                                    );

                                    if (filePath[outerIndex].id === 0) {
                                      setFilePath(() => newFileSet);
                                    } else {
                                      axios
                                        .delete(
                                          `${baseURL}fileUploadData?id=${filePath[outerIndex].id}`
                                        )
                                        .then((response) => {
                                          setFilePath(() => newFileSet);
                                        })
                                        .catch((error) => {
                                          console.error("Error:", error);
                                        });
                                    }
                                  }
                                }
                              }}
                            />
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <input
                      type="file"
                      name="inputFileRef"
                      accept="image/*"
                      ref={inputFileRef}
                      onChangeCapture={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => {
                          if (inputFileRef.current !== null) {
                            inputFileRef.current.click();
                          }
                        }}
                      >
                        {" "}
                        <AddIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                        Add Image
                      </p>

                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={() => setShowWebcam(true)}
                      >
                        {" "}
                        <LocalSeeIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                      </p>
                    </div>
                  </label>
                </div>
              </>
            )}

            {/* <AddDriver
              reservation={reservation}
              vehicleClassId={vehicleClassId}
              branchId={branchId}
              pickupDate={pickupDate}
              pickupTime={pickupTime}
              returnDate={returnDate}
              returnTime={returnTime}
              handlePleaseWait={(pleaseWaitPopup) =>
                setPleaseWaitPopup(pleaseWaitPopup)
              }
              reservationUpdate={() => reservationUpdate()}
            /> */}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsDrivers(!isDrivers)}
            >
              {isDrivers ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Drivers
            </h5>
            <hr />

            {isDrivers && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "5%",
                      marginBottom: "0px",
                    }}
                  >
                    #
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "30%",
                      marginBottom: "0px",
                    }}
                  >
                    Name
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "40%",
                      marginBottom: "0px",
                    }}
                  >
                    Driver License
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "5%",
                      marginBottom: "0px",
                    }}
                  >
                    Age
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "start",
                      width: "20%",
                      marginBottom: "0px",
                    }}
                  >
                    Primary Driver ?
                  </p>
                </div>
                <hr style={{ marginBlock: "0px" }} />

                {drivers.map((item: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "5%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.customerId}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "30%",
                            marginBottom: "0px",
                            color: "blue",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            if (!isCompletedOrCancelled) {
                              setCustomerId(item.customerId);
                              setDriverId(item.id);
                              setAddNewDriver(true);
                              setIsPrimaryDriver(item.primaryDriver);
                            }
                          }}
                        >
                          {
                            customers.find(
                              (customer: any) => customer.value === item.customerId
                            )?.label
                          }
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "40%",
                            marginBottom: "0px",
                          }}
                        >
                          {idTypes.find(
                            (idType: any) =>
                              idType.id ===
                              extraInfoCustomers.find(
                                (customer: any) =>
                                  customer.customerId === item.customerId
                              )?.idTypeId
                          )?.name !== "undefined"
                            ? idTypes.find(
                              (idType: any) =>
                                idType.id ===
                                extraInfoCustomers.find(
                                  (customer: any) =>
                                    customer.customerId === item.customerId
                                )?.idTypeId
                            )?.name
                            : "" +
                            " " +
                            extraInfoCustomers.find(
                              (customer: any) =>
                                customer.customerId === item.customerId
                            )?.idNumber +
                            " " +
                            extraInfoCustomers
                              .find(
                                (customer: any) =>
                                  parseInt(customer.customerId) === parseInt(item.customerId)
                              )
                              ?.exprationDate.replaceAll("-", "/")
                              .split("T")[0]}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "5%",
                            marginBottom: "0px",
                          }}
                        >
                          {calculateAge(
                            customers.find(
                              (customer: any) => customer.value === item.customerId
                            )?.dob
                          )}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "start",
                            width: "20%",
                            marginBottom: "0px",
                          }}
                        >
                          {item.primaryDriver ? "Yes" : "No"}
                        </p>
                      </div>
                      <hr style={{ marginBlock: "0px" }} />
                    </React.Fragment>
                  );
                })}

                <Button
                  variant="contained"
                  sx={{
                    marginBlock: "16px",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    border: "none",
                    borderRadius: "0px",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    "&: hover": {
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    if (!isCompletedOrCancelled) {
                      setAddNewDriver(true);
                      setCustomerId("");
                      setDriverId("0");
                      setIsPrimaryDriver(false);
                    }
                  }}
                >
                  Add Driver
                </Button>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                WebkitUserSelect: "none" /* Safari */,
                msUserSelect: "none" /* IE 10 and IE 11 */,
                userSelect: "none" /* Standard syntax */,
              }}
              onClick={() => setIsSignatures(!isSignatures)}
            >
              {isSignatures ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Signatures
            </h5>
            <hr />

            {isSignatures && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Signature
                    </p>
                    <div
                      style={{
                        border: "1px dashed gray",
                        backgroundImage: `url('${baseURL}reservationFile/Get?name=${signatureURL?.fileName}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "auto"
                      }}
                    >
                      <SignatureCanvas
                        ref={signaturePadRef}
                        penColor={colorConfigs.topbar.bg}
                        canvasProps={{
                          width: 500,
                          height: 150,
                          className: "sigCanvas",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <i
                        style={{
                          fontSize: "18px",
                          backgroundColor: "orange",
                          color: "white",
                          padding: "3px 5px",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                        className="bx bxs-eraser"
                        onClick={() => {
                          if (!isCompletedOrCancelled) {
                            signaturePadRef.current?.clear();
                            if (signatureURL) {
                              setIsSubmitting(false);
                              deleteTheSignatureURL();
                            }
                          }
                        }}
                      ></i>
                      <input
                        type="file"
                        accept="image/*"
                        name="signatureFileRef"
                        ref={signatureFileRef}
                        onChangeCapture={onSignatureFileChangeCapture}
                        style={{ display: "none" }}
                      />
                      <i
                        style={{
                          fontSize: "18px",
                          backgroundColor: "skyblue",
                          color: "white",
                          padding: "3px 5px",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                        className="bx bx-upload"
                        onClick={() => {
                          if (!isCompletedOrCancelled) {
                            if (!signatureURL) {
                              if (signatureFileRef.current !== null) {
                                signatureFileRef.current.click();
                              }
                            } else {
                              if (signatureURL?.id === 0) {
                                if (signatureFileRef.current !== null) {
                                  signatureFileRef.current.click();
                                }
                              } else {
                                window.alert(
                                  "Please remove this Signature first."
                                );
                              }
                            }
                          }
                        }}
                      ></i>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Initials
                    </p>
                    <div
                      style={{
                        border: "1px dashed gray",
                        backgroundImage: `url('${baseURL}reservationFile/Get?name=${initialsURL?.fileName}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "auto"
                      }}
                    >
                      <SignatureCanvas
                        ref={initialsPadRef}
                        penColor={colorConfigs.topbar.bg}
                        canvasProps={{
                          width: 500,
                          height: 150,
                          className: "sigCanvas",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                        marginTop: "5px",
                      }}
                    >
                      <i
                        style={{
                          fontSize: "18px",
                          backgroundColor: "orange",
                          color: "white",
                          padding: "3px 5px",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                        className="bx bxs-eraser"
                        onClick={() => {
                          if (!isCompletedOrCancelled) {
                            initialsPadRef.current?.clear();
                            if (initialsURL) {
                              setIsSubmitting(false);
                              deleteTheInitialsURL();
                            }
                          }
                        }}
                      ></i>
                      <input
                        type="file"
                        accept="image/*"
                        name="initialsFileRef"
                        ref={initialsFileRef}
                        onChangeCapture={onInitialsFileChangeCapture}
                        style={{ display: "none" }}
                      />
                      <i
                        style={{
                          fontSize: "18px",
                          backgroundColor: "skyblue",
                          color: "white",
                          padding: "3px 5px",
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                        className="bx bx-upload"
                        onClick={() => {
                          if (!isCompletedOrCancelled) {
                            if (!initialsURL) {
                              if (initialsFileRef.current !== null) {
                                initialsFileRef.current.click();
                              }
                            } else {
                              if (initialsURL?.id === 0) {
                                if (initialsFileRef.current !== null) {
                                  initialsFileRef.current.click();
                                }
                              } else {
                                window.alert(
                                  "Please remove this Inititals first."
                                );
                              }
                            }
                          }
                        }}
                      ></i>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <button
                    style={{
                      marginBlock: "5px",
                      paddingBlock: "5px",
                      backgroundColor: "#66BB6A",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                    }}
                    type="submit"
                    onClick={() => {
                      if (!isCompletedOrCancelled) {
                        setIsSubmitting(true);
                        setConfirmIsClick(true);
                      }
                    }}
                  >
                    Confirm Check Out
                  </button>

                  <button
                    style={{
                      marginBlock: "5px",
                      paddingBlock: "5px",
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid gray",
                      cursor: "pointer",
                    }}

                  >
                    Free Vehicle Class Change
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
      </div>

      <Dialog
        open={processPaymentBox}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "40%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              {processSecurityDeposit
                ? "Authorize Security Deposit"
                : "Charge Customer"}
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => {
                if (!isCompletedOrCancelled) {
                  setProcessPaymentBox(false);
                  setProcessSecurityDeposit(false);
                }
              }}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {!processSecurityDeposit && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="amount"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Amount
                    <br />
                    <input
                      type="text"
                      name="amount"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="description"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Description
                    <br />
                    <input
                      type="text"
                      name="description"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>
            )}

            <p
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                fontSize: "12px",
                marginBottom: "0px",
              }}
            >
              Payment
            </p>

            <hr style={{ marginLeft: "16px", marginRight: "16px" }} />

            <div
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <label htmlFor="payment" style={{ fontSize: "12px" }}>
                <input
                  type="checkbox"
                  value="Yes"
                  name="payment"
                  style={{ fontSize: "12px", textAlign: "center" }}
                  defaultChecked={paymentCheck}
                  onChange={(e) =>
                    setPaymentCheck((paymentCheck) => !paymentCheck)
                  }
                />{" "}
                <i
                  style={{ fontSize: "26px", color: "blue" }}
                  className="bx bxl-visa"
                ></i>
                <i
                  style={{ fontSize: "26px", marginLeft: "5px" }}
                  className="bx bxl-mastercard"
                ></i>
              </label>
            </div>

            {paymentCheck && (
              <>
                <div
                  style={{
                    marginLeft: "16px",
                    marginRight: "16px",
                    marginBlock: "16px",
                    paddingBottom: "16px",
                    border: "1px solid gray",
                  }}
                >
                  <p
                    style={{
                      backgroundColor: "#eeeeee",
                      padding: "8px 5px",
                      marginBlock: "0px",
                    }}
                  >
                    Authorization Details - <strong>USD</strong>
                  </p>
                  <hr style={{ marginTop: "0px" }} />

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      gap: "16px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  >
                    <div style={{ width: "75%" }}>
                      <label
                        htmlFor="amount"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Credit Card Number
                        <br />
                        <input
                          type="text"
                          name="amount"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                      </label>

                      <label
                        htmlFor="amount"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Cardholder Name
                        <br />
                        <input
                          type="text"
                          name="amount"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ width: "25%" }}>
                      <label
                        htmlFor="description"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Expiration Date
                        <br />
                        <input
                          type="text"
                          placeholder="MM/YY"
                          name="description"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        />
                      </label>

                      <label
                        htmlFor="description"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        CCV Code
                        <br />
                        <input
                          type="text"
                          placeholder="CV"
                          name="description"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginLeft: "16px",
                    marginRight: "16px",
                    backgroundColor: colorConfigs.topbar.bg,
                    padding: "5px 8px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      color: "white",
                      marginBlock: "0px",
                    }}
                  >
                    Final Authorization
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      marginBlock: "0px",
                      color: "black",
                      backgroundColor: "white",
                      padding: "1px 5px",
                      borderRadius: "20px",
                    }}
                  >
                    $300.00
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "8px 16px 16px 16px",
                    color: "white",
                    backgroundColor: "#66BB6A",
                    padding: "5px 8px",
                    cursor: "pointer",
                    fontSize: "12px",
                  }}
                >
                  Authorize with Credit Card
                </div>
              </>
            )}
          </div>

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }} />

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => {
                  if (!isCompletedOrCancelled) {
                    setProcessPaymentBox(false);
                    setProcessSecurityDeposit(false);
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => {
                  if (!isCompletedOrCancelled)
                    setShowWebcam(false)
                }}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (!isCompletedOrCancelled) {
                    if (webRef.current !== null) {
                      //@ts-ignore
                      const imageSrc = webRef.current.getScreenshot();

                      handleCapture(imageSrc, `${baseURL}reservationFile/upload`, (imageFilePath: string) => {
                        if (imageFilePath) {
                          setNewFilePath((prevFileString: string[]) => [
                            ...prevFileString,
                            `${baseURL}reservationFile/Get?name=${imageFilePath}`,
                          ]);

                          const newFile: any = {
                            id: 0,
                            fileName: `${baseURL}reservationFile/Get?name=${imageFilePath}`,
                          };
                          setFilePath((prevFileString: any[]) => [
                            ...prevFileString,
                            newFile,
                          ]);
                        }
                      })

                      setShowWebcam(false);
                    }
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              Repair Order Image
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {filePath.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  left: "0",
                  top: "50%",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    cursor: fileIndex !== 0 ? "pointer" : "",
                    opacity: fileIndex === 0 ? "0.5" : "1",
                    color: "gray",
                  }}
                  onClick={() => {
                    if (fileIndex !== 0) {
                      setFileIndex(fileIndex - 1);
                    }
                  }}
                />
              </div>
              <img
                width={700}
                height={350}
                src={filePath[fileIndex]?.fileName}
                alt="repairOrder"
              />

              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  right: "0",
                  top: "50%",
                  color: "gray",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    cursor: fileIndex !== filePath.length - 1 ? "pointer" : "",
                    opacity: fileIndex === filePath.length - 1 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== filePath.length - 1) {
                      setFileIndex(fileIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <AddDriver
        setNewDriverAdded={setNewDriverAdded}
        addNewDiver={addNewDiver}
        setAddNewDriver={setAddNewDriver}
        customers={customers}
        setCustomers={setCustomers}
        customerId={customerId}
        setCustomerId={setCustomerId}
        drivers={drivers}
        driverId={driverId}
        isPrimaryDriver={isPrimaryDriver}
        setIsPrimaryDriver={setIsPrimaryDriver}

        reservation={reservation}
        vehicleClassId={currentVehicleClassId?.toString()}
        branchId={branchId}
        pickupDate={pickupDate}
        pickupTime={pickupTime}
        returnDate={returnDate}
        returnTime={returnTime}
        handlePleaseWait={(pleaseWaitPopup) =>
          setPleaseWaitPopup(pleaseWaitPopup)
        }
        reservationUpdate={() => reservationUpdate()}
      />

      <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
    </>
  );
}
