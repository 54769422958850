import { ReactNode, createContext, useState } from 'react'


const ConfirmContext = createContext({});

export const ConfirmProvider = ({ children }: { children: ReactNode }) => {
    const [confirmPopup, setConfirmPopup] = useState<boolean>(false);
    const [data, setData] = useState<any>();

    const value = { confirmPopup, setConfirmPopup, data, setData };

    return (
        <ConfirmContext.Provider value={value}>
            {children}
        </ConfirmContext.Provider>
    )
}

export default ConfirmContext
