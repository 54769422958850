import axios from "axios"
import baseURL from "../configs/api"


export const post_VehicleReplace = (data: any) => {
    return axios({
        url: `${baseURL}vehicleReplacement`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const put_VehicleReplace = (data: any, userId: number) => {
    return axios({
        url: `${baseURL}vehicleReplacement?userId=${userId}`,
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        data: data
    }).then(response => response.data);
}

export const getVehicleReplaceReportByReservationId = (primaryKey: string) => {
    return getFetch(`vehicleReplacement/GetVehicleReplacementReportByReservationId/${primaryKey}`);
}

export const getVehicleReplacementByReservationId = (primaryKey: string) => {
    return getFetch(`vehicleReplacement/GetVehicleReplacementsByReservationId/${primaryKey}`);
}

export const getVehicleReplaceId = (primaryKey: string) => {
    return getFetch(`vehicleReplacement/GetVehicleReplacement/${primaryKey}`);
}

const getFetch = (url: string, params = {}) => {
    return axios({
        url: baseURL + url,
        method: "GET",
        params: params
    }).then(response => response.data);
}