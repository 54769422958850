import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import colorConfigs from "../../../../configs/colorConfigs";
import CloseIcon from "@mui/icons-material/Close";
import baseURL from "../../../../configs/api";
import axois from "../../../../configs/axois";
import axios from "axios";

type Props = {
  id: string;
  popup: boolean;
  hidePopup: () => void;
  isSaved: () => void;
};

export default function EditAddOnCategory({
  id,
  popup,
  hidePopup,
  isSaved,
}: Props) {
  const [name, setName] = useState<string>("");
  const [roleId, setRoleId] = useState<string>("");
  const [order, setOrder] = useState<string>("");

  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(() => {
    axois
      .get(`${baseURL}roles`)
      .then((response) => {
        setRoleOptions(
          response.data.map((item: any) => {
            return { value: item.id, label: item.roleName };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (popup) {
      axois
        .get(`${baseURL}addOnCategory/${id}`)
        .then((response) => {
          setName(response.data.name);
          setRoleId(response.data.roleId);
          setOrder(response.data.order);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [popup]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .put(
        `${baseURL}addOnCategory`,
        JSON.stringify({
          id,
          name,
          roleId,
          order,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        isSaved();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteTheCategory = async () => {
    if (
      window.confirm("Are you sure you want to delete this Category?") === true
    ) {
      await axios
        .delete(`${baseURL}addOnCategory?id=${id}`)
        .then(() => {
          isSaved();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <Dialog
      open={popup}
      maxWidth="md"
      PaperProps={{
        style: {
          width: "35%",
          marginTop: "-300px",
        },
      }}
    >
      <DialogTitle sx={{ padding: "0px", width: "100%" }}>
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 16px 8px 16px",
          }}
        >
          <p style={{ fontSize: "14px", marginLeft: "5px" }}>
            Edit Add-On-Category
          </p>
          <CloseIcon
            sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
            onClick={() => hidePopup()}
          />
        </div>
        <hr style={{ marginBlock: "0px" }} />
      </DialogTitle>

      <DialogContent sx={{ padding: "0px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "16px 16px 0px 16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "62px",
                  borderStyle: "solid solid none solid",
                  borderWidth: "1px",
                  borderColor: "gray",
                  textAlign: "center",
                  padding: "5px 5px 0px 5px",
                  fontSize: "12px",
                }}
              >
                English
              </div>

              <div
                style={{
                  width: "100%",
                  borderStyle: "none none solid none",
                  borderWidth: "1px",
                  borderColor: "gray",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                padding: "0px 16px",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="label"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Label
                    <br />
                    <input
                      type="text"
                      name="label"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div
                  style={{
                    width: "50%",
                    marginRight: "3%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="firstName"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Allowed Roles for Edit
                      <br />
                      <select
                        name="roles"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={roleId}
                        onChange={(e) => setRoleId(e.target.value)}
                      >
                        {roleOptions.map((role: any) => {
                          return (
                            <option key={role.value} value={role.value}>
                              {role.label}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "50%",
                padding: "0px 16px 16px 16px",
              }}
            >
              <div style={{ marginTop: "10px" }}>
                <label
                  htmlFor="order"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Order
                  <br />
                  <input
                    type="text"
                    name="order"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              ></div>
            </div>

            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                backgroundColor: "#eeeeee",
              }}
            >
              <div style={{ width: "50%" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: "red",
                      color: "white",
                    },
                  }}
                  onClick={() => deleteTheCategory()}
                >
                  Delete
                </Button>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: "#e0dee0",
                      color: colorConfigs.sidebar.color,
                    },
                  }}
                  onClick={() => hidePopup()}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
