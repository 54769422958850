import { AccountBalanceWalletRounded, ArrowDownwardRounded, ArrowUpwardRounded, AttachMoneyRounded, BuildRounded, SavingsRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Box, Typography } from '@mui/material';
import axois from "../../../configs/axois";
import baseURL from "../../../configs/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { format } from "date-fns";

type VehicleReservationsProps = {
    vehicleId: any,
    vehicleSelectedClassData: any | null;
}

const VehicleReservations = ({ vehicleId, vehicleSelectedClassData }: VehicleReservationsProps) => {
    const [revenue, setRevenue] = useState<number>(0);
    const [totalExpense, setTotalExpense] = useState<number>(0);
    const [earnings, setEarnings] = useState<number>(0);
    const [totalRepairs, setTotalRepairs] = useState<number>(0);
    const [otherExpenses, setOtherExpenses] = useState<number>(0);
    const [roiToDate, setROIToDate] = useState<number>(0);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [columnsHeader, setColumnsHeader] = useState<GridColDef[]>([]);
    const [reservationHistoryData, setReservationHistoryData] = useState<any[]>([]);

    useEffect(() => {
        const columns: GridColDef[] = [
            {
                field: "bookingNumber",
                headerName: "Reservation",
                width: 300,
                editable: false,
                sortable: false,
                renderCell: (params) => {
                    return (
                        <Link to="/carrental/reservations/editreservation" state={params.id}>
                            {params.value}
                        </Link>
                    );
                },
            },
            {
                field: "pickupDate",
                headerName: "Pickup",
                width: 150,
                editable: false,
                sortable: false,
            },
            {
                field: "returenDate",
                headerName: "Return",
                width: 150,
                editable: false,
                sortable: false,
            },
            {
                field: "pickupLocationName",
                headerName: "Pickup Location",
                width: 150,
                editable: false,
                sortable: false,
            },
            {
                field: "returnLocationName",
                headerName: "Return Loaction",
                editable: false,
                sortable: false,
                width: 150,
            },
            {
                field: "fuelAtPickup",
                headerName: "Fuel At Pickup",
                width: 120,
                editable: false,
                sortable: false,
            },
            {
                field: "fuelAtReturn",
                headerName: "Fuel At Return",
                width: 120,
                editable: false,
                sortable: false,
            },
            {
                field: "chargeFuleToClient",
                headerName: "Charge Fule To Client",
                width: 150,
                editable: false,
                sortable: false,
            },
            {
                field: "odometerAtPickup",
                headerName: "Odometer At Pickup",
                width: 150,
                editable: false,
                sortable: false,
            },
            {
                field: "odometerAtReturn",
                headerName: "Odometer At Return",
                width: 150,
                editable: false,
                sortable: false,
            },
            {
                field: "extraChargePerMile",
                headerName: vehicleSelectedClassData ? "Extra charge Per Mile" : `Extra miles charge $${vehicleSelectedClassData?.additionalChargeIdforExceededDistance?.toFixed(2)} per mile`,
                width: 150,
                editable: false,
                sortable: false,
                renderCell: (params) => {
                    return (
                        params.id === 'summary' ? (
                            <span style={{ fontWeight: "bold" }}>{params.value}</span>
                        )
                            : (
                                params.value
                            )
                    )

                }
            },
            {
                field: "totalRevenue",
                headerName: "Total Revenue",
                width: 110,
                editable: false,
                sortable: false,
                renderCell: (params) => {
                    return (
                        params.id === 'summary' ? (
                            <span style={{ fontWeight: "bold" }}>{params.value}</span>
                        )
                            : (
                                params.value
                            )
                    )

                }
            },
            {
                field: "daysRented",
                headerName: "Days Rented",
                width: 100,
                editable: false,
                sortable: false,
                renderCell: (params) => {
                    return (
                        params.id === 'summary' ? (
                            <span style={{ fontWeight: "bold" }}>{params.value}</span>
                        )
                            : (
                                params.value
                            )
                    )

                }
            },
            {
                field: "status",
                headerName: "Status",
                width: 100,
                editable: false,
                sortable: false,
                renderCell: (params) => {
                    let color = "#bdbdbd";
                    switch (params.value) {
                        case "Quote":
                            color = "#bdbdbd";
                            break;
                        case "Open":
                            color = "#f0ad4e";
                            break;
                        case "Rental":
                            color = "#66BB6A";
                            break;
                        case "Completed":
                            color = "#253455";
                            break;
                        case "Cancelled":
                            color = "#ff0000";
                            break;
                        case "Pending":
                            color = "#808080";
                            break;
                        case "No Show":
                            color = "#e0e0e0";
                            break;
                    }


                    return (
                        params?.id !== 'summary' ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                <p
                                    style={{
                                        color: "white",
                                        background: color,
                                        padding: "2px 4px",
                                        textAlign: "center",
                                        borderRadius: "2px",
                                        fontSize: "10px",
                                        margin: "0",
                                        display: "inline-block",
                                        lineHeight: "12px",
                                    }}
                                >
                                    {params.value}
                                </p>
                            </div>
                        ) :
                            (<></>)
                    );
                }
            },

        ];

        setColumnsHeader(columns);
    }, [vehicleSelectedClassData])

    useEffect(() => {
        if (vehicleId && vehicleId > 0) {
            axois
                .get(`${baseURL}vehicles/GetVehicleFinanceReport/${vehicleId}`)
                .then((response) => {
                    setRevenue(response.data?.revenue);
                    setTotalExpense(response.data?.totalExpenses);
                    setEarnings(response.data?.earnings);
                    setTotalRepairs(response.data?.totalRepirs);
                    setOtherExpenses(response.data?.otherExpenses);
                    setROIToDate(response.data?.otherExpenses);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [vehicleId])

    useEffect(() => {
        if (vehicleId && vehicleId > 0) {
            setIsDataLoaded(false)
            let summaryRevenue = 0;
            let summaryCharge = 0;
            let summaryDaysRented = 0;

            fetch(`${baseURL}reservation/GetReservationsHistoryByVehicle/${vehicleId}`)
                .then((res) => res.json())
                .then((data) => {
                    const result = data?.map((item: any) => {
                        summaryCharge += item?.extraChargePerMile ? item?.extraChargePerMile : 0;
                        summaryRevenue += item?.totalRevenue ? item?.totalRevenue : 0;
                        summaryDaysRented += item?.daysRented ? item?.daysRented : 0;
                        return {
                            ...item,
                            pickupDate: format(new Date(item?.pickupDate), "dd-MM-yyyy hh:mm aaaaa'm'"),
                            returenDate: format(new Date(item?.returenDate), "dd-MM-yyyy hh:mm aaaaa'm'"),
                            chargeFuleToClient: item?.chargeFuleToClient ? "Yes" : "No",
                            fuelAtPickup: `${item?.fuelAtPickup ? item?.fuelAtPickup : ''} / 8`,
                            fuelAtReturn: `${item?.fuelAtReturn ? item?.fuelAtReturn : ''} / 8`,
                            extraChargePerMile: `$${item?.extraChargePerMile ? item?.extraChargePerMile : 0}`,
                            totalRevenue: `$${item?.totalRevenue ? item?.totalRevenue : 0}`
                        }
                    })

                    const summary = {
                        id: 'summary',
                        bookingNumber: '',
                        pickupDate: '',
                        returenDate: '',
                        pickupLocationName: '',
                        returnLocationName: '',
                        fuelAtPickup: '',
                        fuelAtReturn: '',
                        chargeFuleToClient: '',
                        odometerAtPickup: '',
                        odometerAtReturn: '',
                        extraChargePerMile: `$${summaryCharge}`,
                        totalRevenue: `$${summaryRevenue}`,
                        daysRented: summaryDaysRented,
                        status: '',
                    }

                    result.push(summary)
                    setReservationHistoryData(result);
                    setIsDataLoaded(true);

                })
                .catch((error) => {
                    console.error(error);
                    setReservationHistoryData([]);
                    setIsDataLoaded(true);
                });
        }
    }, [vehicleId])

    return (
        <>
            <div
                style={{
                    width: "100%"
                }}
            >
                <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                    Finances
                </h3>
                <hr />
                <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    padding: "10px 0px 10px 24px",
                    gap: "8px"
                }}>
                    <PresentationBox
                        name={"Revenue"}
                        balance={revenue}
                        symbol="$"
                        bgColor="#295B7E"
                        icon={<ArrowUpwardRounded style={{ color: "white", fontSize: "34px" }} />} />


                    <PresentationBox
                        name={"Total Expenses"}
                        balance={totalExpense}
                        symbol="$"
                        bgColor="#ff0000a3"
                        icon={<ArrowDownwardRounded style={{ color: "white", fontSize: "34px" }} />} />

                    <PresentationBox
                        name={"Earnings"}
                        balance={earnings}
                        symbol="$"
                        bgColor="#78c862"
                        icon={<AttachMoneyRounded style={{ color: "white", fontSize: "34px" }} />} />


                    <PresentationBox
                        name={"Total Repairs"}
                        balance={totalRepairs}
                        bgColor="#f6a62f"
                        symbol="$"
                        icon={<BuildRounded style={{ color: "white", fontSize: "34px" }} />} />

                    <PresentationBox
                        name={"Other Expenses"}
                        balance={otherExpenses}
                        bgColor="#f6a62f"
                        symbol="$"
                        icon={<AccountBalanceWalletRounded style={{ color: "white", fontSize: "34px" }} />} />

                    <PresentationBox
                        name={"ROI to Date"}
                        balance={roiToDate}
                        symbol=""
                        bgColor="#78c862"
                        icon={<SavingsRounded style={{ color: "white", fontSize: "34px" }} />} />


                </div>
            </div>

            <div
                style={{
                    width: "100%"
                }}
            >
                <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                    Reservatioins history
                </h3>
                <hr />
                <div style={{
                    width: "100%",
                    height: "70vh",
                }}>
                    {isDataLoaded ? reservationHistoryData.length > 0 ? (

                        <DataGrid
                            rows={reservationHistoryData}
                            columns={columnsHeader}
                            hideFooterPagination={true}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            showCellVerticalBorder={false}
                            sx={{
                                border: "none",
                                fontSize: "12px",
                                wordWrap: "break-word",
                                overflow: "visible",
                            }}
                        />



                    ) : (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <p>No Data Found</p>

                        </div>
                    ) : (
                        <div className="loading" style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                        }}>
                            <p>Please wait</p>
                            <span>
                                <i></i>
                                <i></i>
                            </span>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export default VehicleReservations;

type PresentationBoxProps = {
    name: string;
    balance: number;
    bgColor?: string;
    icon: any;
    symbol: string;
}

const PresentationBox = ({ name, balance, bgColor, icon, symbol }: PresentationBoxProps) => {
    return (
        <Box sx={{ background: "transparent", padding: "8px 16px", width: { sm: "26%", md: "30%" } }}>
            <Box sx={{ display: "flex", flexDirection: { sm: "column", md: "row" }, alignItems: "center", gap: { sm: "4px", md: "8px" } }}>
                <Box sx={{ width: { sm: 42, md: 50 }, height: { sm: 42, md: 50 }, backgroundColor: bgColor, padding: { sm: "4px", md: "8px" }, borderRadius: "50%" }}>
                    {icon}
                </Box>
                <div>
                    <Typography variant="h3" sx={{ fontSize: { sm: "24px", md: "32px" }, fontWeight: "bold" }}>
                        {symbol}{new Intl.NumberFormat('en-us').format(balance)}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: { sm: "16px", md: "12px" } }}>
                        {name}
                    </Typography>
                </div>
            </Box>
        </Box>
    )
}