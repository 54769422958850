import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import colorConfigs from "../../../../configs/colorConfigs";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../../configs/api";
import axois from "../../../../configs/axois";
import AddOpenHour from "./AddOpenHour";
import PhoneNumberInput from "../../../../components/common/PhoneNumberInput";
import { validateMin } from "../../../../utils/validateMin";

const pages = ["Edit"];

type OpenHourType = {
  id: number;
  locationId: number;
  startDate: string;
  endDate: string;
  branchId: number;
  isSundayOpen: boolean;
  sundayOpenTime: string;
  sundayCloseTime: string;
  isMondayOpen: boolean;
  mondayOpenTime: string;
  mondayCloseTime: string;
  isTuesdayOpen: boolean;
  tuesdayOpenTime: string;
  tuesdayCloseTime: string;
  isWednesdayOpen: boolean;
  wednesdayOpenTime: string;
  wednesdayCloseTime: string;
  isThursdayOpen: boolean;
  thursdayOpenTime: string;
  thursdayCloseTime: string;
  isFridayOpen: boolean;
  fridayOpenTime: string;
  fridayCloseTime: string;
  isSaturdayOpen: boolean;
  saturdayOpenTime: string;
  saturdayCloseTime: string;
  uniqueId: number;
};

export const EditFleetLocation = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const [locationName, setLocationName] = useState<string>("");
  const [isAirport, setIsAirport] = useState<boolean>(true);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [isShowOnWebsite, setIsShowOnWebsite] = useState<boolean>(true);
  const [prefixForReservation, setPrefixForReservation] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isImages, setIsImages] = useState<boolean>(true);
  const [mandatoryAdditionalCharges, setMandatoryAdditionalCharges] =
    useState<string>("");
  const [tax, setTax] = useState<string>("");
  const [locationFee, setLocationFee] = useState<string>("");
  const [governmentTax, setGovernmentTax] = useState<string>("");
  const [supportedCurrencies, setSupportedCurrencies] = useState<string>("");
  const [taxLabel, setTaxLabel] = useState<string>("");
  const [locationFeeLabel, setLocationFeeLabel] = useState<string>("");
  const [
    defaultFuelPricePerLiterOrGallon,
    setDefaultFuelPricePerLiterOrGallon,
  ] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [websiteLink, setWebsiteLink] = useState<string>("");
  const [preventBookingsInClosedHours, setPreventBookingsInClosedHours] =
    useState<boolean>(true);
  const [allowPickupDuringClosedHours, setAllowPickupDuringClosedHours] =
    useState<boolean>(true);
  const [
    minimumHoursRequiredBeforePickup,
    setMinimumHoursRequiredBeforePickup,
  ] = useState<string>("");
  const [allowReturnDuringClosedHours, setAllowReturnDuringClosedHours] =
    useState<boolean>(true);

  const [addOpenHour, setAddOpenHour] = useState<OpenHourType[]>([]);
  const [uniqueNumber, setUniqueNumber] = useState<number>(1);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);

  const [firstTime, setFirstTime] = React.useState<String>(pages[0]);

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}branch`)
      .then((response) => {
        setBranches(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.branchName };
          })
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const idNumber = useLocation();
  useEffect(() => {
    axois
      .get(`${baseURL}location/${idNumber.state}`)
      .then((response) => {
        setLocationName(response.data.locationName);
        setPrefixForReservation(response.data.prefixForReservation);
        setBranchId(response.data.branchId);
        setIsAirport(response.data.isAirport);
        setWebsiteLink(response.data.websiteLink);
        setEmailAddress(response.data.emailAddress);
        setPhoneNumber(response.data.phoneNumber);
        setIsShowOnWebsite(response.data.isShowOnWebsite);
        setIsActive(response.data.isActive);
        setIsImages(response.data.isImages);
        setStreet(response.data.street);
        setCity(response.data.city);
        setState(response.data.state);
        setZipCode(response.data.zipCode);
        setCountry(response.data.country);
        setLatitude(response.data.latitude);
        setLongitude(response.data.longitude);
        setSupportedCurrencies(response.data.supportedCurrencies);
        setLocationFeeLabel(response.data.locationFeeLabel);
        setLocationFee(response.data.locationFee);
        setTaxLabel(response.data.taxLabel);
        setTax(response.data.tax);
        setMandatoryAdditionalCharges(response.data.mandatoryAdditionalCharges);
        setGovernmentTax(response.data.governmentTax);
        setDefaultFuelPricePerLiterOrGallon(
          response.data.defaultFuelPricePerLiterOrGallon
        );
        setPreventBookingsInClosedHours(
          response.data.preventBookingsInClosedHours
        );
        setMinimumHoursRequiredBeforePickup(
          response.data.minimumHoursRequiredBeforePickup
        );
        setAllowReturnDuringClosedHours(
          response.data.allowReturnDuringClosedHours
        );
        setAllowPickupDuringClosedHours(
          response.data.allowPickupDuringClosedHours
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axois
      .get(`${baseURL}locationOpenHour`)
      .then((response) => {
        setAddOpenHour(() =>
          response.data
            .filter((item: any) => item.locationId === parseInt(idNumber.state))
            .map((item: any, index: number) => {
              return {
                ...item,
                startDate: item.startDate && item.startDate.split("T")[0],
                endDate: item.endDate && item.endDate.split("T")[0],
                uniqueId: index + 1,
              };
            })
        );

        setIsFirstTime(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (isFirstTime) {
      setUniqueNumber(addOpenHour.length + 1);
      setIsFirstTime(false);
    }
  }, [isFirstTime]);

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const openHourData = addOpenHour.map((item: any) => {
      if (item.id === 0) {
        return {
          startDate: item.startDate,
          endDate: item.endDate,
          branchId: branchId,
          isSundayOpen: item.isSundayOpen,
          sundayOpenTime: item.sundayOpenTime,
          sundayCloseTime: item.sundayCloseTime,
          isMondayOpen: item.isMondayOpen,
          mondayOpenTime: item.mondayOpenTime,
          mondayCloseTime: item.mondayCloseTime,
          isTuesdayOpen: item.isTuesdayOpen,
          tuesdayOpenTime: item.tuesdayOpenTime,
          tuesdayCloseTime: item.tuesdayCloseTime,
          isWednesdayOpen: item.isWednesdayOpen,
          wednesdayOpenTime: item.wednesdayOpenTime,
          wednesdayCloseTime: item.wednesdayCloseTime,
          isThursdayOpen: item.isThursdayOpen,
          thursdayOpenTime: item.thursdayOpenTime,
          thursdayCloseTime: item.thursdayCloseTime,
          isFridayOpen: item.isFridayOpen,
          fridayOpenTime: item.fridayOpenTime,
          fridayCloseTime: item.fridayCloseTime,
          isSaturdayOpen: item.isSaturdayOpen,
          saturdayOpenTime: item.saturdayOpenTime,
          saturdayCloseTime: item.saturdayCloseTime,
        };
      } else {
        return item;
      }
    });

    await axios
      .put(
        `${baseURL}location`,
        JSON.stringify({
          id: idNumber.state,
          locationName,
          prefixForReservation,
          branchId,
          isAirport,
          websiteLink,
          emailAddress,
          phoneNumber,
          isShowOnWebsite,
          isActive,
          isImages,
          street,
          city,
          state,
          zipCode,
          country,
          latitude,
          longitude,
          supportedCurrencies,
          locationFeeLabel,
          locationFee,
          taxLabel,
          tax,
          mandatoryAdditionalCharges,
          governmentTax,
          defaultFuelPricePerLiterOrGallon,
          preventBookingsInClosedHours,
          minimumHoursRequiredBeforePickup,
          allowReturnDuringClosedHours,
          allowPickupDuringClosedHours,
          locationOpenHours: openHourData,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const deleteTheBranch = async () => {
    if (
      window.confirm("Are you sure you want to delete this Location?") === true
    ) {
      await axios
        .delete(`${baseURL}location?id=${idNumber.state}`)
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const deleteTheFleetOpenHour = async (uniqueNumber: number) => {
    if (
      window.confirm("Are you sure you want to delete this Open Hour?") === true
    ) {
      if (
        addOpenHour.find((item: any) => item.uniqueId === uniqueNumber)?.id ===
        0
      ) {
        const updateData: OpenHourType[] = addOpenHour.filter(
          (item: OpenHourType) => item.uniqueId !== uniqueNumber
        );
        setAddOpenHour(() => updateData);
      } else {
        await axios
          .delete(
            `${baseURL}locationOpenHour?id=${addOpenHour.find((item: any) => item.uniqueId === uniqueNumber)
              ?.id
            }`
          )
          .then((response) => {
            const updateData: OpenHourType[] = addOpenHour.filter(
              (item: OpenHourType) => item.uniqueId !== uniqueNumber
            );
            setAddOpenHour(() => updateData);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const addNewRow = () => {
    const addOpenHourData: OpenHourType = {
      id: 0,
      locationId: 0,
      startDate: "",
      endDate: "",
      branchId: 0,
      isSundayOpen: false,
      sundayOpenTime: "",
      sundayCloseTime: "",
      isMondayOpen: false,
      mondayOpenTime: "",
      mondayCloseTime: "",
      isTuesdayOpen: false,
      tuesdayOpenTime: "",
      tuesdayCloseTime: "",
      isWednesdayOpen: false,
      wednesdayOpenTime: "",
      wednesdayCloseTime: "",
      isThursdayOpen: false,
      thursdayOpenTime: "",
      thursdayCloseTime: "",
      isFridayOpen: false,
      fridayOpenTime: "",
      fridayCloseTime: "",
      isSaturdayOpen: false,
      saturdayOpenTime: "",
      saturdayCloseTime: "",
      uniqueId: uniqueNumber,
    };

    setAddOpenHour((addOpenHour: OpenHourType[]) => [
      ...addOpenHour,
      addOpenHourData,
    ]);
    setUniqueNumber(uniqueNumber + 1);
  };

  const changeDataOnOpenHour = (data: OpenHourType, index: number) => {
    setAddOpenHour((addOpenHour) =>
      addOpenHour.map((rowValue: OpenHourType, i: number) => {
        return i === index ? data : rowValue;
      })
    );
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <CreditCardIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Fleet Locations / Edit Fleet Location
          </Typography>
        </Box>
        <div>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "red",
              borderRadius: "0",
              textTransform: "capitalize",
            }}
            onClick={() => deleteTheBranch()}
          >
            Delete
          </Button>
        </div>
      </Toolbar>

      <AppBar
        position="static"
        sx={{
          backgroundColor: colorConfigs.sidebar.bg,
          border: "none",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "black", paddingBlock: "4px" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              paddingTop: "8px",
              paddingLeft: "0px",
              marginLeft: "-20px",
            }}
          >
            {pages.map((page) => {
              if (firstTime === page) {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",

                      backgroundColor: "white",
                      borderWidth: "1px",
                      borderColor: "black",
                      borderStyle: "solid solid none solid",
                      "&: hover": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              } else {
                return (
                  <Button
                    disableElevation={false}
                    key={page}
                    onClick={() => handleOnClick(page)}
                    variant="text"
                    sx={{
                      my: 2,
                      color: "black",
                      display: "block",
                      paddingBlock: "0px",
                      marginBlock: "0px",
                      borderRadius: "0px",
                      fontSize: "12px",
                      fontWeight: "normal",
                      textTransform: "none",
                      textAlign: "center",
                      border: "none",
                      "&: focus": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                      "&: active": {
                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                      },
                    }}
                  >
                    {page}
                  </Button>
                );
              }
            })}
          </Box>
        </Container>
      </AppBar>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: "10px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        <div
          style={{
            width: "75%",
            marginRight: "3%",
          }}
        >
          <h3
            style={{
              paddingBottom: "0px",
              marginBottom: "0px",
              fontSize: "12px",
            }}
          >
            Location Overview
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "70%",
                  marginRight: "3%",
                }}
              >
                <label
                  htmlFor="locationName"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Name
                  <br />
                  <input
                    type="text"
                    name="locationName"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    required
                  />
                </label>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Is Airport?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="isAirport"
                      style={{ fontSize: "12px" }}
                      checked={isAirport}
                      onChange={(e) => setIsAirport(e.target.value === "Yes")}
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="isAirport"
                      style={{ fontSize: "12px" }}
                      checked={!isAirport}
                      onChange={(e) => setIsAirport(e.target.value === "Yes")}
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="emailAddress"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    CC Email?
                    <br />
                    <input
                      type="text"
                      name="emailAddress"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Show On Website?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="showOnWebsite"
                      style={{ fontSize: "12px" }}
                      checked={isShowOnWebsite}
                      onChange={(e) =>
                        setIsShowOnWebsite(e.target.value === "Yes")
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="showOnWebsite"
                      style={{ fontSize: "12px" }}
                      checked={!isShowOnWebsite}
                      onChange={(e) =>
                        setIsShowOnWebsite(e.target.value === "Yes")
                      }
                    />
                    No
                  </label>
                </div>
              </div>

              <div style={{ width: "80%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ width: "50%", marginRight: "3%" }}>
                    <label
                      htmlFor="prefixForReservation"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Prefix For Reservation #
                      <br />
                      <input
                        type="text"
                        name="prefixForReservation"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={prefixForReservation}
                        onChange={(e) =>
                          setPrefixForReservation(e.target.value)
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ width: "50%" }}>
                    <label
                      htmlFor="branchId"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Branch
                      <br />
                      <select
                        name="branchId"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={branchId}
                        onChange={(e) => setBranchId(e.target.value)}
                        required
                      >
                        {branches.map((item: any) => {
                          return (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  </div>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="phoneNumber"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Phone Number
                    <br />
                    <PhoneNumberInput
                      name={"phoneNumber"}
                      value={phoneNumber}
                      setValue={setPhoneNumber}
                      fontSize={"14px"}
                      paddingBlock={"5px"}
                      required={true} />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Active?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="active"
                      style={{ fontSize: "12px" }}
                      checked={isActive ? true : false}
                      onChange={(e) =>
                        setIsActive(e.target.value == "Yes" ? true : false)
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="active"
                      style={{ fontSize: "12px" }}
                      checked={isActive ? false : true}
                      onChange={(e) =>
                        setIsActive(e.target.value === "Yes" ? true : false)
                      }
                    />
                    No
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Images?
                  </label>{" "}
                  <br />
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="Yes"
                      name="isImages"
                      style={{ fontSize: "12px" }}
                      checked={isImages ? true : false}
                      onChange={(e) =>
                        setIsImages(e.target.value === "Yes" ? true : false)
                      }
                    />
                    Yes
                  </label>
                  <label style={{ fontSize: "12px" }}>
                    <input
                      type="radio"
                      value="No"
                      name="isImages"
                      style={{ fontSize: "12px" }}
                      checked={isImages ? false : true}
                      onChange={(e) =>
                        setIsImages(e.target.value === "Yes" ? true : false)
                      }
                    />
                    No
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                marginRight: "3%",
                marginTop: "20px",
              }}
            >
              <h3
                style={{
                  paddingBottom: "0px",
                  marginBottom: "0px",
                  fontSize: "12px",
                }}
              >
                Additonal Charges & Fees
              </h3>
              <hr />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    width: "70%",
                    marginRight: "3%",
                  }}
                >
                  <label
                    htmlFor="mandatoryAdditionalCharges"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Mandatory Additonal Charges
                    <br />
                    <input
                      type="number"
                      min={0}
                      name="mandatoryAdditionalCharges"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={mandatoryAdditionalCharges}
                      onChange={(e) =>
                        setMandatoryAdditionalCharges(validateMin(e.target.value, 0).toString())
                      }
                      required
                    />
                  </label>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="tax"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Tax (%)
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="tax"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={tax}
                        onChange={(e) => setTax(validateMin(e.target.value, 0).toString())}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="locationFee"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Location Fee (%)
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="locationFee"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={locationFee}
                        onChange={(e) => setLocationFee(validateMin(e.target.value, 0).toString())}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="governmentTax"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Government Tax
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="governmentTax"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={governmentTax}
                        onChange={(e) => setGovernmentTax(validateMin(e.target.value, 0).toString())}
                        required
                      />
                    </label>
                  </div>
                </div>

                <div style={{ width: "80%" }}>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div>
                      <label
                        htmlFor="supportedCurrencies"
                        style={{ fontSize: "12px", fontWeight: "bold" }}
                      >
                        Supported Currencies
                        <br />
                        <input
                          type="text"
                          name="supportedCurrencies"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={supportedCurrencies}
                          onChange={(e) =>
                            setSupportedCurrencies(e.target.value)
                          }
                          required
                        />
                      </label>
                    </div>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="taxLabel"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Tax Label
                      <br />
                      <input
                        type="text"
                        name="taxLabel"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={taxLabel}
                        onChange={(e) => setTaxLabel(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="locationFeeLabel"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Location Fee Label
                      <br />
                      <input
                        type="text"
                        name="locationFeeLabel"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={locationFeeLabel}
                        onChange={(e) => setLocationFeeLabel(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="defaultFuelPricePerLiterOrGallon"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Default Fuel Price Per Liter Or Gallon
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="defaultFuelPricePerLiterOrGallon"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={defaultFuelPricePerLiterOrGallon}
                        onChange={(e) =>
                          setDefaultFuelPricePerLiterOrGallon(validateMin(e.target.value, 0).toString())
                        }
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  marginRight: "3%",
                  marginTop: "30px",
                }}
              >
                <h3
                  style={{
                    paddingBottom: "0px",
                    marginBottom: "0px",
                    fontSize: "12px",
                  }}
                >
                  Opening Details
                </h3>
                <hr />

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      width: "70%",
                      marginRight: "3%",
                    }}
                  >
                    <div>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Prevent Bookings In Closed Hours
                      </label>{" "}
                      <br />
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="preventBookingsInClosedHours"
                          style={{ fontSize: "12px" }}
                          checked={preventBookingsInClosedHours ? true : false}
                          onChange={(e) =>
                            setPreventBookingsInClosedHours(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />
                        Yes
                      </label>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="No"
                          name="preventBookingsInClosedHours"
                          style={{ fontSize: "12px" }}
                          checked={preventBookingsInClosedHours ? false : true}
                          onChange={(e) =>
                            setPreventBookingsInClosedHours(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />
                        No
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Allow Pickup During Closed Hours
                      </label>{" "}
                      <br />
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="allowPickupDuringClosedHours"
                          style={{ fontSize: "12px" }}
                          checked={allowPickupDuringClosedHours ? true : false}
                          onChange={(e) =>
                            setAllowPickupDuringClosedHours(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />
                        Yes
                      </label>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="No"
                          name="allowPickupDuringClosedHours"
                          style={{ fontSize: "12px" }}
                          checked={allowPickupDuringClosedHours ? false : true}
                          onChange={(e) =>
                            setAllowPickupDuringClosedHours(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>

                  <div style={{ width: "80%" }}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div>
                        <label
                          htmlFor="minimumHoursRequiredBeforePickup"
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                        >
                          Minimum Hours Required Before Pickup
                          <br />
                          <input
                            type="number"
                            min={0}
                            name="minimumHoursRequiredBeforePickup"
                            style={{
                              width: "100%",
                              fontSize: "14px",
                              paddingBlock: "5px",
                            }}
                            value={minimumHoursRequiredBeforePickup}
                            onChange={(e) =>
                              setMinimumHoursRequiredBeforePickup(
                                validateMin(e.target.value, 0).toString()
                              )
                            }
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                        Allow Return During Closed Hours
                      </label>{" "}
                      <br />
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="allowReturnDuringClosedHours"
                          style={{ fontSize: "12px" }}
                          checked={allowReturnDuringClosedHours ? true : false}
                          onChange={(e) =>
                            setAllowReturnDuringClosedHours(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />
                        Yes
                      </label>
                      <label style={{ fontSize: "12px" }}>
                        <input
                          type="radio"
                          value="No"
                          name="allowReturnDuringClosedHours"
                          style={{ fontSize: "12px" }}
                          checked={allowReturnDuringClosedHours ? false : true}
                          onChange={(e) =>
                            setAllowReturnDuringClosedHours(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {addOpenHour.map((item: OpenHourType, index: number) => {
                return (
                  <AddOpenHour
                    key={item.uniqueId}
                    index={index}
                    uniqueNumber={item.uniqueId}
                    data={item}
                    changeDataOnOpenHour={(data: OpenHourType, index: number) =>
                      changeDataOnOpenHour(data, index)
                    }
                    deleteTheFleetOpenHour={deleteTheFleetOpenHour}
                  />
                );
              })}

              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "10px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => addNewRow()}
              >
                Add New Open Hour Block
              </Button>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  type="submit"
                >
                  Save
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: "black",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
        <div
          style={{
            width: "50%",
          }}
        >
          <h3
            style={{
              paddingBottom: "0px",
              marginBottom: "0px",
              fontSize: "12px",
            }}
          >
            Address
          </h3>
          <hr />

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "100%",
                marginRight: "4%",
              }}
            >
              <label
                htmlFor="country"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Country
                <br />
                <input
                  type="text"
                  name="country"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                />
              </label>

              <div style={{ marginTop: "10px" }}>
                <label
                  htmlFor="city"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  City
                  <br />
                  <input
                    type="text"
                    name="city"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    htmlFor="state"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    State
                    <br />
                    <input
                      type="text"
                      name="state"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <label
                  htmlFor="zipCode"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Zip Code
                  <br />
                  <input
                    type="text"
                    name="zipCode"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    required
                  />
                </label>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <label
              htmlFor="street"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Street
              <br />
              <input
                type="text"
                name="street"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  paddingBlock: "5px",
                }}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                required
              />
            </label>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                marginRight: "4%",
              }}
            >
              <label
                htmlFor="latitude"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Latitude
                <br />
                <input
                  type="number"
                  min={0}
                  name="latitude"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={latitude}
                  onChange={(e) => setLatitude(validateMin(e.target.value, 0).toString())}
                  required
                />
              </label>
            </div>

            <div style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label
                    htmlFor="longitude"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Longitude
                    <br />
                    <input
                      type="number"
                      min={0}
                      name="longitude"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={longitude}
                      onChange={(e) => setLongitude(validateMin(e.target.value, 0).toString())}
                      required
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
            }}
          >
            <h3
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                fontSize: "12px",
              }}
            >
              Website
            </h3>
            <hr />
            <div style={{ marginTop: "10px" }}>
              <label
                htmlFor="websiteLink"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Label
                <br />
                <input
                  type="text"
                  name="websiteLink"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={websiteLink}
                  onChange={(e) => setWebsiteLink(e.target.value)}
                  required
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
