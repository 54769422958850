import { Button } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import colorConfigs from "../../../configs/colorConfigs";
import { CurrentType } from "../../../enum/CurrentType";
import { getLoctionOpenHours } from "../../../services/locationOpenHour";
import moment from "moment";


type Props = {
  goToNextPage: (page: number) => void;
  getDataFromDate: (
    pickupDate: string,
    pickupTime: string,
    returnDate: string,
    returnTime: string,
    branchId: string,
    pickupLocation: string,
    returnLocation: string,
    walkInCustomer: boolean
  ) => void;
  branches: any[];
  locations: any[];
  popUpPickupDate: string;
  popUpReturnDate: string;
  popUpPickupTime: string;
  popUpReturnTime: string;
  selectBranchId: string;
  currentType: CurrentType;
  _pickupLocationId: string;
  _returnLocationId: string;
  _walkInCustomer: boolean;
};

export default function Dates({
  goToNextPage,
  getDataFromDate,
  branches,
  locations,
  popUpPickupDate,
  popUpReturnDate,
  popUpPickupTime,
  popUpReturnTime,
  selectBranchId,
  currentType,
  _pickupLocationId,
  _returnLocationId,
  _walkInCustomer,
}: Props) {
  const [pickupDate, setPickupDate] = useState<string>("");
  const [pickupTime, setPickupTime] = useState<string>("");
  const [returnDate, setReturnDate] = useState<string>("");
  const [returnTime, setReturnTime] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [pickupLocationId, setPickupLocationId] = useState<string>("");
  const [returnLocationId, setReturnLocationId] = useState<string>("");
  const [walkInCustomer, setWalkInCustomer] = useState<boolean>(true);

  const [branchs, setBranchs] = useState<any[]>([]);

  const [pickupLocationOptions, setPickupLocationOptions] = useState<any[]>([]);
  const [pickupLocationOptionsOfParticular, setPickupLocationOptionsOfParticular] = useState<any[]>([]);;

  const [returnLocationOptions, setReturnLocationOptions] = useState<any[]>([]);
  const [returnLocationOptionsOfParticular, setReturnLocationOptionsOfParticular] = useState<any[]>([]);

  const [locationOpenHour, setLocationOpenHour] = useState<any[]>([]);
  const [locationOpenHourOfParticularBranch, setLocationOpenHourOfParticularBranch] = useState<any[]>([]);

  const [pickupDateMessage, setpickupDateMessage] = useState<string>("");
  const [pickupTimeMessage, setpickupTimeMessage] = useState<string>("");
  const [returnDateMessage, setreturnDateMessage] = useState<string>("");
  const [returnTimeMessage, setreturnTimeMessage] = useState<string>("");
  const [minBookingMessage, setMinBookingMessage] = useState<string>("");

  const [isGreater, setIsGreater] = useState<boolean>(false);

  useEffect(() => {
    if (selectBranchId.length === 0 && locationOpenHour.length === 0) {
      getLoctionOpenHours().then(data => {
        setLocationOpenHour(data);
        setLocationOpenHourOfParticularBranch(data?.filter((item: any) => item.branchId === parseInt(selectBranchId) && (new Date(item.startDate) <= new Date() && new Date(item.endDate) >= new Date())));
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBranchId]);

  useEffect(() => {

    if (locationOpenHourOfParticularBranch.length !== 0 && currentType === CurrentType.ADD) {
      setTimeWithOpenHour(new Date(pickupDate ? pickupDate : popUpPickupDate), true);
      setTimeWithOpenHour(new Date(returnDate ? returnDate : popUpReturnDate), false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationOpenHourOfParticularBranch])

  const setTimeWithOpenHour = (date: Date, isPickupTime: boolean) => {
    switch (date.getDay()) {
      case 0:
        if (isPickupTime) {
          if (moment(moment().format('HH:mm'), 'HH:mm').isAfter(moment(locationOpenHourOfParticularBranch[0].sundayOpenTime, 'HH:mm'))) {
            setPickupTime(moment().format('HH:mm'));
          }
          else {
            setPickupTime(locationOpenHourOfParticularBranch[0].sundayOpenTime);
          }
        }
        else
          setReturnTime(locationOpenHourOfParticularBranch[0].sundayOpenTime);
        break;
      case 1:
        if (isPickupTime) {
          if (moment(moment().format('HH:mm'), 'HH:mm').isAfter(moment(locationOpenHourOfParticularBranch[0].mondayOpenTime, 'HH:mm'))) {
            setPickupTime(moment().format('HH:mm'));
          }
          else {
            setPickupTime(locationOpenHourOfParticularBranch[0].mondayOpenTime);
          }

        }
        else
          setReturnTime(locationOpenHourOfParticularBranch[0].mondayOpenTime);
        break;
      case 2:
        if (isPickupTime) {
          if (moment(moment().format('HH:mm'), 'HH:mm').isAfter(moment(locationOpenHourOfParticularBranch[0].tuesdayOpenTime, 'HH:mm'))) {
            setPickupTime(moment().format('HH:mm'));
          }
          else {
            setPickupTime(locationOpenHourOfParticularBranch[0].tuesdayOpenTime);
          }

        }

        else
          setReturnTime(locationOpenHourOfParticularBranch[0].tuesdayOpenTime);
        break;
      case 3:
        if (isPickupTime) {
          if (moment(moment().format('HH:mm'), 'HH:mm').isAfter(moment(locationOpenHourOfParticularBranch[0].wednesdayOpenTime, 'HH:mm'))) {
            setPickupTime(moment().format('HH:mm'));
          }
          else {
            setPickupTime(locationOpenHourOfParticularBranch[0].wednesdayOpenTime);
          }

        }

        else
          setReturnTime(locationOpenHourOfParticularBranch[0].wednesdayOpenTime);
        break;
      case 4:
        if (isPickupTime) {
          if (moment(moment().format('HH:mm'), 'HH:mm').isAfter(moment(locationOpenHourOfParticularBranch[0].thursdayOpenTime, 'HH:mm'))) {
            setPickupTime(() => moment().format('HH:mm'));
          }
          else {
            setPickupTime(locationOpenHourOfParticularBranch[0].thursdayOpenTime);
          }
        }
        else
          setReturnTime(locationOpenHourOfParticularBranch[0].thursdayOpenTime);
        break;
      case 5:
        if (isPickupTime) {
          if (moment(moment().format('HH:mm'), 'HH:mm').isAfter(moment(locationOpenHourOfParticularBranch[0].fridayOpenTime, 'HH:mm'))) {
            setPickupTime(moment().format('HH:mm'));
          }
          else {
            setPickupTime(locationOpenHourOfParticularBranch[0].fridayOpenTime);
          }

        }
        else
          setReturnTime(locationOpenHourOfParticularBranch[0].fridayOpenTime);
        break;
      case 6:
        if (isPickupTime) {
          if (moment(moment().format('HH:mm'), 'HH:mm').isAfter(moment(locationOpenHourOfParticularBranch[0].saturdayOpenTime, 'HH:mm'))) {
            setPickupTime(moment().format('HH:mm'));
          }
          else {
            setPickupTime(locationOpenHourOfParticularBranch[0].saturdayOpenTime);
          }

        }

        else
          setReturnTime(locationOpenHourOfParticularBranch[0].saturdayOpenTime);
        break;
    }
  }

  useEffect(() => {
    if (branches) {
      setBranchs(
        branches.map((item: any) => {
          return {
            value: item.id,
            label: item.branchName,
            locationId: item.defaultLocationId,
          };
        })
      );

      if (currentType === CurrentType.ADD) {
        setPickupLocationId(() => branches[0]?.defaultLocationId);
        setReturnLocationId(() => branches[0]?.defaultLocationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches]);

  useEffect(() => {
  }, [pickupTime])

  useEffect(() => {
    if (locations) {
      setPickupLocationOptions(
        locations.map((item: any) => {
          return {
            value: item.id,
            label: item.locationName,
            branchId: item.branchId,
          };
        })
      );

      setReturnLocationOptions(
        locations.map((item: any) => {
          return {
            value: item.id,
            label: item.locationName,
            branchId: item.branchId,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    if (parseInt(branchId) > 0 && pickupLocationOptions?.length > 0) {
      setPickupLocationOptionsOfParticular(pickupLocationOptions?.filter((item: any) => item.branchId === parseInt(branchId)));
    } else {
      setPickupLocationOptionsOfParticular([]);
    }

    if (parseInt(branchId) > 0 && returnLocationOptions?.length > 0) {
      setReturnLocationOptionsOfParticular(returnLocationOptions?.filter((item: any) => item.branchId === parseInt(branchId)));
    } else {
      setReturnLocationOptionsOfParticular([]);
    }

    if (parseInt(branchId) > 0 && locationOpenHour?.length > 0) {
      setLocationOpenHourOfParticularBranch(locationOpenHour?.filter((item: any) => item.branchId === parseInt(branchId) && (new Date(item.startDate) <= new Date() && new Date(item.endDate) >= new Date())));
    } else {
      setLocationOpenHourOfParticularBranch([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId, pickupLocationOptions, returnLocationOptions, locationOpenHour]);

  useEffect(() => {
    if (currentType === CurrentType.ADD) {
      setPickupLocationId(pickupLocationOptionsOfParticular[0]?.value);
      setReturnLocationId(pickupLocationOptionsOfParticular[0]?.value);
    } else if (currentType === CurrentType.EDIT) {
      setPickupLocationId(_pickupLocationId);
      setReturnLocationId(_returnLocationId);
      setWalkInCustomer(_walkInCustomer);
    } else if (currentType === CurrentType.ADD_FROM_QUOTES) {
      setPickupLocationId(_pickupLocationId);
      setReturnLocationId(_returnLocationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupLocationOptionsOfParticular])

  useEffect(() => {
    if (new Date(pickupDate).getTime() === new Date(returnDate).getTime() && returnTime <= pickupTime) {
      setIsGreater(true);
      setreturnTimeMessage("Return Time must greater than pickup Time");
    }
    else {
      setIsGreater(false);
      setreturnTimeMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickupDate, pickupTime, returnDate, returnTime]);

  const selectedBranch = useMemo(() => {
    return branches.find((branch) => branch.id === parseInt(branchId));
  }, [branchId, branches]);

  const minAllowedHoursToBook = selectedBranch ? selectedBranch.minAllowedHoursToBook : 0;

  useEffect(() => {
    const pickupDateTime = new Date(`${pickupDate}T${pickupTime}`);
    const returnDateTime = new Date(`${returnDate}T${returnTime}`);

    const timeDifferenceInMs = returnDateTime.getTime() - pickupDateTime.getTime();
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    if (new Date(pickupDate).getTime() === new Date(returnDate).getTime()) {
      if (timeDifferenceInHours < minAllowedHoursToBook) {
        setMinBookingMessage(
          `The minimum booking duration is ${minAllowedHoursToBook} hours.`
        );
      } else {
        setMinBookingMessage("");
      }
    } else {
      setMinBookingMessage("");
    }
  }, [pickupDate, pickupTime, returnDate, returnTime, minAllowedHoursToBook]);

  useEffect(() => {
    setPickupDate(() => popUpPickupDate);
  }, [popUpPickupDate])

  useEffect(() => {
    setReturnDate(() => popUpReturnDate);
  }, [popUpReturnDate])

  useEffect(() => {
    setPickupTime(() => popUpPickupTime);
  }, [popUpPickupTime])

  useEffect(() => {
    setReturnTime(() => popUpReturnTime);
  }, [popUpReturnTime])

  useEffect(() => {
    setBranchId(() => selectBranchId);
  }, [selectBranchId])

  const handleTheSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let pickupDateMessages = "";
    let returnDateMessages = "";
    let pickupTimeMessages = "";
    let returnTimeMessages = "";

    if (!isGreater) {

      if (
        new Date(locationOpenHourOfParticularBranch[0].startDate) <=
        new Date(pickupDate.replaceAll("-", "/")) &&
        new Date(locationOpenHourOfParticularBranch[0].endDate) >=
        new Date(returnDate.replaceAll("-", "/"))
      ) {
        switch (new Date(pickupDate.replaceAll("-", "/")).getDay()) {
          case 0:
            if (locationOpenHourOfParticularBranch[0].isSundayOpen) {
              pickupDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].sundayOpenTime <=
                pickupTime &&
                locationOpenHourOfParticularBranch[0].sundayCloseTime >=
                pickupTime
              ) {
                pickupTimeMessages = "";
              } else {
                pickupTimeMessages =
                  "Office on Sunday is open from " +
                  locationOpenHourOfParticularBranch[0].sundayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].sundayCloseTime;
              }
            } else {
              pickupDateMessages = "Office is close on Sunday";
            }
            break;
          case 1:
            if (locationOpenHourOfParticularBranch[0].isMondayOpen) {
              pickupDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].mondayOpenTime <=
                pickupTime &&
                locationOpenHourOfParticularBranch[0].mondayCloseTime >=
                pickupTime
              ) {
                pickupTimeMessages = "";
              } else {
                pickupTimeMessages = "Office on Monday is open from " +
                  locationOpenHourOfParticularBranch[0].mondayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].mondayCloseTime;
              }
            } else {
              pickupDateMessages = "Office is close on Monday";
            }
            break;
          case 2:
            if (locationOpenHourOfParticularBranch[0].isTuesdayOpen) {
              pickupDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].tuesdayOpenTime <=
                pickupTime &&
                locationOpenHourOfParticularBranch[0].tuesdayCloseTime >=
                pickupTime
              ) {
                pickupTimeMessages = "";
              } else {
                pickupTimeMessages =
                  "Office on Tuesday is open from " +
                  locationOpenHourOfParticularBranch[0].tuesdayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].tuesdayCloseTime;
              }
            } else {
              pickupDateMessages = "Office is close on Tuesday";
            }
            break;
          case 3:
            if (locationOpenHourOfParticularBranch[0].isWednesdayOpen) {
              pickupDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].wednesdayOpenTime <=
                pickupTime &&
                locationOpenHourOfParticularBranch[0].wednesdayCloseTime >=
                pickupTime
              ) {
                pickupTimeMessages = "";
              } else {
                pickupTimeMessages =
                  "Office on Wednesday is open from " +
                  locationOpenHourOfParticularBranch[0].wednesdayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].wednesdayCloseTime;
              }
            } else {
              pickupDateMessages = "Office is close on Wednesday";
            }
            break;
          case 4:
            if (locationOpenHourOfParticularBranch[0].isThursdayOpen) {
              pickupDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].thursdayOpenTime <=
                pickupTime &&
                locationOpenHourOfParticularBranch[0].thursdayCloseTime >=
                pickupTime
              ) {
                pickupTimeMessages = "";
              } else {
                pickupTimeMessages =
                  "Office on Thursday is open from " +
                  locationOpenHourOfParticularBranch[0].thursdayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].thursdayCloseTime;
              }
            } else {
              pickupDateMessages = "Office is close on Thursday";
            }
            break;
          case 5:
            if (locationOpenHourOfParticularBranch[0].isFridayOpen) {
              pickupDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].fridayOpenTime <=
                pickupTime &&
                locationOpenHourOfParticularBranch[0].fridayCloseTime >=
                pickupTime
              ) {
                pickupTimeMessages = "";
              } else {
                pickupTimeMessages =
                  "Office on Friday is open from " +
                  locationOpenHourOfParticularBranch[0].fridayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].fridayCloseTime;
              }
            } else {
              pickupDateMessages = "Office is close on Friday";
            }
            break;
          case 6:
            if (locationOpenHourOfParticularBranch[0].isSaturdayOpen) {
              pickupDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].saturdayOpenTime <=
                pickupTime &&
                locationOpenHourOfParticularBranch[0].saturdayCloseTime >=
                pickupTime
              ) {
                pickupTimeMessages = "";
              } else {
                pickupTimeMessages =
                  "Office on Saturday is open from " +
                  locationOpenHourOfParticularBranch[0].saturdayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].saturdayCloseTime;
              }
            } else {
              pickupDateMessages = "Office is close on Saturday";
            }
            break;
        }

        switch (new Date(returnDate.replaceAll("-", "/")).getDay()) {
          case 0:
            if (locationOpenHourOfParticularBranch[0].isSundayOpen) {
              returnDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].sundayOpenTime <=
                returnTime &&
                locationOpenHourOfParticularBranch[0].sundayCloseTime >=
                returnTime
              ) {
                returnTimeMessages = "";
              } else {
                returnTimeMessages =
                  "Office on Sunday is open from " +
                  locationOpenHourOfParticularBranch[0].sundayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].sundayCloseTime;
              }
            } else {
              returnDateMessages = "Office is close on Sunday";
            }
            break;
          case 1:
            if (locationOpenHourOfParticularBranch[0].isMondayOpen) {
              returnDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].mondayOpenTime <=
                returnTime &&
                locationOpenHourOfParticularBranch[0].mondayCloseTime >=
                returnTime
              ) {
                returnTimeMessages = "";
              } else {
                returnTimeMessages =
                  "Office on Monday is open from " +
                  locationOpenHourOfParticularBranch[0].mondayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].mondayCloseTime;
              }
            } else {
              returnDateMessages = "Office is close on Monday";
            }
            break;
          case 2:
            if (locationOpenHourOfParticularBranch[0].isTuesdayOpen) {
              returnDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].tuesdayOpenTime <=
                returnTime &&
                locationOpenHourOfParticularBranch[0].tuesdayCloseTime >=
                returnTime
              ) {
                returnTimeMessages = "";
              } else {
                returnTimeMessages =
                  "Office on Tuesday is open from " +
                  locationOpenHourOfParticularBranch[0].tuesdayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].tuesdayCloseTime;
              }
            } else {
              returnDateMessages = "Office is close on Tuesday";
            }
            break;
          case 3:
            if (locationOpenHourOfParticularBranch[0].isWednesdayOpen) {
              returnDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].wednesdayOpenTime <=
                returnTime &&
                locationOpenHourOfParticularBranch[0].wednesdayCloseTime >=
                returnTime
              ) {
                returnTimeMessages = "";
              } else {
                returnTimeMessages =
                  "Office on Wednesday is open from " +
                  locationOpenHourOfParticularBranch[0].wednesdayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].wednesdayCloseTime;
              }
            } else {
              returnDateMessages = "Office is close on Wednesday";
            }
            break;
          case 4:
            if (locationOpenHourOfParticularBranch[0].isThursdayOpen) {
              returnDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].thursdayOpenTime <=
                returnTime &&
                locationOpenHourOfParticularBranch[0].thursdayCloseTime >=
                returnTime
              ) {
                returnTimeMessages = "";
              } else {
                returnTimeMessages =
                  "Office on Thursday is open from " +
                  locationOpenHourOfParticularBranch[0].thursdayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].thursdayCloseTime;
              }
            } else {
              returnDateMessages = "Office is close on Thursday";
            }
            break;
          case 5:
            if (locationOpenHourOfParticularBranch[0].isFridayOpen) {
              returnDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].fridayOpenTime <=
                returnTime &&
                locationOpenHourOfParticularBranch[0].fridayCloseTime >=
                returnTime
              ) {
                returnTimeMessages = "";
              } else {
                returnTimeMessages =
                  "Office on Friday is open from " +
                  locationOpenHourOfParticularBranch[0].fridayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].fridayCloseTime;
              }
            } else {
              returnDateMessages = "Office is close on Friday";
            }
            break;
          case 6:
            if (locationOpenHourOfParticularBranch[0].isSaturdayOpen) {
              returnDateMessages = "";
              if (
                locationOpenHourOfParticularBranch[0].saturdayOpenTime <=
                returnTime &&
                locationOpenHourOfParticularBranch[0].saturdayCloseTime >=
                returnTime
              ) {
                returnTimeMessages = "";
              } else {
                returnTimeMessages =
                  "Office on Saturday is open from " +
                  locationOpenHourOfParticularBranch[0].saturdayOpenTime +
                  " to " +
                  locationOpenHourOfParticularBranch[0].saturdayCloseTime;
              }
            } else {
              returnDateMessages = "Office is close on Saturday";
            }
            break;
        }
      } else {
        pickupDateMessages = "Date is not allowed";
      }

      setpickupDateMessage(pickupDateMessages);
      setreturnDateMessage(returnDateMessages);
      setpickupTimeMessage(pickupTimeMessages);
      setreturnTimeMessage(returnTimeMessages);
      setMinBookingMessage(minBookingMessage);

      if (!pickupDateMessages
        && !returnDateMessages
        && !pickupTimeMessages
        && !returnTimeMessages
        && !minBookingMessage) {
        getDataFromDate(
          pickupDate,
          pickupTime,
          returnDate,
          returnTime,
          branchId,
          pickupLocationId,
          returnLocationId,
          walkInCustomer
        );
        goToNextPage(1);
      }
    }
  };

  const branchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBranchId(e.target.value);
    if (currentType === CurrentType.EDIT || currentType === CurrentType.ADD_FROM_QUOTES) {
      setPickupLocationId(branchs.find((item: any) => item.value === parseInt(e.target.value)).locationId);
      setReturnLocationId(branchs.find((item: any) => item.value === parseInt(e.target.value)).locationId);
    }
  }

  return (
    <div
      style={{
        width: "100%",
        padding: "24px",
      }}
    >
      <form onSubmit={handleTheSubmit}>
        <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
          Date & Time
        </h5>
        <hr />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              width: "50%",
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <div style={{ width: "50%" }}>
              <label
                htmlFor="pickupDate"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Pickup Date
                <br />
                <input
                  type="date"
                  min={currentType === CurrentType.ADD ? new Date().toISOString().split("T")[0] : ""}
                  name="pickupDate"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={pickupDate}
                  onChange={(e) => {
                    setPickupDate(e.target.value)

                    if (new Date(e.target.value) >= new Date(returnDate)) {
                      setReturnDate(moment(e.target.value).add(1, 'day').format('YYYY-MM-DD'))
                    }
                  }}
                  required
                />
                <br />
                <p style={{ color: "red", marginBlock: "0px" }}>
                  {pickupDateMessage}
                </p>
              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="pickupTime"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Pickup Time
                <br />
                <input
                  type="time"
                  name="pickupTime"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={pickupTime}
                  onChange={(e) => {
                    setPickupTime(e.target.value)
                  }}
                  required
                />
                <br />
                <p style={{ color: "red", marginBlock: "0px" }}>
                  {pickupTimeMessage}
                </p>
              </label>
            </div>
          </div>

          <div
            style={{
              marginTop: "10px",
              width: "50%",
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <div style={{ width: "50%" }}>
              <label
                htmlFor="returnDate"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Return Date
                <br />
                <input
                  type="date"
                  min={
                    pickupDate
                  }
                  name="returnDate"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={returnDate}
                  onChange={(e) => setReturnDate(e.target.value)}
                  required
                />
                <br />
                <p style={{ color: "red", marginBlock: "0px" }}>
                  {returnDateMessage}
                </p>


              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="returnTime"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Return Time
                <br />
                <input
                  type="time"
                  name="returnTime"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={returnTime}
                  onChange={(e) => setReturnTime(e.target.value)}
                  required
                />
                <br />
                {returnTimeMessage && (
                  <ul style={{ paddingLeft: "5px", marginBlock: "5px", color: "red" }}>
                    <li>{returnTimeMessage}</li>
                  </ul>
                )}

                {minBookingMessage && (
                  <ul style={{ paddingLeft: "5px", marginBlock: "5px", color: "red" }}>
                    <li>{minBookingMessage}</li>
                  </ul>
                )}
              </label>
            </div>
          </div>

          <div
            style={{
              marginTop: "10px",
              width: "75%",
              display: "flex",
              flexDirection: "row",
              gap: "8px",
            }}
          >
            <div style={{ width: "50%" }}>
              <label
                htmlFor="branch"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Branch
                <br />
                <select
                  name="branch"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={branchId}
                  onChange={branchChange}
                >
                  {branchs.map((item: any) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="pickupLocation"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Pickup Location
                <br />
                <select
                  name="pickupLocation"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={pickupLocationId}
                  onChange={(e) => setPickupLocationId(e.target.value)}
                >
                  {pickupLocationOptionsOfParticular.map((item: any) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>

            <div style={{ width: "50%" }}>
              <label
                htmlFor="returnLocation"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Return Location
                <br />
                <select
                  name="returnLocation"
                  id="cars"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                    paddingBlock: "5px",
                  }}
                  value={returnLocationId}
                  onChange={(e) => setReturnLocationId(e.target.value)}
                >
                  {returnLocationOptionsOfParticular.map((item: any) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>

          <div style={{ width: "50%", marginTop: "10px" }}>
            <label
              htmlFor="walkInCustomer"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Walk in Customer?
              <br />
              <div>
                <label style={{ fontSize: "12px" }}>
                  <input
                    type="radio"
                    value="Yes"
                    name="walkInCustomer"
                    style={{ fontSize: "12px" }}
                    checked={walkInCustomer ? true : false}
                    onChange={(e) =>
                      setWalkInCustomer(e.target.value === "Yes" ? true : false)
                    }
                  />{" "}
                  Yes
                </label>
                <label style={{ fontSize: "12px" }}>
                  <input
                    type="radio"
                    value="No"
                    name="walkInCustomer"
                    style={{ fontSize: "12px" }}
                    checked={walkInCustomer ? false : true}
                    onChange={(e) =>
                      setWalkInCustomer(e.target.value === "Yes" ? true : false)
                    }
                  />{" "}
                  No
                </label>
              </div>
            </label>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              color: "white",
              borderRadius: "0",
              border: "none",
              textTransform: "capitalize",
            }}
            type="submit"
          >
            Next Step
          </Button>
        </div>
      </form>
    </div>
  );
}
