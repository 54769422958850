import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../configs/colorConfigs";
import { useEffect, useState } from "react";
import { getAdditionalCharges } from "../../services/additionalCharge";
import PleaseWaitPopup from "../../pages/PleaseWaitPopup";
import { post_ReservationAdditionalCharge } from "../../services/reservationAdditionalCharge";
import { post_comment } from "../../services/comment";
import useAuth from "../hooks/useAuth";
import Select from 'react-select'
import { format } from "date-fns";
import { getAddOnCategories } from "../../services/addOnCategory";
import axios from "axios";
import baseURL from "../../configs/api";

type AddAdjustmentModalProps = {
    adjustmentIsOpen: boolean;
    setAdjustmentIsOpen: (value: boolean) => void;
    reservation: any;
    setAdjustmentRefresh: (value: boolean) => void;
    setIsExtraValueIsAdded: (value: boolean) => void;
    setReservationUpdate: (value: boolean) => void;
}

const AddAdjustmentModal = ({ adjustmentIsOpen, setAdjustmentIsOpen, reservation, setAdjustmentRefresh, setIsExtraValueIsAdded, setReservationUpdate }: AddAdjustmentModalProps) => {
    const { auth }: any = useAuth();
    const [addOnCategories, setAddOnCategories] = useState<any[]>([]);
    const [isAddOnCategories, setIsAddOnCategories] = useState<boolean>(false);
    const [selctedAdjusment, setSelectedAdjustment] = useState<number>(0);
    const [adjustmentOptions, setAdjustmentOptions] = useState<any[]>([]);
    const [selectionType, setSelectionType] = useState<string>("");
    const [price, setPrice] = useState<string>('0');
    const [comment, setComment] = useState<string>("");
    const [pleaseWait, setPleaseWait] = useState<boolean>(false);
    const [selectedChargeType, setSelectedChargeType] = useState<string>("Amount");

    useEffect(() => {
        getAddOnCategories()
            .then(data => {
                setAddOnCategories(
                    data.map((item: any) => {
                        return {
                            id: item.id,
                            categoryName: item.name,
                            roleId: item.roleId,
                        };
                    })
                );
                setIsAddOnCategories(true);
            })
            .catch((error) => {
                console.error(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (adjustmentIsOpen && isAddOnCategories) {
            getAdditionalCharges()
                .then(data => {
                    setAdjustmentOptions(
                        data.filter((value: any) => !value.hideFromReservations)
                            .map((item: any) => {
                                return {
                                    label: addOnCategories.find(
                                        (category: any) => category.id === item.addOnCategoryId
                                    )?.categoryName,
                                    options: [{
                                        value: item.id,
                                        label: item.name,
                                        selectionType: item.selectionType,
                                        chargeType: item.chargeType,
                                        price: item.additionalChargeByBranches?.filter((charge: any) => charge.branchId !== reservation?.branchId)[0]?.anountOrPercentage
                                    }]
                                }
                            })
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adjustmentIsOpen, isAddOnCategories]);

    useEffect(() => {
        if (selctedAdjusment > 0) {
            setPrice(adjustmentOptions.filter((item: any) => item?.options[0].value === selctedAdjusment)[0]?.options[0].price)
            setSelectionType(adjustmentOptions.filter((item: any) => item?.options[0].value === selctedAdjusment)[0]?.options[0].selectionType)
            setSelectedChargeType(adjustmentOptions.filter((item: any) => item?.options[0].value === selctedAdjusment)[0]?.options[0].chargeType)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selctedAdjusment])


    const updateReservation = async (amount: number) => {
        await axios
            .put(
                `${baseURL}reservation`,
                JSON.stringify({
                    ...reservation,
                    totalPrice: selectionType === "Only One" ? selectedChargeType === "Amount" ? reservation?.totalPrice + amount : reservation?.totalPrice + (reservation?.totalPrice * (amount / 100))
                        : selectedChargeType === "Amount" ? reservation?.totalPrice + (reservation?.totalDays * amount) : reservation?.totalPrice + (reservation?.totalDays * (reservation?.totalPrice * (amount / 100))),
                    outstandingBalance: selectionType === "Only One" ? selectedChargeType === "Amount" ? reservation?.outstandingBalance + amount : reservation?.outstandingBalance + (reservation?.totalPrice * (amount / 100)) :
                        selectedChargeType === "Amount" ? reservation?.outstandingBalance + (reservation?.totalDays * amount) : reservation?.outstandingBalance + (reservation?.totalDays * (reservation?.totalPrice * (amount / 100))),
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then((response) => {
                setPleaseWait(false);
                setComment("");
                setPrice("0");
                setAdjustmentIsOpen(false);
                setAdjustmentRefresh(true);
                setIsExtraValueIsAdded(true);
                setReservationUpdate(true);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleSubmit = async () => {
        setPleaseWait(true);

        await post_ReservationAdditionalCharge(
            JSON.stringify({
                reservatinId: reservation?.id,
                additionalChargeByBranchId: selctedAdjusment,
                quantity: 1,
                basePrice: parseInt(price),
            })
        )
            .then(data => {
                if (comment !== "") {
                    post_comment(
                        JSON.stringify({
                            attributeName: "ReservationAdjustment",
                            attributeId: data,
                            notes: comment,
                            userId: auth?.id,
                            createdDate:
                                format(new Date(), "yyyy-MM-dd") +
                                "T" +
                                format(new Date(), "KK:mm:ss"),
                        })).then(data => {
                            updateReservation(parseFloat(price))
                        })
                        .catch(error => console.error("Error:", error));

                }
                else {
                    setPleaseWait(false);
                    setAdjustmentIsOpen(false);
                    setAdjustmentRefresh(true);
                    setIsExtraValueIsAdded(true);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }


    return (
        <>
            <Dialog
                open={adjustmentIsOpen}
                PaperProps={{
                    style: {
                        marginTop: "-14%",
                        width: "580px",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Add new adjustment
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                            onClick={() => setAdjustmentIsOpen(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>
                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "16px",
                        }}>
                            <div style={{
                                width: "50%",

                            }}>
                                <label
                                    htmlFor="amount"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    Charge of Adjustment
                                    <br />
                                    <Select
                                        value={adjustmentOptions.find(option => option.value === selctedAdjusment)}
                                        options={adjustmentOptions}
                                        onChange={(selectedOption) => setSelectedAdjustment(selectedOption ? selectedOption.value : '')}
                                        isSearchable
                                        styles={{
                                            control: (styles) => ({
                                                ...styles,
                                                width: "100%",
                                            }),
                                            menuList: (provided: any) => ({
                                                ...provided,
                                                maxHeight: "160px",
                                                overflowY: "auto",
                                            }),
                                            menu: (styles) => ({
                                                ...styles,
                                                marginBottom: "40px",
                                                height: "auto",
                                            }),
                                            groupHeading: (provided: any) => ({
                                                ...provided,
                                                fontWeight: "bold",
                                                color: "black",
                                                fontSize: "12px",
                                            }),
                                            option: (provided: any, state: any) => ({
                                                ...provided,
                                                fontWeight: "300",
                                            }),
                                        }}
                                    />

                                </label>
                            </div>

                            <div style={{ width: "50%" }}>
                                <label
                                    htmlFor="amount"
                                    style={{ fontSize: "12px", fontWeight: "bold" }}
                                >
                                    {selectedChargeType} {" "} {selectionType === "Only One" ? " One Time" : "/ Day"}
                                    <br />
                                    <input
                                        type="text"
                                        name="price"
                                        style={{
                                            width: "100%",
                                            fontSize: "14px",
                                            paddingBlock: "5px",
                                            height: "37px"
                                        }}
                                        value={price}
                                        required
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </label>
                            </div>

                        </div>

                        <div style={{ width: "100%" }}>
                            <label
                                htmlFor="description"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Comments
                                <br />
                                <textarea
                                    name="comments"
                                    rows={8}
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    required
                                />
                            </label>
                        </div>



                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "50%" }} />

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => {
                                    setAdjustmentIsOpen(false);
                                }}
                            >
                                Cancel
                            </Button>


                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "#FFFFFF",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "#FFFFFF",
                                    },
                                }}
                                onClick={() => {
                                    handleSubmit();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <PleaseWaitPopup pleaseWaitPopup={pleaseWait} />
        </>
    )
}

export default AddAdjustmentModal;