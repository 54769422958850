import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { format } from "date-fns";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from "../../../configs/colorConfigs";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

type ViewProps = {
  vehicleOptions: any[];
  mechanicOption: any[];
  repaiOrderData: any;
  viewFilePath: any[];
};

export const ViewRepairOrder = ({
  vehicleOptions,
  mechanicOption,
  repaiOrderData,
  viewFilePath
}: ViewProps) => {
  const [maintenanceType, setMaintenanceType] = useState<string>("");
  const [vehicle, setVehicle] = useState<string>("");
  const [totalInParts, setTotalInParts] = useState<string>("");
  const [totalInLabor, setTotalInLabor] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [dateOut, setDateOut] = useState<string>("");
  const [dateDue, setDateDue] = useState<string>("");
  const [mechanic, setMechanic] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [repairVehicleFilePath, setRepairVehicleFilePath] = useState<any[]>([]);
  const [repairVehiclefileIndex, setRepairVehiclefileIndex] = useState<number>(-1);
  const [imagePopup, setImagePopup] = useState<boolean>(false);


  useEffect(() => {
    if (vehicleOptions.length > 0 && mechanicOption.length > 0 && repaiOrderData !== null) {
      setVehicle(() => vehicleOptions.filter((vehicle: any) => vehicle.value === repaiOrderData?.vehicleId)[0]?.label)
      setMaintenanceType(repaiOrderData?.maintenanceType);
      setTotalInParts(repaiOrderData?.totalInParts);
      setTotalInLabor(repaiOrderData?.totalInLabour);
      setStatus(repaiOrderData?.status);
      setNotes(DOMPurify.sanitize(repaiOrderData?.notes, {
        USE_PROFILES: { html: true },
      }));
      setDateOut(format(new Date(repaiOrderData?.dateOut), "dd-MM-yyyy hh:mm aaaaa'm'"));
      setDateDue(format(new Date(repaiOrderData?.dateDue), "dd-MM-yyyy hh:mm aaaaa'm'"));
      setMechanic(() => mechanicOption.filter((item: any) => item.value === parseInt(repaiOrderData?.mechanic))[0]?.label);
      setComments(repaiOrderData?.comments);
      setRepairVehicleFilePath(viewFilePath);
    }
  }, [vehicleOptions, mechanicOption, repaiOrderData, viewFilePath]);


  return (
    <>
      <div
        style={{
          width: "100%"
        }}
      >
        <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
          General Information
        </h3>
        <hr />
        <div style={{
          width: "100%"
        }}>
          <div
            style={{
              width: "100%",
              padding: "14px",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="vehicle"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Vehicle
                <br />
                <span style={{ fontWeight: "lighter" }}>{vehicle}</span>
              </label>
            </div>

            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="maintenanceType"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Maintenance Type
                <br />
                <span style={{ fontWeight: "lighter" }}>{maintenanceType}</span>
              </label>
            </div>

          </div>

          <div
            style={{
              width: "100%",
              padding: "14px",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="maintenanceType"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Total In Parts
                <br />
                <span style={{ fontWeight: "lighter" }}>{totalInParts}</span>
              </label>
            </div>


            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="maintenanceType"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Total In Labor
                <br />
                <span style={{ fontWeight: "lighter" }}>{totalInLabor}</span>
              </label>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              padding: "14px",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="maintenanceType"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Status
                <br />
                <span style={{ fontWeight: "lighter" }}>{status}</span>
              </label>
            </div>

            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="maintenanceType"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Notes
                <br />
                <span style={{ fontWeight: "lighter" }} dangerouslySetInnerHTML={{ __html: notes }} />
              </label>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              padding: "0 14px"
            }}
          >
            <label
              htmlFor="maintenanceType"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Images
              <br />
              <div
                style={{
                  maxWidth: "50%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "5px",
                  flexWrap: "wrap"
                }}
              >
                {repairVehicleFilePath?.map((item: any, outerIndex: number) => {
                  return (
                    <React.Fragment key={outerIndex}>
                      <img
                        src={item.fileName}
                        width={40}
                        height={40}
                        alt="repairOrder"
                        onClick={() => {
                          setRepairVehiclefileIndex(outerIndex);
                          setImagePopup(true);
                        }}
                      />

                    </React.Fragment>
                  );
                })}
              </div>
            </label>
          </div>
        </div>


        <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
          Vehicle Out Information
        </h3>
        <hr />

        <div style={{
          width: "100%"
        }}>
          <div
            style={{
              width: "100%",
              padding: "14px",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="vehicle"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Date Out
                <br />
                <span style={{ fontWeight: "lighter" }}>{dateOut}</span>
              </label>
            </div>

            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="maintenanceType"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Date Due
                <br />
                <span style={{ fontWeight: "lighter" }}>{dateDue}</span>
              </label>
            </div>

          </div>
        </div>


        <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
          Internal Management
        </h3>
        <hr />

        <div style={{
          width: "100%"
        }}>
          <div
            style={{
              width: "100%",
              padding: "14px",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="vehicle"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Mechanic
                <br />
                <span style={{ fontWeight: "lighter" }}>{mechanic}</span>
              </label>
            </div>

            <div
              style={{
                width: "50%"
              }}
            >
              <label
                htmlFor="maintenanceType"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Comments
                <br />
                <span style={{ fontWeight: "lighter" }}>{comments}</span>
              </label>
            </div>

          </div>
        </div>

      </div>
      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              Images
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                padding: "10px",
                left: "0",
                top: "50%",
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  cursor: repairVehiclefileIndex !== 0 ? "pointer" : "",
                  opacity: repairVehiclefileIndex === 0 ? "0.5" : "1",
                  color: "gray",
                }}
                onClick={() => {
                  if (repairVehiclefileIndex !== 0) {
                    setRepairVehiclefileIndex(repairVehiclefileIndex - 1);
                  }
                }}
              />
            </div>
            <img
              width={700}
              height={350}
              src={repairVehicleFilePath[repairVehiclefileIndex]?.fileName}
              alt="repairOrder"
            />

            <div
              style={{
                backgroundColor: "transparent",
                position: "absolute",
                padding: "10px",
                right: "0",
                top: "50%",
                color: "gray",
              }}
            >
              <ArrowForwardIosIcon
                sx={{
                  cursor: repairVehiclefileIndex !== repairVehicleFilePath.length - 1 ? "pointer" : "",
                  opacity: repairVehiclefileIndex === repairVehicleFilePath.length - 1 ? "0.5" : "1",
                }}
                onClick={() => {
                  if (repairVehiclefileIndex !== repairVehicleFilePath.length - 1) {
                    setRepairVehiclefileIndex(repairVehiclefileIndex + 1);
                  }
                }}
              />
            </div>
          </div>

        </DialogContent>
      </Dialog>
    </>

  );
};
