import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../../configs/api";
import { validateMin } from "../../../../utils/validateMin";

type Props = {
    customerId: string;
    addCreditCardPopup: boolean;
    setAddCreditCardPopup: () => void;
}

export default function AddCreditCard({ customerId, addCreditCardPopup, setAddCreditCardPopup }: Props) {
    const [paymentGateway, setPaymentGateway] = useState<string>("");
    const [brand, setBrand] = useState<string>("");
    const [paymentCheck, setPaymentCheck] = useState<boolean>(true);
    const [creditCard, setCreditCard] = useState<string>("");
    const [cardHolderName, setCardHolderName] = useState<string>("");
    const [expirationDate, setExpirationDate] = useState<string>("");
    const [ccvCode, setCcvCode] = useState<string>("");

    const resetForm = () => {
        setPaymentGateway("");
        setBrand("");
        setPaymentCheck(true);
        setCreditCard("");
        setCardHolderName("");
        setExpirationDate("");
        setCcvCode("");
    };

    useEffect(() => {
        if (addCreditCardPopup) {
            resetForm();
        }
    }, [addCreditCardPopup]);

    const handleCreditCardDetails = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios
            .post(
                `${baseURL}creditCard`,
                JSON.stringify({
                    creditCardNumber: parseInt(creditCard),
                    expirationDate: expirationDate,
                    cardholderName: cardHolderName,
                    paymentGateway: paymentGateway,
                    brand: brand,
                    ccvCode: ccvCode,
                    cardZipCode: 0,
                    customerId: parseInt(customerId),
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then((response) => {
                setAddCreditCardPopup();

            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <Dialog
            open={addCreditCardPopup}
            maxWidth="md"
            fullScreen={false}
            PaperProps={{
                style: {
                    width: "40%",
                    display: "flex",
                    position: "absolute",
                    top: "30px",
                },
            }}
        >
            <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                <div
                    style={{
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px 16px 8px 16px",
                    }}
                >
                    <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                        Add Credit Card
                    </p>
                    <CloseIcon
                        sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
                        onClick={() => {
                            setAddCreditCardPopup();
                        }}
                    />
                </div>
                <hr style={{ marginBlock: "0px" }} />
            </DialogTitle>
            <DialogContent sx={{ padding: "0px" }}>
                <form onSubmit={(e) => handleCreditCardDetails(e)}>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: "16px",
                                paddingRight: "16px",
                            }}
                        >
                            <label htmlFor="payment" style={{ fontSize: "12px" }}>
                                <input
                                    type="checkbox"
                                    value="Yes"
                                    name="payment"
                                    style={{ fontSize: "12px", textAlign: "center" }}
                                    defaultChecked={paymentCheck}
                                    onChange={(e) =>
                                        setPaymentCheck((paymentCheck) => !paymentCheck)
                                    }
                                />{" "}
                                <i
                                    style={{ fontSize: "26px", color: "blue" }}
                                    className="bx bxl-visa"
                                ></i>
                                <i
                                    style={{ fontSize: "26px", marginLeft: "5px" }}
                                    className="bx bxl-mastercard"
                                ></i>
                            </label>
                        </div>

                        {paymentCheck && (
                            <>
                                <div
                                    style={{
                                        marginLeft: "16px",
                                        marginRight: "16px",
                                        marginBlock: "16px",
                                        paddingBottom: "16px",
                                        border: "1px solid gray",
                                    }}
                                >
                                    <p
                                        style={{
                                            backgroundColor: "#eeeeee",
                                            padding: "8px 5px",
                                            marginBlock: "0px",
                                        }}
                                    >
                                        Payment Details - <strong>USD</strong>
                                    </p>
                                    <hr style={{ marginTop: "0px" }} />

                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "16px",
                                            paddingLeft: "16px",
                                            paddingRight: "16px",
                                        }}
                                    >
                                        <div style={{ width: "75%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Credit Card Number
                                                <br />
                                                <input
                                                    type="number"
                                                    min={0}
                                                    name="amount"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                    }}
                                                    value={creditCard}
                                                    onChange={(e) => setCreditCard(validateMin(e.target.value, 0).toString())}
                                                    required
                                                />
                                            </label>

                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Cardholder Name
                                                <br />
                                                <input
                                                    type="text"
                                                    name="amount"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                    }}
                                                    value={cardHolderName}
                                                    onChange={(e) => setCardHolderName(e.target.value)}
                                                    required
                                                />
                                            </label>
                                        </div>

                                        <div style={{ width: "25%" }}>
                                            <label
                                                htmlFor="description"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Expiration Date
                                                <br />
                                                <input
                                                    type="text"
                                                    placeholder="MM/YY"
                                                    name="description"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                    }}
                                                    value={expirationDate}
                                                    onChange={(e) => setExpirationDate(e.target.value)}
                                                    required
                                                />
                                            </label>

                                            <label
                                                htmlFor="description"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                CCV Code
                                                <br />
                                                <input
                                                    type="text"
                                                    placeholder="CV"
                                                    name="description"
                                                    style={{
                                                        width: "100%",
                                                        fontSize: "14px",
                                                        paddingBlock: "5px",
                                                    }}
                                                    value={ccvCode}
                                                    onChange={(e) => setCcvCode(e.target.value)}
                                                    required
                                                />
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "8px 16px 16px 16px",
                                        color: "white",
                                        backgroundColor: "#66BB6A",
                                        padding: "5px 8px",
                                        cursor: "pointer",
                                        fontSize: "12px",
                                    }}
                                >
                                    <button
                                        style={{
                                            width: "100%",
                                            color: "white",
                                            backgroundColor: "#66BB6A",
                                            border: "none",
                                            cursor: "pointer"
                                        }}
                                        type="submit"
                                    >
                                        Save Credit Card
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </form>

                <div
                    style={{
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBlock: "8px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                        backgroundColor: "#eeeeee",
                    }}
                >
                    <div style={{ width: "50%" }} />

                    <div
                        style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#e0dee0",
                                color: colorConfigs.sidebar.color,
                                borderRadius: "0",
                                border: "none",
                                textTransform: "capitalize",
                                fontSize: "12px",
                                "&: hover": {
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                },
                            }}
                            onClick={() => {
                                setAddCreditCardPopup();
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
