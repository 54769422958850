import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import colorConfigs from "../../configs/colorConfigs";
import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import AddIcon from '@mui/icons-material/Add';
import PleaseWaitPopup from "../../pages/PleaseWaitPopup";
import { getReservation } from "../../services/reservation";
import { getVehicleReplaceId, getVehicleReplacementByReservationId } from "../../services/VehicleReplcae";
import axios from "axios";
import baseURL from "../../configs/api";
import { format } from "date-fns";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import axois from "../../configs/axois";
import { getCommentsOfAttribute, post_comment, put_comment } from "../../services/comment";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Webcam from "react-webcam";

type EditReplaceVehicleModalProps = {
    editReplaceVehicleIsOpen: boolean;
    setEditReplaceVehicleIsOpen: (value: boolean) => void;
    setVehicleReplaceRefresh: (value: boolean) => void;
    setReservationUpdate: (value: boolean) => void;
    reservation: any;
    replacementModalData: any;
}

const EditReplaceVehicleModal = ({ editReplaceVehicleIsOpen, setEditReplaceVehicleIsOpen, setVehicleReplaceRefresh, setReservationUpdate, reservation, replacementModalData }: EditReplaceVehicleModalProps) => {
    const navigate = useNavigate();
    const { auth }: any = useAuth();
    const [isPickupInformationOpen, setIsPickupInformationOpen] = useState<boolean>(true);
    const [isReturnInformationOpen, setIsReturnInformationOpen] = useState<boolean>(true);
    const [isNewVehicleInformationOpen, setIsNewVehicleInformationOpen] = useState<boolean>(true);
    const [replacedVehicleLink, setReplacedVehicleLink] = useState<string>("");
    const [pickupLocation, setPickupLocation] = useState<string>("");
    const [fuelLevelAtPickup, setFuelLevelAtPickup] = useState<string>("");
    const [odometerAtPickup, setOdometerAtPickup] = useState<string>("");
    const [pickupDate, setIsPickupDate] = useState<string>("");
    const [pickupTime, setPickupTime] = useState<string>("");
    const [returnLocation, setReturnLocation] = useState<string>("");
    const [fuelLevelAtReturn, setFuelLevelAtReturn] = useState<string>("");
    const [ChargeFuelToClient, setChargeFuelToClient] = useState<string>("");
    const [odometerAtReturn, setOdometerAtReturn] = useState<string>("");
    const [returnDate, setReturnDate] = useState<string>("");
    const [returnTime, setReturnTime] = useState<string>("");
    const [replacementVehicle, setReplacementVehicle] = useState<string>("");
    const [freeChange, setFreeChange] = useState<string>("");
    const [currentOdometer, setCurrentOdometer] = useState<string>("");
    const [newVehicleFuelLevel, setNewVehicelFuelLevel] = useState<string>("");
    const [replacementComment, setReplacementComment] = useState<string>("");
    const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
    const [locations, setLocations] = useState<any[]>([]);
    const [isLocationsIsLoaded, setIsLocationIsLoaded] = useState<boolean>(false);
    const returnInputFileRef = useRef<HTMLInputElement>(null);
    const newInputFileRef = useRef<HTMLInputElement>(null);
    const [pickupVehicleFilePath, setPickupVehicleFilePath] = useState<any[]>([]);
    const [pickupVehiclefileIndex, setPickupVehiclefileIndex] = useState<number>(-1);
    const [imagePopup, setImagePopup] = useState<boolean>(false);
    const [returnVehicleFilePath, setReturnVehicleFilePath] = useState<any[]>([]);
    const [returnVehiclefileIndex, setReturnVehiclefileIndex] = useState<number>(-1);
    const [returnVehicleNewFilePath, setReturnVehicleNewFilePath] = useState<any[]>([]);
    const [NewVehicleNewFilePath, setNewVehicleNewFilePath] = useState<any[]>([]);
    const [newVehicleFilePath, setNewVehicleFilePath] = useState<any[]>([]);
    const [newVehicleFileIndex, setNewVehicleFileIndex] = useState<number>(-1);
    const [currentPreview, setCurrrentPreview] = useState<string>("");
    const webRef = useRef(null);
    const [currentWebCam, setCurrrentWebCam] = useState<string>("");
    const [showWebcam, setShowWebcam] = useState<boolean>(false);
    const [replaceVehicleData, setReplaceVehicleData] = useState<any[]>([]);
    const [vehicleId, setVehicleId] = useState<number>(0);

    useEffect(() => {
        if (editReplaceVehicleIsOpen && reservation) {
            getVehicleReplacementByReservationId(reservation?.id)
                .then((res) => {
                    setReplaceVehicleData(res)
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [reservation, editReplaceVehicleIsOpen])

    useEffect(() => {
        if (editReplaceVehicleIsOpen) {
            axios
                .get(`${baseURL}location`)
                .then((response) => {
                    setLocations(() =>
                        response.data
                            .filter(
                                (data: any) => data.branchId === parseInt(reservation?.branchId)
                            )
                            .map((item: any) => {
                                return {
                                    id: item.id,
                                    name: item.locationName,
                                };
                            })
                    );

                    setIsLocationIsLoaded(true);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editReplaceVehicleIsOpen]);

    useEffect(() => {
        if (editReplaceVehicleIsOpen && parseInt(replacementModalData?.id) > 0 && isLocationsIsLoaded && replaceVehicleData.length === 1) {
            getReservation(reservation?.id)
                .then((res) => {
                    getVehicleReplaceId(replacementModalData?.id)
                        .then((data: any) => {
                            setVehicleId(res?.vehicleId);
                            setReplacedVehicleLink(replacementModalData?.replacedVehicle)
                            setPickupLocation(() => locations.filter((item: any) => item.id === res?.pickupLoationId)[0]?.name)
                            setFuelLevelAtPickup(res?.fuelLevelAtCheckOut)
                            setOdometerAtPickup(res?.odometerAtCheckOut)
                            setIsPickupDate(format(new Date(res?.pickupDate?.split("T")[0]), "dd-MM-yyyy"))
                            setPickupTime(res?.pickupDate?.split("T")[1])
                            setReturnLocation(() => locations.filter((item: any) => item.id === res?.returenLoationId)[0]?.name)
                            setFuelLevelAtReturn(res?.fuelLevelAtCheckIn)
                            setChargeFuelToClient(data?.chargeFuleToClient === true ? "Yes" : "No");
                            setOdometerAtReturn(res?.odometerAtCheckIn);
                            setReturnDate(format(new Date(res?.dateTimeCheckIn?.split("T")[0]), "dd-MM-yyyy"))
                            setReturnTime(res?.dateTimeCheckIn?.split("T")[1])
                            setReplacementVehicle(replacementModalData?.replacementVehicle)
                            setFreeChange(data?.isFreeChange === true ? "Yes" : "No")
                            setCurrentOdometer(data?.odometerAtCheckOut);
                            setNewVehicelFuelLevel(data?.fuelLevelAtCheckOut);
                            setReplacementComment(replacementModalData?.replacementComments)
                        })
                        .catch((error) => console.error(error))
                })
                .catch((error) => console.error(error))

        }
        else if (editReplaceVehicleIsOpen && parseInt(replacementModalData?.id) > 0 && isLocationsIsLoaded && replaceVehicleData.length > 1) {
            const replacedVehicles = replaceVehicleData.filter((vehicle: any) => vehicle?.isVehicleReplaced === true);
            const lastReplacedVehicle = replacedVehicles[replacedVehicles.length - 1];

            getVehicleReplaceId(replacementModalData?.id)
                .then((data: any) => {
                    setVehicleId(lastReplacedVehicle?.vehicleId);
                    setReplacedVehicleLink(replacementModalData?.replacedVehicle)
                    setPickupLocation(() => locations.filter((item: any) => item.id === lastReplacedVehicle?.pickupLoationId)[0]?.name)
                    setFuelLevelAtPickup(lastReplacedVehicle?.fuelLevelAtCheckOut)
                    setOdometerAtPickup(lastReplacedVehicle?.odometerAtCheckOut)
                    setIsPickupDate(format(new Date(lastReplacedVehicle?.pickupDate?.split("T")[0]), "dd-MM-yyyy"))
                    setPickupTime(lastReplacedVehicle?.pickupDate?.split("T")[1])
                    setReturnLocation(() => locations.filter((item: any) => item.id === lastReplacedVehicle?.returenLoationId)[0]?.name)
                    setFuelLevelAtReturn(lastReplacedVehicle?.fuelLevelAtCheckIn)
                    setChargeFuelToClient(data?.chargeFuleToClient === true ? "Yes" : "No");
                    setOdometerAtReturn(lastReplacedVehicle?.odometerAtCheckIn);
                    setReturnDate(format(new Date(lastReplacedVehicle?.dateTimeCheckIn?.split("T")[0]), "dd-MM-yyyy"))
                    setReturnTime(lastReplacedVehicle?.dateTimeCheckIn?.split("T")[1])
                    setReplacementVehicle(replacementModalData?.replacementVehicle)
                    setFreeChange(data?.isFreeChange === true ? "Yes" : "No")
                    setCurrentOdometer(data?.odometerAtCheckOut);
                    setNewVehicelFuelLevel(data?.fuelLevelAtCheckOut);
                    setReplacementComment(replacementModalData?.replacementComments)
                })
                .catch((error) => console.error(error))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservation, editReplaceVehicleIsOpen, replacementModalData, isLocationsIsLoaded, replaceVehicleData])


    useEffect(() => {
        if (reservation?.id > 0 && editReplaceVehicleIsOpen) {
            axois
                .get(`${baseURL}fileUploadData/Reservation/${reservation.id}`)
                .then((response) => {
                    setPickupVehicleFilePath(() =>
                        response.data
                            .filter((data: any) => data.tabName === "Checkout")
                            .map((item: any) => {
                                return {
                                    id: item.id,
                                    fileName: item.fileName.startsWith("data:image/png;base64,")
                                        ? item.fileName
                                        : `${baseURL}reservationFile/Get?name=` +
                                        item.fileName,
                                };
                            })
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [reservation, editReplaceVehicleIsOpen]);

    useEffect(() => {
        if (reservation?.id > 0 && editReplaceVehicleIsOpen) {
            axois
                .get(`${baseURL}fileUploadData/Reservation/${reservation.id}`)
                .then((response) => {
                    setReturnVehicleFilePath(() =>
                        response.data
                            .filter((data: any) => data.tabName === "Checkin")
                            .map((item: any) => {
                                return {
                                    id: item.id,
                                    fileName: item.fileName.startsWith("data:image/png;base64,")
                                        ? item.fileName
                                        : `${baseURL}reservationFile/Get?name=` +
                                        item.fileName,
                                };
                            })
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [reservation, editReplaceVehicleIsOpen]);

    useEffect(() => {
        if (reservation?.id > 0 && editReplaceVehicleIsOpen) {
            axois
                .get(`${baseURL}fileUploadData/Checkout-VehicleReplacement/${replacementModalData.id}`)
                .then((response) => {
                    setNewVehicleFilePath(() =>
                        response.data
                            .map((item: any) => {
                                return {
                                    id: item.id,
                                    fileName: item.fileName.startsWith("data:image/png;base64,")
                                        ? item.fileName
                                        : `${baseURL}reservationFile/Get?name=` +
                                        item.fileName,
                                };
                            })
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservation, editReplaceVehicleIsOpen]);

    const onReturnVehicleFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPleaseWaitPopup(true);
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            const formData = new FormData();
            formData.append("ImageFile", selectedFiles[0]);

            axios
                .post(`${baseURL}reservationFile/upload`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                })
                .then((response) => {
                    const newFilePath: any = {
                        id: 0,
                        fileName:
                            `${baseURL}reservationFile/Get?name=` +
                            response.data,
                    };

                    setReturnVehicleNewFilePath((prevPath: string[]) => [
                        ...prevPath,
                        newFilePath.fileName,
                    ]);
                    setReturnVehicleFilePath((prevPath: any[]) => [...prevPath, newFilePath]);
                    setPleaseWaitPopup(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            setPleaseWaitPopup(false);
        }
    };

    const onNewVehicleFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPleaseWaitPopup(true);
        const selectedFiles = e.target.files as FileList;

        if (selectedFiles.length !== 0) {
            const formData = new FormData();
            formData.append("ImageFile", selectedFiles[0]);

            axios
                .post(`${baseURL}reservationFile/upload`, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    withCredentials: true,
                })
                .then((response) => {
                    const newFilePath: any = {
                        id: 0,
                        fileName:
                            `${baseURL}reservationFile/Get?name=` +
                            response.data,
                    };

                    setNewVehicleNewFilePath((prevPath: string[]) => [
                        ...prevPath,
                        newFilePath.fileName,
                    ]);
                    setNewVehicleFilePath((prevPath: any[]) => [...prevPath, newFilePath]);
                    setPleaseWaitPopup(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            setPleaseWaitPopup(false);
        }
    };

    const handleSubmit = () => {
        setPleaseWaitPopup(true);
        if (returnVehicleNewFilePath.length !== 0) {
            returnVehicleNewFilePath.forEach((item: string, index: number) => {
                axios
                    .post(
                        `${baseURL}fileUploadData`,
                        JSON.stringify({
                            attributeName: "Reservation",
                            attributeId: reservation?.id,
                            fileName: item?.replace(
                                `${baseURL}reservationFile/Get?name=`,
                                ""
                            ),
                            tabName: "Checkin",
                            userId: auth?.id ?? 0,
                            createdDate: new Date(),
                        }),
                        {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                        }
                    )
                    .then((response) => {
                        if (returnVehicleNewFilePath.length - 1 === index) {
                            updateNewVehicleReplace()
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            });
        }
        else {
            updateNewVehicleReplace()
        }
    }

    const updateNewVehicleReplace = () => {
        if (NewVehicleNewFilePath.length !== 0) {
            getCommentsOfAttribute('VehicleReplacement', replacementModalData?.id)
                .then((res) => {
                    if (res.length !== 0) {
                        put_comment(
                            JSON.stringify({
                                attributeName: "VehicleReplacement",
                                attributeId: replacementModalData?.id,
                                notes: replacementComment,
                                id: res[0]?.id,
                                userId: auth?.id,
                                createdDate:
                                    format(new Date(), "yyyy-MM-dd") +
                                    "T" +
                                    format(new Date(), "KK:mm:ss"),
                            })).then(data => {
                                NewVehicleNewFilePath.forEach((item: string, index: number) => {
                                    axios
                                        .post(
                                            `${baseURL}fileUploadData`,
                                            JSON.stringify({
                                                attributeName: "Checkout-VehicleReplacement",
                                                attributeId: replacementModalData?.id,
                                                fileName: item?.replace(
                                                    `${baseURL}reservationFile/Get?name=`,
                                                    ""
                                                ),
                                                tabName: "VehicleReplacement",
                                                userId: auth?.id ?? 0,
                                                createdDate: new Date(),
                                            }),
                                            {
                                                headers: { "Content-Type": "application/json" },
                                                withCredentials: true,
                                            }
                                        )
                                        .then((response) => {
                                            if (newVehicleFilePath.length - 1 === index) {
                                                setPleaseWaitPopup(false);
                                                setEditReplaceVehicleIsOpen(false);
                                                setVehicleReplaceRefresh(true);
                                                setReservationUpdate(true);
                                                setReturnVehicleFilePath([]);
                                                setNewVehicleFilePath([]);
                                            }
                                        })
                                        .catch((error) => {
                                            console.error("Error:", error);
                                        });
                                });

                            })
                            .catch(error => console.error("Error:", error));
                    }
                    else {
                        post_comment(
                            JSON.stringify({
                                attributeName: "VehicleReplacement",
                                attributeId: replacementModalData?.id,
                                notes: replacementComment,
                                userId: auth?.id,
                                createdDate:
                                    format(new Date(), "yyyy-MM-dd") +
                                    "T" +
                                    format(new Date(), "KK:mm:ss"),
                            })).then(data => {
                                NewVehicleNewFilePath.forEach((item: string, index: number) => {
                                    axios
                                        .post(
                                            `${baseURL}fileUploadData`,
                                            JSON.stringify({
                                                attributeName: "Checkout-VehicleReplacement",
                                                attributeId: replacementModalData?.id,
                                                fileName: item?.replace(
                                                    `${baseURL}reservationFile/Get?name=`,
                                                    ""
                                                ),
                                                tabName: "VehicleReplacement",
                                                userId: auth?.id ?? 0,
                                                createdDate: new Date(),
                                            }),
                                            {
                                                headers: { "Content-Type": "application/json" },
                                                withCredentials: true,
                                            }
                                        )
                                        .then((response) => {
                                            if (newVehicleFilePath.length - 1 === index) {
                                                setPleaseWaitPopup(false);
                                                setEditReplaceVehicleIsOpen(false);
                                                setVehicleReplaceRefresh(true);
                                                setReturnVehicleFilePath([]);
                                                setNewVehicleFilePath([]);
                                                setReservationUpdate(true);
                                            }
                                        })
                                        .catch((error) => {
                                            console.error("Error:", error);
                                        });
                                });

                            })
                            .catch(error => console.error("Error:", error));
                    }
                }
                )
                .catch(error => console.error("Error:", error));
        }
        else {
            getCommentsOfAttribute('VehicleReplacement', replacementModalData?.id)
                .then((res) => {
                    if (res.length !== 0) {

                        put_comment(
                            JSON.stringify({
                                attributeName: "VehicleReplacement",
                                attributeId: replacementModalData?.id,
                                notes: replacementComment,
                                id: res[0]?.id,
                                userId: auth?.id,
                                createdDate:
                                    format(new Date(), "yyyy-MM-dd") +
                                    "T" +
                                    format(new Date(), "KK:mm:ss"),
                            })).then(data => {

                                setPleaseWaitPopup(false);
                                setEditReplaceVehicleIsOpen(false);
                                setVehicleReplaceRefresh(true);
                                setReservationUpdate(true);

                            })
                            .catch(error => console.error("Error:", error));
                    }
                    else {
                        post_comment(
                            JSON.stringify({
                                attributeName: "VehicleReplacement",
                                attributeId: replacementModalData?.id,
                                notes: replacementComment,
                                userId: auth?.id,
                                createdDate:
                                    format(new Date(), "yyyy-MM-dd") +
                                    "T" +
                                    format(new Date(), "KK:mm:ss"),
                            })).then(data => {

                                setPleaseWaitPopup(false);
                                setEditReplaceVehicleIsOpen(false);
                                setVehicleReplaceRefresh(true);
                                setReservationUpdate(true);

                            })
                            .catch(error => console.error("Error:", error));
                    }
                }
                )
                .catch(error => console.error("Error:", error));
        }
    }

    return (
        <>
            <Dialog
                open={editReplaceVehicleIsOpen}

                PaperProps={{
                    style: {
                        maxWidth: "800px",
                        width: "100%"
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Edit Vehicle Replacement
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                            onClick={() => setEditReplaceVehicleIsOpen(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>

                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >


                        <h5
                            style={{
                                paddingBottom: "0px",
                                marginBottom: "0px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                WebkitUserSelect: "none" /* Safari */,
                                msUserSelect: "none" /* IE 10 and IE 11 */,
                                userSelect: "none" /* Standard syntax */,
                            }}
                            onClick={() => setIsPickupInformationOpen(!isPickupInformationOpen)}
                        >
                            {isPickupInformationOpen ? (
                                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                            ) : (
                                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                            )}{" "}
                            Pickup Information
                        </h5>
                        <hr />
                        {
                            isPickupInformationOpen && (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Vehicle Link
                                                <br />
                                                <span
                                                    style={{
                                                        fontWeight: "lighter",
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        textDecoration: "underline",
                                                    }}
                                                    onClick={() =>
                                                        navigate("/fleet/vehicle/editvehicles", {
                                                            state: vehicleId,
                                                        })
                                                    }
                                                >
                                                    {replacedVehicleLink}
                                                </span>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Pickup Location
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{pickupLocation}</span>
                                            </label>
                                        </div>

                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Fuel level at Pickup
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{`${fuelLevelAtPickup} / 8`}</span>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Odometer at Pickup
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{odometerAtPickup}</span>
                                            </label>
                                        </div>

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Pickup Date
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{pickupDate}</span>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Pickup Time
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{pickupTime}</span>
                                            </label>
                                        </div>

                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Images
                                                <br />
                                                <div
                                                    style={{
                                                        maxWidth: "50%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        flexWrap: "wrap"
                                                    }}
                                                >
                                                    {pickupVehicleFilePath.map((item: any, outerIndex: number) => {
                                                        return (
                                                            <React.Fragment key={outerIndex}>
                                                                <img
                                                                    src={item.fileName}
                                                                    width={40}
                                                                    height={40}
                                                                    alt="repairOrder"
                                                                    onClick={() => {
                                                                        setPickupVehiclefileIndex(outerIndex);
                                                                        setImagePopup(true);
                                                                        setCurrrentPreview('pickup-vehicle')
                                                                    }}
                                                                />

                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>

                                            </label>
                                        </div>

                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >


                        <h5
                            style={{
                                paddingBottom: "0px",
                                marginBottom: "0px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                WebkitUserSelect: "none" /* Safari */,
                                msUserSelect: "none" /* IE 10 and IE 11 */,
                                userSelect: "none" /* Standard syntax */,
                            }}
                            onClick={() => setIsReturnInformationOpen(!isReturnInformationOpen)}
                        >
                            {isReturnInformationOpen ? (
                                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                            ) : (
                                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                            )}{" "}
                            Return Information
                        </h5>
                        <hr />
                        {
                            isReturnInformationOpen && (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Vehicle Link
                                                <br />
                                                <span
                                                    style={{
                                                        fontWeight: "lighter",
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        textDecoration: "underline",
                                                    }}
                                                    onClick={() =>
                                                        navigate("/fleet/vehicle/editvehicles", {
                                                            state: vehicleId,
                                                        })
                                                    }
                                                >
                                                    {replacedVehicleLink}
                                                </span>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Return Location
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{returnLocation}</span>
                                            </label>
                                        </div>

                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "16px",
                                            }}>
                                                <div style={{
                                                    width: "50%",

                                                }}>
                                                    <label
                                                        htmlFor="amount"
                                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                                    >
                                                        Fuel level at Return
                                                        <br />
                                                        <span style={{ fontWeight: "lighter" }}>{fuelLevelAtReturn}</span>
                                                    </label>
                                                </div>

                                                <div style={{ width: "50%" }}>
                                                    <label
                                                        htmlFor="chargeFule"
                                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                                    >
                                                        Charge fule to client?
                                                        <br />
                                                        <span style={{ fontWeight: "lighter" }}>{ChargeFuelToClient}</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Odometer at Return
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{odometerAtReturn}</span>
                                            </label>
                                        </div>

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Return Date
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{returnDate}</span>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Return Time
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{returnTime}</span>
                                            </label>
                                        </div>

                                    </div>


                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Images
                                                <br />
                                                <div
                                                    style={{
                                                        maxWidth: "50%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        flexWrap: "wrap"
                                                    }}
                                                >
                                                    {returnVehicleFilePath.map((item: any, outerIndex: number) => {
                                                        return (
                                                            <React.Fragment key={outerIndex}>
                                                                <img
                                                                    src={item.fileName}
                                                                    width={40}
                                                                    height={40}
                                                                    alt="repairOrder"
                                                                    onClick={() => {
                                                                        setReturnVehiclefileIndex(outerIndex);
                                                                        setImagePopup(true);
                                                                        setCurrrentPreview('return-vehicle')
                                                                    }}
                                                                />
                                                                <RemoveCircleIcon
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        color: colorConfigs.topbar.bg,
                                                                        fontWeight: "bold",
                                                                        "&: hover": {
                                                                            color: "red",
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            window.confirm(
                                                                                "Are you sure you want to delete this image?"
                                                                            )
                                                                        ) {
                                                                            let newFileSet: any[] = returnVehicleFilePath.filter(
                                                                                (_, index: number) => index !== outerIndex
                                                                            );

                                                                            if (returnVehicleFilePath[outerIndex].id === 0) {
                                                                                setReturnVehicleFilePath(() => newFileSet);
                                                                            } else {
                                                                                axios
                                                                                    .delete(
                                                                                        `${baseURL}fileUploadData?id=${returnVehicleFilePath[outerIndex].id}`
                                                                                    )
                                                                                    .then((response) => {
                                                                                        setReturnVehicleFilePath(() => newFileSet);
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        console.error("Error:", error);
                                                                                    });
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                                <input
                                                    type="file"
                                                    name="inputFileRef"
                                                    accept="image/*"
                                                    ref={returnInputFileRef}
                                                    onChangeCapture={onReturnVehicleFileChangeCapture}
                                                    style={{ display: "none" }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                        }}
                                                        onClick={(e) => {
                                                            if (returnInputFileRef.current !== null) {
                                                                returnInputFileRef.current.click();
                                                            }
                                                        }}
                                                    >
                                                        {" "}
                                                        <AddIcon
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "lighter",
                                                            }}
                                                        />
                                                        Add Image
                                                    </p>

                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                        }}
                                                        onClick={() => {
                                                            setShowWebcam(true)
                                                            setCurrrentWebCam('return-vehicle')
                                                        }}
                                                    >
                                                        {" "}
                                                        <LocalSeeIcon
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "bold",
                                                            }}
                                                        />
                                                    </p>
                                                </div>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>

                                        </div>

                                    </div>
                                </div>
                            )
                        }

                    </div>

                    <div
                        style={{
                            width: "100%",
                            marginTop: "10px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                        }}
                    >


                        <h5
                            style={{
                                paddingBottom: "0px",
                                marginBottom: "0px",
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                WebkitUserSelect: "none" /* Safari */,
                                msUserSelect: "none" /* IE 10 and IE 11 */,
                                userSelect: "none" /* Standard syntax */,
                            }}
                            onClick={() => setIsNewVehicleInformationOpen(!isNewVehicleInformationOpen)}
                        >
                            {isNewVehicleInformationOpen ? (
                                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                            ) : (
                                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
                            )}{" "}
                            Vehicle Replacement
                        </h5>
                        <hr />
                        {
                            isNewVehicleInformationOpen && (
                                <div
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Replacement Vehicle
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{replacementVehicle}</span>
                                            </label>





                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="freeChange"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Free Change?
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{freeChange}</span>
                                            </label>
                                        </div>
                                    </div>




                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Current Odomemeter
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{currentOdometer}</span>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Fuel Level
                                                <br />
                                                <span style={{ fontWeight: "lighter" }}>{`${newVehicleFuelLevel} / 8`}</span>
                                            </label>
                                        </div>

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px",
                                    }}>
                                        <div style={{
                                            width: "50%",

                                        }}>
                                            <label
                                                htmlFor="amount"
                                                style={{ fontSize: "12px", fontWeight: "bold" }}
                                            >
                                                Images
                                                <br />
                                                <div
                                                    style={{
                                                        maxWidth: "50%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                        flexWrap: "wrap"
                                                    }}
                                                >
                                                    {newVehicleFilePath.map((item: any, outerIndex: number) => {
                                                        return (
                                                            <React.Fragment key={outerIndex}>
                                                                <img
                                                                    src={item.fileName}
                                                                    width={40}
                                                                    height={40}
                                                                    alt="repairOrder"
                                                                    onClick={() => {
                                                                        setNewVehicleFileIndex(outerIndex);
                                                                        setImagePopup(true);
                                                                        setCurrrentPreview('new-vehicle')
                                                                    }}
                                                                />
                                                                <RemoveCircleIcon
                                                                    sx={{
                                                                        fontSize: "14px",
                                                                        color: colorConfigs.topbar.bg,
                                                                        fontWeight: "bold",
                                                                        "&: hover": {
                                                                            color: "red",
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        if (
                                                                            window.confirm(
                                                                                "Are you sure you want to delete this image?"
                                                                            )
                                                                        ) {
                                                                            let newFileSet: any[] = newVehicleFilePath.filter(
                                                                                (_, index: number) => index !== outerIndex
                                                                            );

                                                                            if (newVehicleFilePath[outerIndex].id === 0) {
                                                                                setNewVehicleFilePath(() => newFileSet);
                                                                            } else {
                                                                                axios
                                                                                    .delete(
                                                                                        `${baseURL}fileUploadData?id=${newVehicleFilePath[outerIndex].id}`
                                                                                    )
                                                                                    .then((response) => {
                                                                                        setNewVehicleFilePath(() => newFileSet);
                                                                                    })
                                                                                    .catch((error) => {
                                                                                        console.error("Error:", error);
                                                                                    });
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </div>
                                                <input
                                                    type="file"
                                                    name="inputFileRef"
                                                    accept="image/*"
                                                    ref={newInputFileRef}
                                                    onChangeCapture={onNewVehicleFileChangeCapture}
                                                    style={{ display: "none" }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px",
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                        }}
                                                        onClick={(e) => {
                                                            if (newInputFileRef.current !== null) {
                                                                newInputFileRef.current.click();
                                                            }
                                                        }}
                                                    >
                                                        {" "}
                                                        <AddIcon
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "lighter",
                                                            }}
                                                        />
                                                        Add Image
                                                    </p>

                                                    <p
                                                        style={{
                                                            border: "1px dashed black",
                                                            borderRadius: "3px",
                                                            padding: "3px 5px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "12px",
                                                            backgroundColor: "white",
                                                        }}
                                                        onClick={() => {
                                                            setShowWebcam(true)
                                                            setCurrrentWebCam('new-vehicle')
                                                        }}
                                                    >
                                                        {" "}
                                                        <LocalSeeIcon
                                                            style={{
                                                                fontSize: "14px",
                                                                color: "black",
                                                                fontWeight: "bold",
                                                            }}
                                                        />
                                                    </p>
                                                </div>
                                            </label>
                                        </div>

                                        <div style={{ width: "50%" }}>

                                        </div>

                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <label
                                            htmlFor="description"
                                            style={{ fontSize: "12px", fontWeight: "bold" }}
                                        >
                                            Comments
                                            <br />
                                            <textarea
                                                name="comments"
                                                rows={4}
                                                style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    paddingBlock: "5px",
                                                }}
                                                onChange={(e) => setReplacementComment(e.target.value)}
                                                value={replacementComment}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>
                            )
                        }


                    </div>
                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "50%" }} />

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                gap: "15px",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => {
                                    setEditReplaceVehicleIsOpen(false);
                                }}
                            >
                                Cancel
                            </Button>


                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "#FFFFFF",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "#FFFFFF",
                                    },
                                }}
                                onClick={() => handleSubmit()}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>


            <Dialog
                open={imagePopup}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        marginTop: "-14%",
                    },
                }}
            >
                <DialogTitle sx={{ padding: "0px", width: "100%" }}>
                    <div
                        style={{
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "16px 16px 8px 16px",
                        }}
                    >
                        <p style={{ fontSize: "14px", marginLeft: "5px" }}>
                            Vehicle Replacement Image
                        </p>
                        <CloseIcon
                            sx={{ fontSize: "14px", marginRight: "5px" }}
                            onClick={() => setImagePopup(false)}
                        />
                    </div>
                    <hr style={{ marginBlock: "0px" }} />
                    <div
                        style={{
                            width: "100%",
                            height: "5%",
                            backgroundColor: colorConfigs.sidebar.bg,
                            paddingLeft: "8px",
                            fontSize: "14px",
                        }}
                    >
                        Preview
                    </div>
                    <hr style={{ marginTop: "0px" }} />
                </DialogTitle>

                <DialogContent sx={{ padding: "5px" }}>
                    {currentPreview === 'pickup-vehicle' ? pickupVehicleFilePath.length !== 0 && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "transparent",
                                    position: "absolute",
                                    padding: "10px",
                                    left: "0",
                                    top: "50%",
                                }}
                            >
                                <ArrowBackIosIcon
                                    sx={{
                                        cursor: pickupVehiclefileIndex !== 0 ? "pointer" : "",
                                        opacity: pickupVehiclefileIndex === 0 ? "0.5" : "1",
                                        color: "gray",
                                    }}
                                    onClick={() => {
                                        if (pickupVehiclefileIndex !== 0) {
                                            setPickupVehiclefileIndex(pickupVehiclefileIndex - 1);
                                        }
                                    }}
                                />
                            </div>
                            <img
                                width={700}
                                height={350}
                                src={pickupVehicleFilePath[pickupVehiclefileIndex]?.fileName}
                                alt="repairOrder"
                            />

                            <div
                                style={{
                                    backgroundColor: "transparent",
                                    position: "absolute",
                                    padding: "10px",
                                    right: "0",
                                    top: "50%",
                                    color: "gray",
                                }}
                            >
                                <ArrowForwardIosIcon
                                    sx={{
                                        cursor: pickupVehiclefileIndex !== pickupVehicleFilePath.length - 1 ? "pointer" : "",
                                        opacity: pickupVehiclefileIndex === pickupVehicleFilePath.length - 1 ? "0.5" : "1",
                                    }}
                                    onClick={() => {
                                        if (pickupVehiclefileIndex !== pickupVehicleFilePath.length - 1) {
                                            setPickupVehiclefileIndex(pickupVehiclefileIndex + 1);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )
                        :
                        currentPreview === 'return-vehicle' ?
                            returnVehicleFilePath.length !== 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "5px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "transparent",
                                            position: "absolute",
                                            padding: "10px",
                                            left: "0",
                                            top: "50%",
                                        }}
                                    >
                                        <ArrowBackIosIcon
                                            sx={{
                                                cursor: returnVehiclefileIndex !== 0 ? "pointer" : "",
                                                opacity: returnVehiclefileIndex === 0 ? "0.5" : "1",
                                                color: "gray",
                                            }}
                                            onClick={() => {
                                                if (returnVehiclefileIndex !== 0) {
                                                    setReturnVehiclefileIndex(returnVehiclefileIndex - 1);
                                                }
                                            }}
                                        />
                                    </div>
                                    <img
                                        width={700}
                                        height={350}
                                        src={returnVehicleFilePath[returnVehiclefileIndex]?.fileName}
                                        alt="repairOrder"
                                    />

                                    <div
                                        style={{
                                            backgroundColor: "transparent",
                                            position: "absolute",
                                            padding: "10px",
                                            right: "0",
                                            top: "50%",
                                            color: "gray",
                                        }}
                                    >
                                        <ArrowForwardIosIcon
                                            sx={{
                                                cursor: returnVehiclefileIndex !== returnVehicleFilePath.length - 1 ? "pointer" : "",
                                                opacity: returnVehiclefileIndex === returnVehicleFilePath.length - 1 ? "0.5" : "1",
                                            }}
                                            onClick={() => {
                                                if (returnVehiclefileIndex !== returnVehicleFilePath.length - 1) {
                                                    setReturnVehiclefileIndex(returnVehiclefileIndex + 1);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                            :
                            newVehicleFilePath.length !== 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "5px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "transparent",
                                            position: "absolute",
                                            padding: "10px",
                                            left: "0",
                                            top: "50%",
                                        }}
                                    >
                                        <ArrowBackIosIcon
                                            sx={{
                                                cursor: newVehicleFileIndex !== 0 ? "pointer" : "",
                                                opacity: newVehicleFileIndex === 0 ? "0.5" : "1",
                                                color: "gray",
                                            }}
                                            onClick={() => {
                                                if (newVehicleFileIndex !== 0) {
                                                    setNewVehicleFileIndex(newVehicleFileIndex - 1);
                                                }
                                            }}
                                        />
                                    </div>
                                    <img
                                        width={700}
                                        height={350}
                                        src={newVehicleFilePath[newVehicleFileIndex]?.fileName}
                                        alt="repairOrder"
                                    />

                                    <div
                                        style={{
                                            backgroundColor: "transparent",
                                            position: "absolute",
                                            padding: "10px",
                                            right: "0",
                                            top: "50%",
                                            color: "gray",
                                        }}
                                    >
                                        <ArrowForwardIosIcon
                                            sx={{
                                                cursor: newVehicleFileIndex !== newVehicleFilePath.length - 1 ? "pointer" : "",
                                                opacity: newVehicleFileIndex === newVehicleFilePath.length - 1 ? "0.5" : "1",
                                            }}
                                            onClick={() => {
                                                if (newVehicleFileIndex !== newVehicleFilePath.length - 1) {
                                                    setNewVehicleFileIndex(newVehicleFileIndex + 1);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                    }
                </DialogContent>
            </Dialog>
            <Dialog
                open={showWebcam}
                maxWidth="md"
                fullScreen={false}
                PaperProps={{
                    style: {
                        marginTop: "-16%",
                    },
                }}
            >
                <DialogContent sx={{ padding: "0px" }}>
                    <Webcam
                        width={480}
                        height={320}
                        ref={webRef}
                        screenshotFormat="image/png"
                        audio={false}
                    />

                    <div
                        style={{
                            width: "100%",
                            height: "50px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBlock: "8px",
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            backgroundColor: "#eeeeee",
                        }}
                    >
                        <div style={{ width: "50%" }}>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: "#e0dee0",
                                    color: colorConfigs.sidebar.color,
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: "#e0dee0",
                                        color: colorConfigs.sidebar.color,
                                    },
                                }}
                                onClick={() => setShowWebcam(false)}
                            >
                                Cancel
                            </Button>
                        </div>

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "white",
                                    borderRadius: "0",
                                    border: "none",
                                    textTransform: "capitalize",
                                    fontSize: "12px",
                                    "&: hover": {
                                        backgroundColor: colorConfigs.topbar.bg,
                                        color: "white",
                                    },
                                }}
                                onClick={() => {
                                    if (currentWebCam === "return-vehicle") {
                                        if (webRef.current !== null) {
                                            //@ts-ignore
                                            const imageSrc = webRef.current.getScreenshot();
                                            setReturnVehicleNewFilePath((prevFileString: string[]) => [
                                                ...prevFileString,
                                                imageSrc,
                                            ]);

                                            const newFile: any = {
                                                id: 0,
                                                fileName: imageSrc,
                                            };
                                            setReturnVehicleFilePath((prevFileString: any[]) => [
                                                ...prevFileString,
                                                newFile,
                                            ]);
                                            setShowWebcam(false);
                                        }
                                    }
                                    else if (currentWebCam === "new-vehicle") {
                                        if (webRef.current !== null) {
                                            //@ts-ignore
                                            const imageSrc = webRef.current.getScreenshot();
                                            setNewVehicleNewFilePath((prevFileString: string[]) => [
                                                ...prevFileString,
                                                imageSrc,
                                            ]);

                                            const newFile: any = {
                                                id: 0,
                                                fileName: imageSrc,
                                            };
                                            setNewVehicleFilePath((prevFileString: any[]) => [
                                                ...prevFileString,
                                                newFile,
                                            ]);
                                            setShowWebcam(false);
                                        }
                                    }
                                }}
                            >
                                Capture an Image
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
        </>
    )
}

export default EditReplaceVehicleModal;