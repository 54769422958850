import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Select, { components } from "react-select";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../../configs/api";
import colorConfigs from "../../../../configs/colorConfigs";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { iconValues } from "../../../../utils/iconValues";

const pages = ["Edit"];

const Option = (props: any) => (
  <components.Option {...props} className="top-bar-icon-option">
    <img src={`../../../../images/icons/svg/${props.data.icon}`} alt="logo" className="top-bar-icon-logo" />
    {props.data.label}
  </components.Option>
);

export default function EditAdditionalCharges() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const [firstTime, setFirstTime] = useState<String>(pages[0]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOnClick = (page: string) => {
    setAnchorElNav(null);
    setFirstTime(page);
  };

  const idNumber = useLocation().state;
  const [name, setName] = useState<string>("");
  const [addOnCategoryId, setAddOnCategoryId] = useState<string>("");
  const [selectionType, setSelectionType] = useState<string>("Only One");
  const [chargeType, setChargeType] = useState<string>("Percent");
  const [icon, setIcon] = useState<string>("");
  const [
    decreasingRateBasedonIntervalPerDay,
    setDecreasingRateBasedonIntervalPerDay,
  ] = useState<boolean>(false);
  const [
    decreasingRateBasedonIntervalPerHour,
    setDecreasingRateBasedonIntervalPerHour,
  ] = useState<boolean>(false);
  const [
    decreasingRateBasedonIntervalPerMonth,
    setDecreasingRateBasedonIntervalPerMonth,
  ] = useState<boolean>(false);
  const [
    decreasingRateBasedonIntervalPerMinute,
    setDecreasingRateBasedonIntervalPerMinute,
  ] = useState<boolean>(false);
  const [
    decreasingRateBasedonIntervalPerWeek,
    setDecreasingRateBasedonIntervalPerWeek,
  ] = useState<boolean>(false);
  const [showOnWebsite, setShowOnWebsite] = useState<boolean>(false);

  const [isDefaultSettings, setIsDefaultSettings] = useState<boolean>(false);
  const [isDayRules, setIsDayRules] = useState<boolean>(false);
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const [isWebsite, setIsWebsite] = useState<boolean>(true);

  const [isClone, setIsClone] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const [menuBarIcon, setMenuBarIcon] = useState<any>();
  const [menuTopBarIcon, setMenuTopBarIcon] = useState<string>("");

  const handleMenuChange = (value: any) => {
    setMenuBarIcon(value);
    setMenuTopBarIcon(value.value);
  };

  const SingleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>
      <img src={`../../../../images/icons/svg/${menuBarIcon?.icon}`} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  useEffect(() => {
    axios
      .get(`${baseURL}additionalCharge/${idNumber}`)
      .then((response) => {
        setName(response.data.name);
        setAddOnCategoryId(response.data.addOnCategoryId);
        setSelectionType(response.data.selectionType);
        setChargeType(response.data.chargeType);
        setMenuTopBarIcon(response.data.icon);
        setMenuBarIcon(iconValues.find((item: any) => item.value === response.data.icon));
        setDecreasingRateBasedonIntervalPerDay(
          response.data.decreasingRateBasedonIntervalPerDay
        );
        setDecreasingRateBasedonIntervalPerHour(
          response.data.decreasingRateBasedonIntervalPerHour
        );
        setDecreasingRateBasedonIntervalPerMonth(
          response.data.decreasingRateBasedonIntervalPerMonth
        );
        setDecreasingRateBasedonIntervalPerMinute(
          response.data.decreasingRateBasedonIntervalPerMinute
        );
        setDecreasingRateBasedonIntervalPerWeek(
          response.data.decreasingRateBasedonIntervalPerWeek
        );

        setBranch(response.data.additionalChargeByBranches);
        setShowOnWebsite(response.data.showOnWebsite);

        setIsDataLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isClone) {
      setBranches(() =>
        branches.map((item: any) => {
          return {
            branchId: item.branchId,
            anountOrPercentage: item.anountOrPercentage,
            mandatory: item.mandatory,
            mandatoryOnPublicReservations: item.mandatoryOnPublicReservations,
            mandatoryOnInternalReservations:
              item.mandatoryOnInternalReservations,
            isActive: item.isActive,
          };
        })
      );

      await axios
        .post(
          `${baseURL}additionalCharge`,
          JSON.stringify({
            name,
            addOnCategoryId,
            selectionType,
            chargeType,
            icon: menuTopBarIcon,
            decreasingRateBasedonIntervalPerDay,
            decreasingRateBasedonIntervalPerHour,
            decreasingRateBasedonIntervalPerMonth,
            decreasingRateBasedonIntervalPerMinute,
            decreasingRateBasedonIntervalPerWeek,
            showOnWebsite,
            additionalChargeByBranches: branches,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      await axios
        .put(
          `${baseURL}additionalCharge`,
          JSON.stringify({
            id: idNumber,
            name,
            addOnCategoryId,
            selectionType,
            chargeType,
            icon: menuTopBarIcon,
            decreasingRateBasedonIntervalPerDay,
            decreasingRateBasedonIntervalPerHour,
            decreasingRateBasedonIntervalPerMonth,
            decreasingRateBasedonIntervalPerMinute,
            decreasingRateBasedonIntervalPerWeek,
            showOnWebsite,   
            additionalChargeByBranches: branches,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const [branches, setBranches] = useState<any[]>([]);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [branch, setBranch] = useState<any[]>([]);

  useEffect(() => {
    if (isDataLoaded) {
      axios
        .get(`${baseURL}branch`)
        .then((response) => {
          setBranches(() =>
            response.data.map((item: any) => {
              return {
                id: branch.find((data: any) => data.branchId === item.id)
                  ? branch.find((data: any) => data.branchId === item.id)?.id
                  : 0,
                branchId: item.id,
                branchName: item.branchName,
                anountOrPercentage: branch.find(
                  (data: any) => data.branchId === item.id
                )
                  ? branch.find((data: any) => data.branchId === item.id)
                    ?.anountOrPercentage
                  : "0",
                mandatory: branch.find((data: any) => data.branchId === item.id)
                  ? branch.find((data: any) => data.branchId === item.id)
                    ?.mandatory
                  : false,
                mandatoryOnPublicReservations: branch.find(
                  (data: any) => data.branchId === item.id
                )
                  ? branch.find((data: any) => data.branchId === item.id)
                    ?.mandatoryOnPublicReservations
                  : false,
                mandatoryOnInternalReservations: branch.find(
                  (data: any) => data.branchId === item.id
                )
                  ? branch.find((data: any) => data.branchId === item.id)
                    ?.mandatoryOnInternalReservations
                  : false,
                isActive: branch.find((data: any) => data.branchId === item.id)
                  ? branch.find((data: any) => data.branchId === item.id)
                    ?.isActive
                  : true,
              };
            })
          );

          setIsDataLoaded(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isDataLoaded]);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    axios
      .get(`${baseURL}addOnCategory`)
      .then((response) => {
        setCategories(() =>
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const deleteAdditionalChanges = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this Additional Charges?"
      ) === true
    ) {
      await axios
        .delete(`${baseURL}additionalCharge?id=${idNumber}`)
        .then((response) => {
          navigate(-1);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <AddShoppingCartOutlinedIcon
            sx={{ fontSize: "24px", marginRight: "5px" }}
          />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Addtional Charges / {isClone ? "Add" : "Edit"} Addtional Charge
          </Typography>
        </Box>

        {!isClone && (
          <div>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#81D4FA",
                borderRadius: "0",
                textTransform: "capitalize",
                marginRight: "5px",
              }}
              onClick={() => setIsClone(true)}
            >
              Clone
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                borderRadius: "0",
                textTransform: "capitalize",
              }}
              onClick={() => deleteAdditionalChanges()}
            >
              Delete
            </Button>
          </div>
        )}
      </Toolbar>

      {!isClone && (
        <AppBar
          position="static"
          sx={{
            backgroundColor: colorConfigs.sidebar.bg,
            border: "none",
            paddingLeft: "18px",
            paddingRight: "18px",
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "black", paddingBlock: "4px" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                paddingTop: "8px",
                paddingLeft: "0px",
                marginLeft: "-20px",
              }}
            >
              {pages.map((page) => {
                if (firstTime === page) {
                  return (
                    <Button
                      disableElevation={false}
                      key={page}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",

                        backgroundColor: "white",
                        borderWidth: "1px",
                        borderColor: "black",
                        borderStyle: "solid solid none solid",
                        "&: hover": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      disableElevation={false}
                      key={page}
                      onClick={() => handleOnClick(page)}
                      variant="text"
                      sx={{
                        my: 2,
                        color: "black",
                        display: "block",
                        paddingBlock: "0px",
                        marginBlock: "0px",
                        borderRadius: "0px",
                        fontSize: "12px",
                        fontWeight: "normal",
                        textTransform: "none",
                        textAlign: "center",
                        border: "none",
                        "&: focus": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                        "&: active": {
                          backgroundColor: "white",
                          borderWidth: "1px",
                          borderColor: "black",
                          borderStyle: "solid solid none solid",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  );
                }
              })}
            </Box>
          </Container>
        </AppBar>
      )}

      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px 26px",
        }}
      >
        <div
          style={{
            width: "75%",
            marginRight: "3%",
          }}
        >
          <h3>Additional Charge Overview</h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <label
                  htmlFor="name"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Name
                  <br />
                  <input
                    type="text"
                    name="name"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </label>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="selectionType"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Selection Type{" "}
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <div
                        onMouseOver={() => setShowTooltip(true)}
                        onMouseOut={() => setShowTooltip(false)}
                      >
                        <HelpOutlineOutlinedIcon
                          sx={{ fontSize: "12px", cursor: "pointer" }}
                        />
                      </div>

                      <Box
                        component="span"
                        m="{1}"
                        sx={{
                          visibility: showTooltip ? "visible" : "hidden",
                          width: "150px",
                          fontSize: "10px",
                          backgroundColor: "white",
                          color: "gray",
                          textAlign: "center",
                          padding: "5px 0px",
                          borderRadius: "6px",
                          border: "1px solid gray",
                          position: "absolute",
                          zIndex: "1",
                          bottom: "100%",
                          left: "50%",
                          marginLeft: "-75px",
                          "&:after": {
                            content: `' '`,
                            position: "absolute",
                            top: "100%",
                            left: "51%",
                            marginLeft: "-8px",
                            borderWidth: "8px",
                            borderStyle: "solid",
                            borderColor:
                              "white transparent transparent transparent",
                          },
                          "&:before": {
                            content: `' '`,
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            marginLeft: "-8px",
                            borderWidth: "10px",
                            borderStyle: "solid",
                            borderColor:
                              "gray transparent transparent transparent",
                          },
                        }}
                      >
                        "Only one" will charge amount as one time but "Multiple"
                        will charge the amount per day
                      </Box>
                    </div>
                    <br />
                    <select
                      name="selectionType"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={selectionType}
                      onChange={(e) => setSelectionType(e.target.value)}
                    >
                      <option value="Only One">Only One</option>
                      <option value="Multiple">Multiple</option>
                    </select>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="icon"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Icon
                    <br />
                    <Select
                      name="menu"
                      value={menuBarIcon}
                      options={iconValues}
                      onChange={handleMenuChange}
                      styles={{
                        singleValue: (base) => ({
                          ...base,
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                        }),
                      }}
                      components={{
                        Option,
                        SingleValue,
                      }}
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="timeOut"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Decreasing Rate Based on Interval (Per Day)
                    <br />
                    <div>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="decreasingRateBasedonIntervalPerDay"
                          checked={
                            decreasingRateBasedonIntervalPerDay ? true : false
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerDay(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        Yes
                      </label>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="No"
                          name="decreasingRateBasedonIntervalPerDay"
                          checked={
                            decreasingRateBasedonIntervalPerDay ? false : true
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerDay(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        No
                      </label>
                    </div>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="timeOut"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Decreasing Rate Based on Interval (Per Hour)
                    <br />
                    <div>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="decreasingRateBasedonIntervalPerHour"
                          checked={
                            decreasingRateBasedonIntervalPerHour ? true : false
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerHour(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        Yes
                      </label>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="No"
                          name="decreasingRateBasedonIntervalPerHour"
                          checked={
                            decreasingRateBasedonIntervalPerHour ? false : true
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerHour(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        No
                      </label>
                    </div>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="timeOut"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Decreasing Rate Based on Interval (Per Month)
                    <br />
                    <div>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="decreasingRateBasedonIntervalPerMonth"
                          checked={
                            decreasingRateBasedonIntervalPerMonth ? true : false
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerMonth(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        Yes
                      </label>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="No"
                          name="decreasingRateBasedonIntervalPerMonth"
                          checked={
                            decreasingRateBasedonIntervalPerMonth ? false : true
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerMonth(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        No
                      </label>
                    </div>
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div>
                  <label
                    htmlFor="category"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Category
                    <br />
                    <select
                      name="category"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={addOnCategoryId}
                      onChange={(e) => setAddOnCategoryId(e.target.value)}
                    >
                      <option style={{ color: "gray" }} value={""}>
                        Select...
                      </option>
                      {categories.map((item: any) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="chargeType"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Charge Type
                    <br />
                    <select
                      name="chargeType"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={chargeType}
                      onChange={(e) => setChargeType(e.target.value)}
                    >
                      <option value="Percent">Percent</option>
                      <option value="Amount">Amount</option>
                    </select>
                  </label>
                </div>

                <div style={{ visibility: "hidden" }}>
                  <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                    Name
                    <br />
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                    />
                  </label>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <label
                    htmlFor="timeOut"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Decreasing Rate Based on Interval (Per Minute)
                    <br />
                    <div>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="decreasingRateBasedonIntervalPerMinute"
                          checked={
                            decreasingRateBasedonIntervalPerMinute
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerMinute(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        Yes
                      </label>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="No"
                          name="decreasingRateBasedonIntervalPerMinute"
                          checked={
                            decreasingRateBasedonIntervalPerMinute
                              ? false
                              : true
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerMinute(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        No
                      </label>
                    </div>
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="timeOut"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Decreasing Rate Based on Interval (Per Week)
                    <br />
                    <div>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="Yes"
                          name="decreasingRateBasedonIntervalPerWeek"
                          checked={
                            decreasingRateBasedonIntervalPerWeek ? true : false
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerWeek(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        Yes
                      </label>
                      <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                        <input
                          type="radio"
                          value="No"
                          name="decreasingRateBasedonIntervalPerWeek"
                          checked={
                            decreasingRateBasedonIntervalPerWeek ? false : true
                          }
                          onChange={(e) =>
                            setDecreasingRateBasedonIntervalPerWeek(
                              e.target.value === "Yes" ? true : false
                            )
                          }
                        />{" "}
                        No
                      </label>
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
                gap: "8px",
              }}
            >
              <div
                style={{
                  width: "25%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                }}
              >
                Branch
              </div>
              <div
                style={{
                  width: "25%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                }}
              >
                Amount or %
              </div>
              <div
                style={{
                  width: "10%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                }}
              >
                Mandatory
              </div>
              <div
                style={{
                  width: "14%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                }}
              >
                Mandatory on Public Reservations
              </div>
              <div
                style={{
                  width: "14%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                }}
              >
                Mandatory on internal Reservatoins
              </div>
              <div
                style={{
                  width: "10%",
                  color: "gray",
                  textAlign: "start",
                  fontSize: "12px",
                }}
              >
                Excluded Branches
              </div>
            </div>

            <hr style={{ marginBottom: "0px" }} />

            {branches.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        width: "25%",
                        color: "gray",
                        textAlign: "start",
                        fontSize: "12px",
                        marginBlock: "4px",
                      }}
                    >
                      {item.branchName}
                    </div>

                    <hr style={{ marginBlock: "0px" }} />

                    <div
                      style={{
                        width: "25%",
                        color: "gray",
                        textAlign: "start",
                        fontSize: "12px",
                        marginBlock: "4px",
                      }}
                    >
                      <input
                        style={{ width: "100%" }}
                        type="text"
                        value={item?.anountOrPercentage}
                        onChange={(e) => {
                          branches[index].anountOrPercentage = e.target.value;
                          setBranches(branches);
                          setIsChange(!isChange);
                        }}
                      />
                    </div>

                    <hr style={{ marginBlock: "0px" }} />

                    <div
                      style={{
                        width: "10%",
                        color: "gray",
                        textAlign: "start",
                        fontSize: "12px",
                        marginBlock: "4px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={item?.mandatory}
                        onChange={() => {
                          branches[index].mandatory = !item.mandatory;
                          setBranches(branches);
                          setIsChange(!isChange);
                        }}
                      />
                    </div>

                    <hr style={{ marginBlock: "0px" }} />

                    <div
                      style={{
                        width: "14%",
                        color: "gray",
                        textAlign: "start",
                        fontSize: "12px",
                        marginBlock: "4px",
                      }}
                    >
                      {" "}
                      <input
                        type="checkbox"
                        checked={item?.mandatoryOnPublicReservations}
                        onChange={() => {
                          branches[index].mandatoryOnPublicReservations =
                            !item.mandatoryOnPublicReservations;
                          setBranches(branches);
                          setIsChange(!isChange);
                        }}
                      />
                    </div>

                    <hr style={{ marginBlock: "0px" }} />

                    <div
                      style={{
                        width: "14%",
                        color: "gray",
                        textAlign: "start",
                        fontSize: "12px",
                        marginBlock: "4px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={item?.mandatoryOnInternalReservations}
                        onChange={() => {
                          branches[index].mandatoryOnInternalReservations =
                            !item.mandatoryOnInternalReservations;
                          setBranches(branches);
                          setIsChange(!isChange);
                        }}
                      />
                    </div>

                    <hr style={{ marginBlock: "0px" }} />

                    <div
                      style={{
                        width: "10%",
                        color: "gray",
                        textAlign: "start",
                        fontSize: "12px",
                        marginBlock: "4px",
                      }}
                    >
                      <input type="checkbox" />
                    </div>
                  </div>

                  <hr style={{ marginBlock: "0px" }} />
                </div>
              );
            })}

            <h5
              style={{
                paddingBottom: "0px",
                marginTop: "24px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsDefaultSettings(!isDefaultSettings)}
            >
              {isDefaultSettings ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Default Settings
            </h5>
            <hr />

            {isDefaultSettings && (
              <>
                <label>Default Settings</label>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsDayRules(!isDayRules)}
            >
              {isDayRules ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Day Rules
            </h5>
            <hr />

            {isDayRules && (
              <>
                <label>Day Rules</label>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsAdvanced(!isAdvanced)}
            >
              {isAdvanced ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Advanced
            </h5>
            <hr />

            {isAdvanced && (
              <>
                <label>Advanced</label>
              </>
            )}

            <h5
              style={{
                paddingBottom: "0px",
                marginBottom: "0px",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setIsWebsite(!isWebsite)}
            >
              {isWebsite ? (
                <ExpandMoreIcon sx={{ fontSize: "20px" }} />
              ) : (
                <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
              )}{" "}
              Website
            </h5>
            <hr />

            {isWebsite && (
              <>
              <div style={{ marginTop: "10px" }}>
                <label
                  htmlFor="showOnWebsite"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Show on Website?
                  <br />
                  <div>
                    <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="showOnWebsite"
                        checked={
                          showOnWebsite ? true : false
                        }
                        onChange={(e) =>
                          setShowOnWebsite(
                            e.target.value === "Yes" ? true : false
                          )
                        }
                      />{" "}
                      Yes
                    </label>
                    <label style={{ fontSize: "12px", fontWeight: "normal" }}>
                      <input
                        type="radio"
                        value="No"
                        name="showOnWebsite"
                        checked={
                          showOnWebsite ? false : true
                        }
                        onChange={(e) =>
                          setShowOnWebsite(
                            e.target.value === "Yes" ? true : false
                          )
                        }
                      />{" "}
                      No
                    </label>
                  </div>
                </label>
              </div>
            </>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  marginTop: "20px",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  marginTop: "20px",
                }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>

        <div
          style={{
            width: "25%",
          }}
        />
      </div>
    </>
  );
}
