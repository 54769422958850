import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import { validateMin } from "../../../utils/validateMin";

type Props = {
  reservation: any;
  reservationUpdate: () => void;
  selectedVehicleClass: any;
  discountList: any[];
  discountUpdate: () => void;
  isCompletedOrCancelled: boolean
};

export default function AddDiscount({
  reservation,
  reservationUpdate,
  selectedVehicleClass,
  discountList,
  discountUpdate,
  isCompletedOrCancelled
}: Props) {
  const [isDiscounts, setIsDiscounts] = useState<boolean>(true);

  const [isAddDiscount, setIsAddDiscount] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<string>("");
  const [customDiscountValue, setCustomDiscountValue] = useState<string>("");
  const [manualDiscount, setManualDiscount] = useState<string>("");
  const [isManualDiscountInAPercentage, setIsManualDiscountInAPercentage] =
    useState<boolean>(false);
  const [discountOptions, setDiscountOptions] = useState<any[]>([]);

  useEffect(() => {
    if (reservation) {
      axios
        .get(`${baseURL}/discount`)
        .then((response) => {
          setDiscountOptions(
            response.data.filter(
              (item: any) => item.branchId === parseInt(reservation?.branchId)
            )
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [reservation]);

  const handleDiscount = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let amount = 0.0;

    if (couponCode) {
      amount =
        discountOptions.find((item: any) => item.id === parseInt(couponCode))
          ?.discounttype === "Amount"
          ? parseFloat(
            discountOptions.find(
              (item: any) => item.id === parseInt(couponCode)
            )?.discountAmount
          )
          : ((parseFloat(selectedVehicleClass?.days) *
            Number(selectedVehicleClass?.dailyRate) +
            parseFloat(selectedVehicleClass?.hours) *
            Number(selectedVehicleClass?.hourlyRate)) *
            parseFloat(
              discountOptions.find(
                (item: any) => item.id === parseInt(couponCode)
              )?.discountAmount
            )) /
          100;
      addDiscount(
        discountOptions.find((item: any) => item.id === parseInt(couponCode))
          ?.name,
        discountOptions.find((item: any) => item.id === parseInt(couponCode))
          ?.discounttype,
        amount,
        false
      );
    } else if (customDiscountValue) {
      addDiscount(
        "Manual Discount",
        "Manual",
        parseFloat(customDiscountValue),
        true
      );
    } else if (manualDiscount) {
      amount = isManualDiscountInAPercentage
        ? ((parseFloat(selectedVehicleClass?.days) *
          parseFloat(selectedVehicleClass?.dailyRate) +
          parseFloat(selectedVehicleClass?.hours) *
          parseFloat(selectedVehicleClass?.hourlyRate)) *
          parseFloat(manualDiscount)) /
        100
        : parseFloat(manualDiscount);
      addDiscount(
        "Manual Discount",
        isManualDiscountInAPercentage ? "Percentage" : "Amount",
        amount,
        true
      );
    } else {
      discountUpdate();
      setIsAddDiscount(false);
    }
  };

  const addDiscount = async (
    discountDefinition: string,
    discountType: string,
    discountAmount: number,
    manualDiscount: boolean
  ) => {
    if (!isCompletedOrCancelled) {
      await axios
        .post(
          `${baseURL}reservationDiscount`,
          JSON.stringify({
            reservationId: reservation?.id,
            discountDefinition: discountDefinition,
            discountType: discountType,
            discountAmount: discountAmount,
            dateofDiscount: new Date(),
            supportingDocuments: false,
            createdAt: new Date(),
            createdBy: "Website",
            manualDiscount: manualDiscount,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          setCouponCode("");
          setCustomDiscountValue("");
          setManualDiscount("");
          setIsManualDiscountInAPercentage(false);
          updateReservation(discountAmount, false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleDelete = async (id: number, amount: number) => {
    if (!isCompletedOrCancelled) {
      if (
        window.confirm("Are you sure you want to remove this discount?") === true
      ) {
        await axios
          .delete(`${baseURL}reservationDiscount?id=${id}`)
          .then((response) => {
            updateReservation(amount, true);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  const updateReservation = async (amount: number, isDelete: boolean) => {
    if (!isCompletedOrCancelled) {
      await axios
        .put(
          `${baseURL}reservation`,
          JSON.stringify({
            ...reservation,
            totalPrice: isDelete
              ? parseFloat(reservation?.totalPrice) + amount
              : parseFloat(reservation?.totalPrice) - amount,
            outstandingBalance: isDelete
              ? parseFloat(reservation?.outstandingBalance) + amount
              : parseFloat(reservation?.outstandingBalance) - amount,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (!isDelete) {
            setIsAddDiscount(false);
          }
          discountUpdate();
          reservationUpdate();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <h5
        style={{
          paddingBottom: "0px",
          marginBottom: "0px",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          WebkitUserSelect: "none" /* Safari */,
          msUserSelect: "none" /* IE 10 and IE 11 */,
          userSelect: "none" /* Standard syntax */,
        }}
        onClick={() => setIsDiscounts(!isDiscounts)}
      >
        {isDiscounts ? (
          <ExpandMoreIcon sx={{ fontSize: "20px" }} />
        ) : (
          <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />
        )}{" "}
        Discounts
      </h5>
      <hr />

      {isDiscounts && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                width: "8%",
                marginBottom: "0px",
              }}
            >
              #
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                width: "32%",
                marginBottom: "0px",
              }}
            >
              Discount definition
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                width: "26%",
                marginBottom: "0px",
              }}
            >
              Discount Type
            </p>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                width: "26%",
                marginBottom: "0px",
              }}
            >
              Discount
            </p>

            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                width: "7%",
                marginBottom: "0px",
              }}
            >
              Delete
            </p>
          </div>
          <hr style={{ marginBlock: "0px" }} />

          {discountList.map((item: any) => {
            return (
              <div key={item.id}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      width: "8%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.id}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      width: "30%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.discountDefinition}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      width: "26%",
                      marginBottom: "0px",
                    }}
                  >
                    {item.discountType}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      width: "26%",
                      marginBottom: "0px",
                    }}
                  >
                    ${item.discountAmount}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      width: "7%",
                      marginBottom: "0px",
                    }}
                  >
                    <RemoveCircleOutlinedIcon
                      sx={{
                        fontSize: "12px",
                        color: "gray",
                        cursor: "pointer",
                        "&: hover": {
                          color: "red",
                        },
                      }}
                      onClick={(e) =>
                        handleDelete(item.id, item.discountAmount)
                      }
                    />
                  </p>
                </div>
                <hr style={{ marginBlock: "0px" }} />
              </div>
            );
          })}

          <div>
            <button
              style={{
                marginBlock: "5px",
                paddingBlock: "3px",
                backgroundColor: colorConfigs.topbar.bg,
                color: "white",
                border: "none",
                padding: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!isCompletedOrCancelled)
                  setIsAddDiscount(true)
              }}
            >
              Add Discount
            </button>
          </div>
        </>
      )}

      <Dialog
        open={isAddDiscount}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "50%",
            display: "flex",
            position: "absolute",
            top: "30px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>Add Discount</p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px", cursor: "pointer" }}
              onClick={() => setIsAddDiscount(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
        </DialogTitle>
        <DialogContent sx={{ padding: "0px" }}>
          <form onSubmit={(e) => handleDiscount(e)}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "8px 16px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="couponCode"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Coupon Code
                    <select
                      name="couponCode"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    >
                      <option value="">Select...</option>
                      {discountOptions.map((item: any) => {
                        return <option key={item.id} value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </label>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="manualDiscount"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Manual Discount($)
                      <input
                        type="text"
                        name="manualDiscount"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={manualDiscount}
                        onChange={(e) => setManualDiscount(e.target.value)}
                      />
                    </label>
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <label
                    htmlFor="customDiscountValue"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Custom Discount Value
                    <input
                      type="number"
                      min={0}
                      name="customDiscountValue"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={customDiscountValue}
                      onChange={(e) => {
                        setCustomDiscountValue(validateMin(e.target.value, 0).toString())
                      }}
                    />
                  </label>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="isManualDiscountInAPercentage"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Manual Discount is a Percentage?
                    </label>{" "}
                    <br />
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="Yes"
                        name="isManualDiscountInAPercentage"
                        style={{ fontSize: "12px" }}
                        checked={isManualDiscountInAPercentage ? true : false}
                        onChange={(e) =>
                          setIsManualDiscountInAPercentage(
                            e.target.value === "Yes" ? true : false
                          )
                        }
                      />
                      Yes
                    </label>
                    <label style={{ fontSize: "12px" }}>
                      <input
                        type="radio"
                        value="No"
                        name="isManualDiscountInAPercentage"
                        style={{ fontSize: "12px" }}
                        checked={isManualDiscountInAPercentage ? false : true}
                        onChange={(e) =>
                          setIsManualDiscountInAPercentage(
                            e.target.value === "Yes" ? true : false
                          )
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingBlock: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                backgroundColor: "#eeeeee",
              }}
            >
              <div style={{ width: "50%" }} />

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: "#e0dee0",
                      color: colorConfigs.sidebar.color,
                    },
                  }}
                  onClick={() => setIsAddDiscount(false)}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    "&: hover": {
                      backgroundColor: colorConfigs.topbar.bg,
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
