import { Box, Button, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import BusinessIcon from "@mui/icons-material/Business";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import colorConfigs from "../../../configs/colorConfigs";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../configs/api";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axois from "../../../configs/axois";
import { format } from "date-fns";
import { validateMin } from "../../../utils/validateMin";

const fuelLevelOptions: any = [];
for (let i = 0; i <= 8; i++) {
  fuelLevelOptions.push(<option key={i} value={i}>{i}</option>);
}

export default function CreateRepairOrder() {
  const [vehicleOptions, setVehicleOption] = React.useState([]);
  const [maintenanceTypeOptions, setMaintenanceTypeOptions] = React.useState(
    []
  );
  const location = useLocation();

  const [vehicle, setVehicle] = useState<string>("");
  const [maintenanceType, setMaintenanceType] = useState<string>("");
  const [totalInParts, setTotalInParts] = useState(0);
  const [totalInLabour, setTotalInLabour] = useState(0);
  const [damages, setDamages] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [dateOut, setDateOut] = useState<string>("");
  const [timeOut, setTimeOut] = useState<string>("T00:00:00");
  const [dateDue, setDateDue] = useState<string>("");
  const [timeDue, setTimeDue] = useState<string>("T00:00:00");
  const [mechanic, setMechanic] = useState<string>("");
  const [mechanicComments, setMechanicComments] = useState<string>("");
  const [comments, setComments] = useState<string>("");

  useEffect(() => {
    axois
      .get(`${baseURL}vehicles`)
      .then((response) => {
        setVehicleOption(
          response.data.map((item: any) => {
            return { value: item.id, label: item.vehicleClass };
          })
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    fetch(`${baseURL}maintenanceType`)
      .then((res) => res.json())
      .then((data) => {
        setMaintenanceTypeOptions(
          data.map((item: any) => {
            return { value: item.id, label: item.maintenanceName };
          })
        );
      });
  }, []);

  useEffect(() => {
    fetch(`${baseURL}maintenance/${location.state}`)
      .then((res) => res.json())
      .then((data) => {
        setDateOut(data.lastMaintenaceDate.split("T")[0]);
        setTimeOut(format(new Date(data.lastMaintenaceDate), "kk:mm"));
        setDateDue(data.nextMaintenaceDate.split("T")[0]);
        setTimeDue(format(new Date(data.nextMaintenaceDate), "kk:mm"));
        setMaintenanceType(data.maintenanceTypeId);

        fetch(`${baseURL}vehicles/${data.vehicleId}`)
          .then((res) => res.json())
          .then((data) => {
            setVehicle(data.vehicleClass);
          });

        fetch(`${baseURL}maintenanceStatus/${data.maintenanceStatusId}`)
          .then((res) => res.json())
          .then((data) => {
            setStatus(data.name);
          });
      });
  }, []);

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await axios
      .post(
        `${baseURL}vehicleRepairOrder`,
        JSON.stringify({
          vehicleId: 2,
          maintenanceType,
          totalInParts,
          totalInLabour,
          status,
          notes,
          dateOut:
            timeOut === "T00:00:00"
              ? dateOut + timeOut
              : dateOut + ("T" + timeOut),
          dateDue:
            timeDue === "T00:00:00"
              ? dateDue + timeDue
              : dateDue + ("T" + timeDue),
          mechanic,
          comments: mechanicComments,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [text, setText] = useState("");
  useEffect(() => {
    let html = editorState.getCurrentContent().getPlainText("\u0001") as string;
    setNotes(html);
    console.log(notes);
  }, [editorState]);

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <BusinessIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Repair Orders / Add Repair Order
          </Typography>
        </Box>

        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "black",
            borderRadius: "0",
            border: "none",
          }}
        >
          <LocalPrintshopOutlinedIcon />
        </Button>
      </Toolbar>

      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "50%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            General Information
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <label
              htmlFor="vehicle"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              Vehicle
              <br />
            </label>

            <p
              style={{
                fontSize: "12px",
                textAlign: "start",
                marginBlock: "0px",
              }}
            >
              {vehicle}
            </p>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="maintenanceType"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Maintenance Type
                    <br />
                    <select
                      name="maintenanceType"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={maintenanceTypeOptions.find(
                        (item: any) => item.value === maintenanceType
                      )}
                      onChange={(e) => setMaintenanceType(e.target.value)}
                    >
                      {maintenanceTypeOptions.map((item: any) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </label>
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "50%", marginRight: "3%" }}>
                    <label
                      htmlFor="totalInParts"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Total In Parts
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="totalInParts"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={totalInParts}
                        onChange={(e) =>
                          setTotalInParts(validateMin(e.target.value, 0))
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ width: "50%" }}>
                    <label
                      htmlFor="totalInLabor"
                      style={{ fontSize: "12px", fontWeight: "bold" }}
                    >
                      Total In Labor
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="totalInLabor"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={totalInLabour}
                        onChange={(e) =>
                          setTotalInLabour(validateMin(e.target.value, 0))
                        }
                        required
                      />
                    </label>
                  </div>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="odemeter"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Images
                    <br />
                    <input
                      disabled={false}
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                    />
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ visibility: "hidden" }}>
                  <label
                    htmlFor=" vehicleKey"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Vehicle Key
                    <br />
                    <input
                      type="text"
                      name="vehicleKey"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                    />
                  </label>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <label
                    htmlFor="vehicleMode"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Damages
                    <br />
                    <input
                      type="text"
                      name="vehicle"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={damages}
                      onChange={(e) => setDamages(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="vehiclSecondaryClass"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Status
                    <br />
                    <select
                      name="vehicleType"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Skipped">Skipped</option>
                      <option value="Done">Done</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <label
                htmlFor="odemeter"
                style={{ fontSize: "12px", fontWeight: "bold" }}
              >
                Notes
              </label>
              <br />

              <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={setEditorState}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>

            <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Vehicle Out Information
            </h3>
            <hr />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="dateOut"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Date Out
                  <br />
                  <input
                    type="date"
                    data-date-format="MM-DD-YYYY"
                    name="dateOut"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={dateOut}
                    onChange={(e) => setDateOut(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="timeOut"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Time Out
                  <br />
                  <input
                    type="time"
                    name="timeOut"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={timeOut}
                    onChange={(e) => setTimeOut(e.target.value)}
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="dateDue"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Date Due
                  <br />
                  <input
                    type="date"
                    data-date-format="MM-DD-YYYY"
                    name="dateDue"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={dateDue}
                    onChange={(e) => setDateDue(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="timeDue"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Time Due
                  <br />
                  <input
                    type="time"
                    name="timeDue"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={timeDue}
                    onChange={(e) => setTimeDue(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Internal Management
            </h3>
            <hr />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ width: "50%", marginRight: "3%" }}>
                <label
                  htmlFor="vehicle"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Mechanic
                  <br />
                  <Select
                    styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    name="vehicle"
                    value={vehicleOptions.find(
                      (item: any) => item.value === mechanic
                    )}
                    onChange={(mechanic: any) =>
                      setMechanic(mechanic ? mechanic.value : "")
                    }
                    options={vehicleOptions}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "100%", marginRight: "3%" }}>
                <label
                  htmlFor="timeDue"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  Comments
                </label>
                <br />

                <textarea
                  style={{ width: "100%", height: "15%", resize: "none" }}
                  value={mechanicComments}
                  onChange={(e) => setMechanicComments(e.target.value)}
                />
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>

        <div
          style={{
            width: "50%",
          }}
        >
          <h3 style={{ marginBottom: "0px", paddingBottom: "0px" }}>
            Comments
          </h3>
          <hr />

          <textarea
            style={{ width: "100%", height: "15%", resize: "none" }}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />

          <Button
            variant="contained"
            sx={{
              backgroundColor: colorConfigs.topbar.bg,
              borderRadius: "0",
              textTransform: "capitalize",
            }}
          >
            Save Comment
          </Button>
        </div>
      </div>
    </>
  );
}
