import { Fragment, useEffect, useState } from "react";
import { Button } from "@mui/material";
import Select from "react-select";
import { AddOutlined, ExpandMore, KeyboardArrowRight } from "@mui/icons-material";
import colorConfigs from "../../../configs/colorConfigs";
import axios from "axios";
import baseURL from "../../../configs/api";
import { format } from "date-fns";
import AddIdentification from "../../contacts/customers/AddIdentification";
import { AddNewCustomer } from "./AddNewCustomer";
import useAuth from "../../../components/hooks/useAuth";
import { getCustomer } from "../../../services/customer";
import { getReservationHistoryByCustomer } from "../../../services/reservationHistory";
import { getCustomerExtraInformationsByCustomerId } from "../../../services/customerExtraInformation";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomerSelectWrapper from "./CustomerSelectWrapper";

type ExtraInformation = {
  id: number;
  idNumber: string;
  issueDate: string;
  exprationDate: string;
  filePath: string;
  idTypeId: string;
  customerId: number;
  type: string;
  imageSize: number,
  uploadedAt: string,
  uploadedBy: string,
};

type Props = {
  goToNextPage: (page: number) => void;
  getDataFromCustomer: (customerId: string, localAddress: string) => void;
  vehicleClassId: string;
  branchId: string;
  pickupDate: string;
  pickupTime: string;
  returnDate: string;
  returnTime: string;
  outstandingBalance: number;
  hideToolbar: boolean;
  setHideToolbar: (hideToolbar: boolean) => void;
  customerPrimaryKey: string;
  reservation: any;
  isFirstTime: boolean;
  setIsFirstTime: (value: boolean) => void;
  currentPage: string;
  isCompletedOrCancelled: boolean;
};

const monthNameOptions = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const daysOptions: any = [];
for (let i = 1; i <= 31; i++) {
  daysOptions.push({ value: i, label: i });
}

const yearOptions: any = [];
for (let i = 1940; i <= new Date().getFullYear(); i++) {
  yearOptions.push({ value: i, label: i });
}

export default function Customer({
  goToNextPage,
  getDataFromCustomer,
  vehicleClassId,
  branchId,
  pickupDate,
  pickupTime,
  returnDate,
  returnTime,
  outstandingBalance,
  hideToolbar,
  setHideToolbar,
  customerPrimaryKey,
  reservation,
  isFirstTime,
  setIsFirstTime,
  currentPage,
  isCompletedOrCancelled
}: Props) {
  const { auth }: any = useAuth();
  const navigate = useNavigate();
  const [isCustomerInformation, setIsCutomerInformation] =
    useState<boolean>(true);
  const [isExtraInformation, setIsExtraInformation] = useState<boolean>(true);
  const [availableVehicle, setAvailableVehicle] = useState<any[]>([]);
  const [availableVehicleOfParticular, setAvailableVehicleOfParticular] =
    useState<any>();
  const [customerId, setCustomerId] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [Street, setStreet] = useState<string>("");
  const [street2, setStreet2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [localAddress, setLocalAddress] = useState<string>("");
  const [reservationHistoryLoaded, setReservationHistoryLoaded] = useState<boolean>(false);
  const [extraRow, setExtraRow] = useState<ExtraInformation[]>([]);
  const [allExtraValue, setAllExtraValue] = useState<boolean>(false);
  const [indexNumber, setIndexNumber] = useState<number>(0);

  const [addNewCustomer, setAddNewCustomer] = useState<boolean>(hideToolbar);

  const [isHistory, setIsHistory] = useState<boolean>(false);
  const [historyValue, setHistoryValue] = useState<any[]>([]);
  const [pageNo, setPage] = useState<number>(-1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isNextPageLoading, setNextPageLoading] = useState<boolean>(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);

  let latestPickup = new Date();
  let latestReturn = new Date();
  if (pickupDate) latestPickup = new Date(pickupTime ? `${pickupDate}T${pickupTime}` : pickupDate);
  if (returnDate) latestReturn = new Date(returnTime ? `${returnDate}T${returnTime}` : returnDate);


  useEffect(() => {
    if (reservation && currentPage === 'Customer') {
      setCustomerId(reservation?.customerId);
    }
  }, [reservation, currentPage]);

  useEffect(() => {
    if (customerPrimaryKey && currentPage === 'Customer') {
      setCustomerId(customerPrimaryKey);
    }
  }, [customerPrimaryKey, currentPage]);

  useEffect(() => {
    if (customerId && isHistory && isFirstTime && currentPage === 'Customer') {
      setReservationHistoryLoaded(false);
      getReservationHistoryByCustomer(customerId)
        .then(data => {
          setHistoryValue(data)
          setReservationHistoryLoaded(true);
        })
        .catch(error => console.error(error));
    }
  }, [customerId, isHistory, currentPage]);

  useEffect(() => {
    if (currentPage === 'Customer') {
      if (isHistory && isFirstTime) {
        setIsFirstTime(false);
      }
      else if (!isHistory && !isFirstTime) {
        setIsFirstTime(true);
      }
    }
  }, [isHistory, isFirstTime, currentPage])

  const handleAddExtraRow = () => {
    if (!isCompletedOrCancelled) {
      setIndexNumber(indexNumber + 1);

      const data: ExtraInformation = {
        id: indexNumber,
        idNumber: "",
        issueDate: "",
        exprationDate: "",
        filePath: "",
        idTypeId: "",
        customerId: parseInt(customerId),
        type: "POST",
        imageSize: 0,
        uploadedAt: `${format(new Date(), "MM/dd/yyyy")} ${format(new Date(), "hh: mm: ss a")}`,
        uploadedBy: auth.givenname,
      };

      setExtraRow((extraRow: ExtraInformation[]) => [...extraRow, data]);
    }
  };

  const handleDeleteExtraRow = async (index: number) => {
    if (!isCompletedOrCancelled) {
      if (extraRow.find((row: ExtraInformation) => row.id === index)?.type.split(" ")[0] === "PUT") {
        await axios
          .delete(`${baseURL}customerExtraInformation?id=${extraRow.find((row: ExtraInformation) => row.id === index)?.type.split(" ")[1]}`)
          .then(() => {
            const newRow: ExtraInformation[] = extraRow.filter(
              (row: ExtraInformation) => row.id !== index
            );
            setExtraRow(newRow);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else {
        const newRow: ExtraInformation[] = extraRow.filter(
          (row: ExtraInformation) => row.id !== index
        );
        setExtraRow(newRow);
      }
    }
  };

  const changeOnExtraValue = (row: ExtraInformation) => {
    setExtraRow((extraRow) =>
      extraRow.map((rowValue: ExtraInformation) => {
        return rowValue.id === row.id ? row : rowValue;
      })
    );
  };

  useEffect(() => {
    if (customerId && currentPage === 'Customer') {
      getCustomer(customerId)
        .then(data => {
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setEmailAddress(data.emailAddress);
          setPhoneNumber(data.phoneNumber);
          setStreet(data.street);
          setStreet2(data.street2);
          setCity(data.city);
          setState(data.state);
          setCountry(data.country);
          setZip(data.zip);
          setYear(new Date(data.birthday).getFullYear().toString());
          setDay(new Date(data.birthday).getDate().toString());
          setMonth((new Date(data.birthday).getMonth() + 1).toString());
        })
        .catch(error => console.error(error));
    }
  }, [customerId, currentPage]);

  useEffect(() => {
    if (branchId && pickupDate && pickupTime && returnDate && returnTime && currentPage === 'Customer') {
      axios
        .get(
          `${baseURL}availableCars?pickupDate=${latestPickup.toISOString()}&returnDate=${latestReturn.toISOString()}&branchId=${branchId}`
        )
        .then((response) => {
          setAvailableVehicle(() => response.data);
        })
        .catch(error => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId, pickupDate, pickupTime, returnDate, returnTime, currentPage]);

  useEffect(() => {
    if (availableVehicle && currentPage === 'Customer') {
      setAvailableVehicleOfParticular(() =>
        availableVehicle.find(
          (item: any) => item.vehicleClassId === parseInt(vehicleClassId)
        )
      );
    } else {
      setAvailableVehicleOfParticular(() => availableVehicle[0]);
    }
  }, [vehicleClassId, availableVehicle, currentPage]);

  useEffect(() => {
    if (allExtraValue && currentPage === 'Customer') {
      const lastIndexValue: any = extraRow.find(
        (item: ExtraInformation) => extraRow[extraRow.length - 1] === item
      )?.id;
      setIndexNumber(lastIndexValue + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allExtraValue, currentPage]);

  useEffect(() => {
    if (!customerId || currentPage !== "Customer") return; 
      getCustomerExtraInformationsByCustomerId(customerId)
        .then(data => {
          setExtraRow(
            data.filter((item: ExtraInformation) => parseInt(customerId) === item.customerId)
              .map((item: ExtraInformation, index: number) => {
                return {
                  id: index + 1,
                  idNumber: item.idNumber,
                  issueDate: format(new Date(item.issueDate), "yyyy-MM-dd"),
                  exprationDate: format(new Date(item.exprationDate), "yyyy-MM-dd"),
                  filePath: item.filePath,
                  idTypeId: item.idTypeId,
                  customerId: item.customerId,
                  type: `PUT ${item?.id}`,
                  imageSize: item.imageSize,
                  uploadedAt: item.uploadedAt,
                  uploadedBy: item.uploadedBy

                };
              })
          );

          if (data.filter((item: ExtraInformation) => parseInt(customerId) === item.customerId).length > 0) setAllExtraValue(true);
        })
        .catch(error => console.error(error));
  }, [customerId, currentPage]);

  const calculateAge = (date: string) => {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isCompletedOrCancelled) {
      if (addNewCustomer) return;

      if (!customerId) {
        alert("Please select a customer or create a new one");
        return;
      }

      const updateBirthday = `${year}-${month}-${day}`;

      if (
        calculateAge(updateBirthday) <
        availableVehicleOfParticular.minimumRentalAllowedAge &&
        calculateAge(updateBirthday) < availableVehicleOfParticular.maxRentalAllowedAge
      ) {
        window.alert("You are not old enough to operate this vehicle.");
        return;
      }

      await axios
        .put(
          `${baseURL}Customer`,
          JSON.stringify({
            id: customerId,
            firstName,
            lastName,
            emailAddress,
            phoneNumber,
            Street,
            street2,
            city,
            state,
            country,
            zip,
            birthday: new Date(`${year}-${month}-${day}`),
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        )
        .then(() => {
          savedExtraInfo();
        })
        .catch(error => console.error("Error:", error));
    }
  };

  const savedExtraInfo = () => {
    if (!isCompletedOrCancelled) {
      if (extraRow.length !== 0) {
        let count = 0;
        let shouldBlockNextPage = false;

        extraRow.forEach(async (item: ExtraInformation) => {
          const returnDateTime = new Date(`${returnDate}T${returnTime}`);
          const exprationDate = new Date(item.exprationDate);

          if (exprationDate < returnDateTime) {
            window.alert(
              `The expiration date for ID (${item.idNumber}) cannot be earlier than the return date and time.`
            );
            shouldBlockNextPage = true;
            return;
          }

          if (item.type.split(" ")[0] === "POST") {
            await axios
              .post(
                `${baseURL}customerExtraInformation`,
                JSON.stringify({
                  idNumber: item.idNumber,
                  issueDate: new Date(item.issueDate),
                  exprationDate: new Date(item.exprationDate),
                  filePath: item.filePath,
                  idTypeId: item.idTypeId,
                  customerId: item.customerId,
                  imageSize: item.imageSize,
                  uploadedAt: new Date(item.uploadedAt),
                  uploadedBy: item.uploadedBy
                }),
                {
                  headers: { "Content-Type": "application/json" },
                  withCredentials: true,
                }
              )
              .then((response) => {
                count++;
                if (extraRow.length === count && !shouldBlockNextPage) {
                  getDataFromCustomer(customerId, localAddress);
                  goToNextPage(4);
                }
              })
              .catch((error) => console.error("Error:", error));
          } else if (item.type.split(" ")[0] === "PUT") {
            await axios
              .put(
                `${baseURL}customerExtraInformation`,
                JSON.stringify({
                  id: item.type.split(" ")[1],
                  idNumber: item.idNumber,
                  issueDate: new Date(item.issueDate),
                  exprationDate: new Date(item.exprationDate),
                  filePath: item.filePath,
                  idTypeId: item.idTypeId,
                  customerId: item.customerId,
                  imageSize: item.imageSize,
                  uploadedAt: new Date(item.uploadedAt),
                  uploadedBy: item.uploadedBy
                }),
                {
                  headers: { "Content-Type": "application/json" },
                  withCredentials: true,
                }
              )
              .then((response) => {
                count++;
                if (extraRow.length === count && !shouldBlockNextPage) {
                  getDataFromCustomer(customerId, localAddress);
                  goToNextPage(4);
                }
              })
              .catch((error) => console.error("Error:", error));
          }
        });
      } else {
        getDataFromCustomer(customerId, localAddress);
        goToNextPage(4);
      }
    };
  }

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;
    setHasNextPage(true);
    setNextPageLoading(false);
    setPage(-1);
    if (searchValue !== "") {
      timeOutId = setTimeout(() => {
        setIsSearching(false);
        setOptions([]);
      }, 1000);
    } else {
      setOptions([]);
      setIsSearching(false);
    }

    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const loadNextPage = () => {
    if (searchValue === "") {
      loadWithOutSearchOptions(pageNo + 1);
    } else {
      loadWithSearchOptions(pageNo + 1);
    }
  };

  const loadWithOutSearchOptions = (page: number) => {
    try {
      const pagination = {
        page: page,
        size: 15,
      };
      setNextPageLoading(true);
      fetch(`${baseURL}Customer/GetCustomersPaging/${pagination.page},${pagination.size}`)
        .then((res) => res.json())
        .then((data) => {
          const dataOptions = data.customers.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          });

          const itemsData = options.concat(dataOptions);
          setOptions(itemsData);
          setNextPageLoading(false);
          setHasNextPage(itemsData.length < data.count);
          setPage(page);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const loadWithSearchOptions = (page: number) => {
    try {
      const pagination = {
        page: page,
        size: 15,
      };
      setNextPageLoading(true);
      fetch(`${baseURL}Customer/GetCustomersSearchWithCustomerNamePaging/${searchValue},${pagination.page},${pagination.size}`)
        .then((res) => res.json())
        .then((data) => {
          const dataOptions = data.customers.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          });

          const itemsData = options.concat(dataOptions);
          setOptions(itemsData);
          setNextPageLoading(false);
          setHasNextPage(itemsData.length < data.count);
          setPage(page);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "24px",
      }}
    >
      {addNewCustomer ? (
        <AddNewCustomer
          handleCustomer={(customerId) => setCustomerId(customerId)}
          closeNewCustomer={() => {
            setAddNewCustomer(false);
            setHideToolbar(false);
          }}
          customerId={""}
          isFromDriver={false}
          returnDate={returnDate}
          returnTime={returnTime}
        />
      ) : (
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
              Customer Information
            </h5>

            <h5 style={{ paddingBottom: "0px", marginBlock: "0px", color: "red" }}>
              Outstanding Balance: ${Number(outstandingBalance)?.toFixed(2)}
            </h5>
          </div>
          <hr />

          <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}>
            <CustomerSelectWrapper
              hasNextPage={hasNextPage}
              isNextPageLoading={isNextPageLoading}
              setSearchValue={setSearchValue}
              options={options}
              setPage={setPage}
              loadNextPage={loadNextPage}
              isSearching={isSearching}
              setIsSearching={setIsSearching}
              onChange={(selectedOption: any) => setCustomerId(selectedOption?.value)}
            />

            <p
              style={{
                fontSize: '14px',
                cursor: 'pointer',
                display: 'inline-block',
                marginLeft: '8px',
              }}
              onClick={() => {
                if (!isCompletedOrCancelled) {
                  setAddNewCustomer(true);
                  setHideToolbar(false);
                }
              }}
            >
              or create a new one
            </p>
          </div>

          <div
            style={{
              width: "100%",
              marginRight: "3%",
            }}
          >
            {customerId && (
              <>
                <h5
                  style={{
                    paddingBottom: "0px",
                    marginBottom: "0px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {

                    setIsCutomerInformation(!isCustomerInformation)

                  }
                  }
                >
                  {isCustomerInformation ? (
                    <ExpandMore sx={{ fontSize: "20px" }} />
                  ) : (
                    <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                  )}{" "}
                  Customer Information
                </h5>
                <hr />
              </>
            )}

            <form onSubmit={(e) => handleSubmit(e)}>
              {customerId && isCustomerInformation && (
                <div style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                >

                  <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    marginTop: "10px"
                  }}>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="firstName"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        First Name
                        <br />
                        <input
                          type="text"
                          name="firstName"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="lastName"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Last Name
                        <br />
                        <input
                          type="text"
                          name="lastName"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                  </div>

                  <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    marginTop: "10px"
                  }}>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="emailAddress"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Email Address
                        <br />
                        <input
                          type="email"
                          name="emailAddress"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="phoneNumber"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Phone Number
                        <br />
                        <input
                          type="tel"
                          name="phoneNumber"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                  </div>

                  <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    marginTop: "10px"
                  }}>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="Street"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Street
                        <br />
                        <input
                          type="text"
                          name="Street"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={Street}
                          onChange={(e) => setStreet(e.target.value)}
                        />
                      </label>
                    </div>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="street2"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Street 2
                        <br />
                        <input
                          type="text"
                          name="Street2"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={street2}
                          onChange={(e) => setStreet2(e.target.value)}
                        />
                      </label>
                    </div>

                  </div>

                  <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    marginTop: "10px"
                  }}>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="city"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        City
                        <br />
                        <input
                          type="text"
                          name="city"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </label>
                    </div>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="state"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        State
                        <br />
                        <input
                          type="text"
                          name="state"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </label>
                    </div>

                  </div>

                  <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    marginTop: "10px"
                  }}>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="zip"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Zip
                        <br />
                        <input
                          type="text"
                          name="zip"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                        />
                      </label>
                    </div>

                    <div style={{ width: "50%" }}>
                      <label
                        htmlFor="country"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Country
                        <br />
                        <input
                          type="text"
                          name="country"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </label>
                    </div>

                  </div>

                  <div style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    marginTop: "10px"
                  }}>

                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                      }}
                    >
                      <div style={{ width: "33.33%" }}>
                        <label
                          htmlFor="birthday"
                          style={{ fontSize: "12px", fontStyle: "bold" }}
                        >
                          Birthday
                          <br />
                          <Select
                            styles={{
                              control: (base) => ({ ...base, fontSize: "12px" }),
                            }}
                            className="basic-single"
                            classNamePrefix="select"
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isRtl={false}
                            isSearchable={false}
                            name="birthday"
                            value={monthNameOptions.find(
                              (item) => item.value === month
                            )}
                            onChange={(month: any) =>
                              setMonth(month ? month.value : "")
                            }
                            options={monthNameOptions}
                          />
                        </label>
                      </div>

                      <div style={{ width: "33.33%" }}>
                        <br />
                        <Select
                          styles={{
                            control: (base) => ({ ...base, fontSize: "12px" }),
                          }}
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={false}
                          name="birthday"
                          value={daysOptions.find(
                            (item: any) => item.value === parseInt(day)
                          )}
                          onChange={(day: any) =>
                            setDay(day ? day.value : "")
                          }
                          options={daysOptions}
                        />
                      </div>

                      <div style={{ width: "33.33%" }}>
                        <br />
                        <Select
                          styles={{
                            control: (base) => ({ ...base, fontSize: "12px" }),
                          }}
                          className="basic-single"
                          classNamePrefix="select"
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isRtl={false}
                          isSearchable={false}
                          name="birthday"
                          value={yearOptions.find(
                            (item: any) => item.value === parseInt(year)
                          )}
                          onChange={(year: any) =>
                            setYear(year ? year.value : "")
                          }
                          options={yearOptions}
                        />
                      </div>
                    </div>

                  </div>

                </div>

              )}

              {customerId && (
                <>
                  <h5
                    style={{
                      paddingBottom: "0px",
                      marginBottom: "0px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {

                      setIsExtraInformation(!isExtraInformation)
                    }}
                  >
                    {isExtraInformation ? (
                      <ExpandMore sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                    )}{" "}
                    Extra Information
                  </h5>
                  <hr />

                  {isExtraInformation && (
                    <>
                      <label style={{ fontSize: "12px", fontStyle: "bold" }}>
                        Driver license
                      </label>

                      <table>
                        <tbody>
                          <tr>
                            <th style={{ width: "15%" }}>Type</th>
                            <th style={{ width: "25%" }}>ID Number</th>
                            <th style={{ width: "15%" }}>Issued On</th>
                            <th style={{ width: "15%" }}>Exp. Date</th>
                            <th style={{ width: "35%" }}>File</th>
                          </tr>

                          {extraRow?.map((row: ExtraInformation) => {
                            return (
                              <AddIdentification
                                key={row.id}
                                itemIndex={row.id}
                                birthDay={new Date(`${year}-${month}-${day}`).toDateString()}
                                handleDeleteExtraRow={(index) =>
                                  handleDeleteExtraRow(index)
                                }
                                row={row}
                                changeOnExtraValue={(row) =>
                                  changeOnExtraValue(row)
                                }
                              />
                            );
                          })}
                        </tbody>
                      </table>
                      <div
                        style={{
                          height: "40px",
                          borderStyle: "none dashed dashed dashed",
                          borderWidth: "1px",
                          borderColor: "#D5D4D8",
                          marginTop: "-10px",
                          paddingTop: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          if (!isCompletedOrCancelled)
                            handleAddExtraRow()
                        }}
                      >
                        <p style={{ fontSize: "12px", textAlign: "center" }}>
                          <AddOutlined
                            sx={{
                              fontSize: "12px",
                              textAlign: "center",
                              paddingTop: "3px",
                            }}
                          />
                          Add another Identification
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}

              <div style={{ marginTop: "10px" }}>
                <label
                  htmlFor="localAddress"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Local Address
                  <br />
                  <input
                    type="text"
                    name="localAddress"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={localAddress}
                    onChange={(e) => setLocalAddress(e.target.value)}
                  />
                </label>
              </div>

              {reservation && (
                <div>
                  <h5
                    style={{
                      paddingBottom: "0px",
                      marginBottom: "0px",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsHistory(!isHistory)}
                  >
                    {isHistory ? (
                      <ExpandMore sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowRight sx={{ fontSize: "20px" }} />
                    )}{" "}
                    Reservations history
                  </h5>
                  <hr />

                  {isHistory && reservationHistoryLoaded && (
                    <>
                      <table style={{ borderCollapse: "collapse", fontSize: "10px" }}>
                        <thead>
                          <tr style={{ textAlign: "left", borderBottom: "2px solid #ccc" }}>
                            <th style={{ padding: "5px", borderRight: "1px solid #ddd" }}>#</th>
                            <th style={{ padding: "5px", borderRight: "1px solid #ddd" }}>Pickup Date</th>
                            <th style={{ padding: "5px", borderRight: "1px solid #ddd" }}>Return Date</th>
                            <th style={{ padding: "5px", borderRight: "1px solid #ddd" }}>Vehicle Class</th>
                            <th style={{ padding: "5px", borderRight: "1px solid #ddd" }}>Status</th>
                            <th style={{ padding: "5px", borderRight: "1px solid #ddd" }}>Notes</th>
                            <th style={{ padding: "5px", borderRight: "1px solid #ddd" }}>Selected Discounts</th>
                            <th style={{ padding: "5px", borderRight: "1px solid #ddd" }}>Outstanding Balance</th>
                            <th style={{ padding: "5px" }}>Total Price</th>
                          </tr>
                        </thead>
                        <tbody>


                          {historyValue.map((item: any, index: number) => (
                            <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                              <td style={{
                                padding: "5px", borderRight: "1px solid #ddd", textDecoration: "underline",
                                color: "blue",
                                cursor: "pointer"
                              }} onClick={() => {
                                navigate("/carrental/reservations/editreservation", {
                                  state: item.id,
                                })
                              }}><p>{item.bookingNumber}</p></td>
                              <td style={{ padding: "5px", borderRight: "1px solid #ddd" }}><p>{format(
                                new Date(item.pickupDate),
                                "MM/dd/yyyy"
                              )}</p></td>
                              <td style={{ padding: "5px", borderRight: "1px solid #ddd" }}><p>{format(
                                new Date(item.returenDate),
                                "MM/dd/yyyy"
                              )}</p></td>
                              <td style={{ padding: "5px", borderRight: "1px solid #ddd" }}><p>{item.vehicleClassName}</p></td>
                              <td style={{ padding: "5px", borderRight: "1px solid #ddd" }}>
                                {item.status}
                              </td>
                              <td style={{ padding: "5px", borderRight: "1px solid #ddd" }}>
                                <ul style={{
                                  padding: '0 13px',
                                  margin: 0,
                                }}>
                                  {item.notes.map((note: string) => {
                                    return (
                                      <li>
                                        {note}
                                      </li>

                                    )
                                  })}
                                </ul>
                              </td>
                              <td style={{ padding: "5px", borderRight: "1px solid #ddd" }}>{item.selectedDiscounts.map((discount: string) => {
                                return discount + ","
                              })}</td>
                              <td style={{ padding: "5px", borderRight: "1px solid #ddd" }}>{parseFloat(item.outstandingBalance).toFixed(2)}</td>
                              <td style={{ padding: "5px" }}>{item.totalPrice}</td>
                            </tr>
                          ))}

                        </tbody>
                      </table>

                    </>
                  )}
                  {isHistory && !reservationHistoryLoaded && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "10px",
                        width: "100%",
                        justifyContent: "center"
                      }}
                    >
                      <div style={{
                        width: "30px",
                        height: "30px"
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="#FFFFFF" width="100%" height="100%" /><radialGradient id="a9" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#295B7E"></stop><stop offset=".3" stop-color="#295B7E" stop-opacity=".9"></stop><stop offset=".6" stop-color="#295B7E" stop-opacity=".6"></stop><stop offset=".8" stop-color="#295B7E" stop-opacity=".3"></stop><stop offset="1" stop-color="#295B7E" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a9)" stroke-width="18" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#295B7E" stroke-width="18" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
                      </div>

                    </div>
                  )}
                </div>
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                    borderRadius: "0",
                    border: "none",
                    textTransform: "capitalize",
                  }}
                  type="submit"
                >
                  Next Step
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );

}