import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../../../configs/colorConfigs";

import {
    ContentState,
    EditorState,
    convertFromHTML,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import baseURL from "../../../../configs/api";
import PleaseWaitPopup from "../../../PleaseWaitPopup";
import { validateMin } from "../../../../utils/validateMin";

type Props = {
    state: string;
    setState: (value: string) => void;
    zip: string;
    setZip: (value: string) => void;
}

export default function GeneralSettings({ state, setState, zip, setZip }: Props) {
    const [id, setId] = useState<string>("");

    const [collectZIPCode, setCollectZIPCode,] = useState<boolean>(true);

    const [prohibitTheUsageofPrePaidCards, setProhibitTheUsageofPrePaidCards] = useState<boolean>(true);

    const [prohibitUsageOfDebitCardsforSaveCreditCardFunctionality,
        setProhibitUsageOfDebitCardsforSaveCreditCardFunctionality] = useState<boolean>(true);

    const [sendFailedTransactionEmail, setSendFailedTransactionEmail] = useState<boolean>(true);

    const [allowCaptureforHigherAmountsThanAuthorized,
        setAllowCaptureforHigherAmountsThanAuthorized] = useState<boolean>(true);

    const [disableManualUpdateOfTransactionsToPaid,
        setDisableManualUpdateOfTransactionsToPaid] = useState<boolean>(true);

    const [disableManualUpdateofTransactionsToFailed,
        setDisableManualUpdateofTransactionsToFailed] = useState<boolean>(true);

    const [paperSizeforPaymentReceipts, setPaperSizeforPaymentReceipts] = useState<string>("A0");

    const [maxDaysToKeepAuthorizations, setMaxDaysToKeepAuthorizations] =
        useState<string>("");
    const [failedTransactionEmailReceiver, setFailedTransactionEmailReceiver] =
        useState<string>("");

    const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);

    const [emailContentForRecurringChargesWith3DS,
        setEmailContentForRecurringChargesWith3DS] = useState<string>("");

    const [emailSubjectforRecurringChargesWith3DS,
        setEmailSubjectforRecurringChargesWith3DS] = useState<string>("");


    useEffect(() => {
        axios
            .get(`${baseURL}paymentGatewaySetting`)
            .then((response) => {
                setId(response.data.id);
                setMaxDaysToKeepAuthorizations(
                    response.data.maxDaysToKeepAuthorizations
                );
                setCollectZIPCode(response.data.collectZIPCode);
                setProhibitTheUsageofPrePaidCards(response.data.prohibitTheUsageofPrePaidCards);
                setProhibitUsageOfDebitCardsforSaveCreditCardFunctionality
                    (response.data.prohibitUsageOfDebitCardsforSaveCreditCardFunctionality);
                setSendFailedTransactionEmail(response.data.sendFailedTransactionEmail);
                setFailedTransactionEmailReceiver(response.data.failedTransactionEmailReceiver);
                setEmailSubjectforRecurringChargesWith3DS(response.data.emailSubjectforRecurringChargesWith3DS);
                setEmailContentForRecurringChargesWith3DS(response.data.emailContentForRecurringChargesWith3DS);
                setPaperSizeforPaymentReceipts(response.data.paperSizeforPaymentReceipts);
                setAllowCaptureforHigherAmountsThanAuthorized
                    (response.data.allowCaptureforHigherAmountsThanAuthorized);
                setDisableManualUpdateOfTransactionsToPaid
                    (response.data.disableManualUpdateOfTransactionsToPaid);
                setDisableManualUpdateofTransactionsToFailed
                    (response.data.disableManualUpdateofTransactionsToFailed);
                setState(response.data.state);
                setZip(response.data.zip);

                setEditorState(
                    EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(response.data.emailContentForRecurringChargesWith3DS).contentBlocks,
                            convertFromHTML(response.data.emailContentForRecurringChargesWith3DS).entityMap
                        )
                    )
                );

                setPleaseWaitPopup(false);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setPleaseWaitPopup(true);
        await axios
            .put(
                `${baseURL}paymentGatewaySetting`,
                JSON.stringify({
                    id,
                    maxDaysToKeepAuthorizations,
                    collectZIPCode,
                    prohibitTheUsageofPrePaidCards,
                    prohibitUsageOfDebitCardsforSaveCreditCardFunctionality,
                    sendFailedTransactionEmail,
                    failedTransactionEmailReceiver,
                    emailSubjectforRecurringChargesWith3DS,
                    emailContentForRecurringChargesWith3DS,
                    paperSizeforPaymentReceipts,
                    allowCaptureforHigherAmountsThanAuthorized,
                    disableManualUpdateOfTransactionsToPaid,
                    disableManualUpdateofTransactionsToFailed,
                    state,
                    zip,
                }),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then(() => setPleaseWaitPopup(false))
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );

    useEffect(() => {
        setEmailContentForRecurringChargesWith3DS(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);

    return (
        <div>
            <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "24px 26px",
                }}
            >
                <div
                    style={{
                        width: "85%",
                        marginRight: "3%",
                    }}
                >
                    <h5 style={{ paddingBottom: "0px", marginBlock: "0px" }}>
                        General Settings
                    </h5>
                    <hr />

                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "50%",
                                    marginRight: "3%",
                                    paddingLeft: "16px",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="maxDaysToKeepAuthorizations"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Max Days to Keep Authorizations?
                                        <br />
                                        <input
                                            type="number"
                                            min={0}
                                            name="maxDaysToKeepAuthorizations"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={maxDaysToKeepAuthorizations}
                                            onChange={(e) =>
                                                setMaxDaysToKeepAuthorizations(validateMin(e.target.value, 0).toString())
                                            }
                                            required
                                        />
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Collect ZIP Code
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="collectZIPCode"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                collectZIPCode ? true : false
                                            }
                                            onChange={(e) =>
                                                setCollectZIPCode(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="collectZIPCode"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                collectZIPCode ? false : true
                                            }
                                            onChange={(e) =>
                                                setCollectZIPCode(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Prohibit the Usage of Pre-Paid Cards?
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="prohibitTheUsageofPrePaidCards"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                prohibitTheUsageofPrePaidCards ? true : false
                                            }
                                            onChange={(e) =>
                                                setProhibitTheUsageofPrePaidCards(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="prohibitTheUsageofPrePaidCards"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                prohibitTheUsageofPrePaidCards ? false : true
                                            }
                                            onChange={(e) =>
                                                setProhibitTheUsageofPrePaidCards(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                            </div>

                            <div
                                style={{
                                    width: "50%",
                                }}
                            >
                                <div style={{ marginTop: "10px", visibility: "hidden" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Use Amounts in Repair Orders
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="useAmountsInRepairOrders"
                                            style={{ fontSize: "12px" }}
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="useAmountsInRepairOrders"
                                            style={{ fontSize: "12px" }}
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "15px", visibility: "hidden" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Include Transaction Fees in Payments?
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="useAmountsInRepairOrders"
                                            style={{ fontSize: "12px" }}
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="useAmountsInRepairOrders"
                                            style={{ fontSize: "12px" }}
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Prohibit the Usage of Debit Cards for Save Credit Card Functionality
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="prohibitUsageOfDebitCardsforSaveCreditCardFunctionality"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                prohibitUsageOfDebitCardsforSaveCreditCardFunctionality ? true : false
                                            }
                                            onChange={(e) =>
                                                setProhibitUsageOfDebitCardsforSaveCreditCardFunctionality(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="prohibitUsageOfDebitCardsforSaveCreditCardFunctionality"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                prohibitUsageOfDebitCardsforSaveCreditCardFunctionality ? false : true
                                            }
                                            onChange={(e) =>
                                                setProhibitUsageOfDebitCardsforSaveCreditCardFunctionality(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                            </div>
                        </div>

                        <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                            Email Settings
                        </h5>
                        <hr />

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "50%",
                                    marginRight: "3%",
                                    paddingLeft: "16px",
                                }}
                            >

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Send Failed Transaction Email
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="sendFailedTransactionEmail"
                                            style={{ fontSize: "12px" }}
                                            checked={sendFailedTransactionEmail ? true : false}
                                            onChange={(e) =>
                                                setSendFailedTransactionEmail(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="sendFailedTransactionEmail"
                                            style={{ fontSize: "12px" }}
                                            checked={sendFailedTransactionEmail ? false : true}
                                            onChange={(e) =>
                                                setSendFailedTransactionEmail(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>
                            </div>

                            <div
                                style={{
                                    width: "50%",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="tax"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Failed Transaction Email Receiver
                                        <br />
                                        <input
                                            type="text"
                                            name="tax"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={failedTransactionEmailReceiver}
                                            onChange={(e) =>
                                                setFailedTransactionEmailReceiver(e.target.value)
                                            }
                                            required
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                marginTop: "10px",
                                width: "100%",
                                paddingLeft: "16px",
                            }}
                        >
                            <label
                                htmlFor="emailSubject"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Email Subject For Recurring Charges With 3DS
                                <br />
                                <input
                                    type="text"
                                    name="emailSubjectforRecurringChargesWith3DS"
                                    style={{
                                        width: "100%",
                                        fontSize: "14px",
                                        paddingBlock: "5px",
                                    }}
                                    value={emailSubjectforRecurringChargesWith3DS}
                                    onChange={(e) =>
                                        setEmailSubjectforRecurringChargesWith3DS(e.target.value)
                                    }
                                    required
                                />
                            </label>
                        </div>

                        <div style={{
                            marginTop: "10px",
                            width: "100%",
                            paddingLeft: "16px",
                        }}>
                            <label
                                htmlFor="odemeter"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                                Email Content For Recurring Charges With 3DS
                            </label>
                            <br />

                            <Editor
                                editorState={editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={setEditorState}
                                toolbar={{
                                    inline: { inDropdown: true },
                                    list: { inDropdown: true },
                                    textAlign: { inDropdown: true },
                                    link: { inDropdown: true },
                                    history: { inDropdown: true },
                                }}
                            />
                        </div>

                        <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
                            Advanced
                        </h5>
                        <hr />

                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <div
                                style={{
                                    width: "50%",
                                    marginRight: "3%",
                                    paddingLeft: "16px",
                                }}
                            >
                                <div style={{ marginTop: "10px" }}>
                                    <label
                                        htmlFor="paperSizeforPaymentReceipts"
                                        style={{ fontSize: "12px", fontWeight: "bold" }}
                                    >
                                        Paper Size For Payment Receipts
                                        <br />
                                        <select
                                            name="paperSizeforPaymentReceipts"
                                            style={{
                                                width: "100%",
                                                fontSize: "14px",
                                                paddingBlock: "5px",
                                            }}
                                            value={paperSizeforPaymentReceipts}
                                            onChange={(e) => setPaperSizeforPaymentReceipts(e.target.value)}
                                        >
                                            <option value="A0">A0</option>
                                            <option value="A1">A1</option>
                                            <option value="A2">A2</option>
                                            <option value="A3">A3</option>
                                            <option value="A4">A4</option>
                                            <option value="A5">A5</option>
                                            <option value="A6">A6</option>
                                            <option value="A7">A7</option>
                                            <option value="A8">A8</option>
                                            <option value="A9">A9</option>
                                            <option value="B0">B0</option>
                                            <option value="B1">B1</option>
                                            <option value="B2">B2</option>
                                            <option value="B3">B3</option>
                                            <option value="B4">B4</option>
                                            <option value="B5">B5</option>
                                            <option value="B6">B6</option>
                                            <option value="B7">B7</option>
                                            <option value="B8">B8</option>
                                            <option value="B9">B9</option>
                                            <option value="B10">B10</option>
                                            <option value="C5E">C5E</option>
                                            <option value="Comm10E">Comm10E</option>
                                            <option value="DLE">DLE</option>
                                            <option value="Executive">Executive</option>
                                            <option value="Folio">Folio</option>
                                            <option value="Ledger">Ledger</option>
                                            <option value="Legal">Legal</option>
                                            <option value="Letter">Letter</option>
                                            <option value="Ledger">Tabloid</option>
                                        </select>
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Allow Capture For Higher Amounts than Authorized?
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="allowCaptureforHigherAmountsThanAuthorized"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                allowCaptureforHigherAmountsThanAuthorized ? true : false
                                            }
                                            onChange={(e) =>
                                                setAllowCaptureforHigherAmountsThanAuthorized(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="allowCaptureforHigherAmountsThanAuthorized"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                allowCaptureforHigherAmountsThanAuthorized ? false : true
                                            }
                                            onChange={(e) =>
                                                setAllowCaptureforHigherAmountsThanAuthorized(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Disable Manual Update of Transaction to Paid
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="disableManualUpdateOfTransactionsToPaid"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                disableManualUpdateOfTransactionsToPaid ? true : false
                                            }
                                            onChange={(e) =>
                                                setDisableManualUpdateOfTransactionsToPaid(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="disableManualUpdateOfTransactionsToPaid"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                disableManualUpdateOfTransactionsToPaid ? false : true
                                            }
                                            onChange={(e) =>
                                                setDisableManualUpdateOfTransactionsToPaid(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>

                            </div>

                            <div
                                style={{
                                    width: "50%",
                                }}
                            >
                                <div style={{ marginTop: "10px", visibility: "hidden" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Use Amounts in Repair Orders
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="useAmountsInRepairOrders"
                                            style={{ fontSize: "12px" }}
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="useAmountsInRepairOrders"
                                            style={{ fontSize: "12px" }}
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "15px", visibility: "hidden" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Include Transaction Fees in Payments?
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="useAmountsInRepairOrders"
                                            style={{ fontSize: "12px" }}
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="useAmountsInRepairOrders"
                                            style={{ fontSize: "12px" }}
                                        />
                                        No
                                    </label>
                                </div>

                                <div style={{ marginTop: "20px" }}>
                                    <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                                        Disable Manual Update of Transaction to Failed
                                    </label>{" "}
                                    <br />
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="Yes"
                                            name="disableManualUpdateofTransactionsToFailed"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                disableManualUpdateofTransactionsToFailed ? true : false
                                            }
                                            onChange={(e) =>
                                                setDisableManualUpdateofTransactionsToFailed(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        Yes
                                    </label>
                                    <label style={{ fontSize: "12px" }}>
                                        <input
                                            type="radio"
                                            value="No"
                                            name="disableManualUpdateofTransactionsToFailed"
                                            style={{ fontSize: "12px" }}
                                            checked={
                                                disableManualUpdateofTransactionsToFailed ? false : true
                                            }
                                            onChange={(e) =>
                                                setDisableManualUpdateofTransactionsToFailed(
                                                    e.target.value === "Yes" ? true : false
                                                )
                                            }
                                        />
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: colorConfigs.topbar.bg,
                                color: "white",
                                borderRadius: "0",
                                border: "none",
                                textTransform: "capitalize",
                                marginLeft: "16px",
                                marginTop: "40px",
                                "&:hover": {
                                    backgroundColor: colorConfigs.topbar.bg,
                                    color: "white",
                                },
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                    </form>
                </div>
            </div >

            <PleaseWaitPopup pleaseWaitPopup={pleaseWaitPopup} />
        </div >
    );
};
