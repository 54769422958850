import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Webcam from "react-webcam";
import BusinessIcon from "@mui/icons-material/Business";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddIcon from "@mui/icons-material/Add";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import colorConfigs from "../../../configs/colorConfigs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import baseURL from "../../../configs/api";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import axois from "../../../configs/axois";
import { handleCapture } from "../../../utils/covertTheBase64FormatIntoImage";
import useAuth from "../../../components/hooks/useAuth";
import { validateMin } from "../../../utils/validateMin";

const fuelLevelOptions: any = [];
for (let i = 0; i <= 8; i++) {
  fuelLevelOptions.push(<option key={i} value={i}>{i}</option>);
}

export const AddRepairOrder = () => {
  const { auth }: any = useAuth();
  const [vehicleOptions, setVehicleOption] = React.useState([]);

  const [showWebcam, setShowWebcam] = useState<boolean>(false);
  const [filePath, setFilePath] = useState<string[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [isImageSaved, setIsImageSaved] = useState<boolean>(false);
  const [imagePopup, setImagePopup] = useState<boolean>(false);
  const [pleaseWaitPopup, setPleaseWaitPopup] = useState<boolean>(false);
  const [primaryKey, setPrimaryKey] = useState<number>();

  useEffect(() => {
    axois
      .get(`${baseURL}vehicles`)
      .then((response) => {
        setVehicleOption(
          response.data.map((item: any) => {
            return { value: item.id, label: item.name };
          })
        );

        setVehicle(response.data[0].id);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [mechanicOption, setMechanicOption] = useState([]);

  useEffect(() => {
    axois
      .get(`${baseURL}users`)
      .then((response) => {
        setMechanicOption(
          response.data.map((item: any) => {
            return {
              value: item.id,
              label: item.firstName + " " + item.lastName,
            };
          })
        );

        setMechanic(response.data[0].id);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axois
      .get(`${baseURL}vehicleRepairOrder`)
      .then((response) => {
        setPrimaryKey(parseInt(response.data[0].id) + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [vehicle, setVehicle] = useState<string>("");
  const [maintenanceType, setMaintenanceType] = useState<string>("");
  const [totalInParts, setTotalInParts] = useState(0);
  const [totalInLabour, setTotalInLabour] = useState(0);
  const [damages, setDamages] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [dateOut, setDateOut] = useState<string>("");
  const [timeOut, setTimeOut] = useState<string>("T00:00:00");
  const [dateDue, setDateDue] = useState<string>("");
  const [timeDue, setTimeDue] = useState<string>("T00:00:00");
  const [mechanic, setMechanic] = useState<string>("");
  const [mechanicComments, setMechanicComments] = useState<string>("");

  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setPleaseWaitPopup(true);
    axios
      .post(
        `${baseURL}vehicleRepairOrder`,
        JSON.stringify({
          vehicleId: vehicle,
          maintenanceType,
          totalInParts,
          totalInLabour,
          status,
          notes,
          dateOut:
            timeOut === "T00:00:00"
              ? dateOut + timeOut
              : dateOut + ("T" + timeOut),
          dateDue:
            timeDue === "T00:00:00"
              ? dateDue + timeDue
              : dateDue + ("T" + timeDue),
          mechanic,
          comments: mechanicComments,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        setIsImageSaved(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (isImageSaved) {
      if (filePath.length !== 0) {
        filePath.forEach((item: string, index: number) => {
          axios
            .post(
              `${baseURL}fileUploadData`,
              JSON.stringify({
                attributeName: "VehicleRepairOrder",
                attributeId: primaryKey,
                fileName: item?.replace(
                  `${baseURL}repairOrderFile/Get?name=`,
                  ""
                ),
                tabName: "VehicleRepairOrder",
                userId: auth?.id ?? 0,
                createdDate: new Date(),
              }),
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
              }
            )
            .then(() => {
              if (filePath.length - 1 === index) {
                setPleaseWaitPopup(false);
                navigate(-1);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
      } else {
        navigate(-1);
      }
      setIsImageSaved(false);
    }
  }, [isImageSaved]);

  const webRef = useRef(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const onFileChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPleaseWaitPopup(true);
    const selectedFiles = e.target.files as FileList;

    if (selectedFiles.length !== 0) {
      const formData = new FormData();
      formData.append("ImageFile", selectedFiles[0]);

      axios
        .post(`${baseURL}repairOrderFile/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        })
        .then((response) => {
          const newFilePath: string =
            `${baseURL}repairOrderFile/Get?name=` +
            response.data;
          setFilePath((prevPath: string[]) => [...prevPath, newFilePath]);
          setPleaseWaitPopup(false);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setPleaseWaitPopup(false);
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    setNotes(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }, [editorState]);

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <BusinessIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Repair Orders / Add Repair Order
          </Typography>
        </Box>

        <Button
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "black",
            borderRadius: "0",
            border: "none",
          }}
        >
          <LocalPrintshopOutlinedIcon />
        </Button>
      </Toolbar>

      <hr style={{ borderBottom: "1px solid #8c8c8c", marginBlock: "0px" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            General Information
          </h3>
          <hr />

          <form onSubmit={(e) => handleSubmit(e)}>
            <label
              htmlFor="vehicle"
              style={{ fontSize: "12px", fontStyle: "bold" }}
            >
              Vehicle
              <br />
              <Select
                styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                className="basic-single"
                classNamePrefix="select"
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                isSearchable={false}
                name="vehicle"
                value={vehicleOptions.find(
                  (item: any) => item.value === vehicle
                )}
                onChange={(vehicle: any) =>
                  setVehicle(vehicle ? vehicle.value : "")
                }
                options={vehicleOptions}
                required
              />
            </label>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="maintenanceType"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Maintenance Type
                    <br />
                    <select
                      name="maintenanceType"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={maintenanceType}
                      onChange={(e) => setMaintenanceType(e.target.value)}
                    >
                      <option value="VAN">VAN</option>
                      <option value="SUV">SUV</option>
                    </select>
                  </label>
                </div>

                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "50%", marginRight: "3%" }}>
                    <label
                      htmlFor="totalInParts"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Total In Parts
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="totalInParts"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={totalInParts}
                        onChange={(e) =>
                          setTotalInParts(validateMin(e.target.value, 0))
                        }
                        required
                      />
                    </label>
                  </div>

                  <div style={{ width: "50%" }}>
                    <label
                      htmlFor="totalInLabor"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Total In Labor
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="totalInLabor"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={totalInLabour}
                        onChange={(e) =>
                          setTotalInLabour(validateMin(e.target.value, 0))
                        }
                        required
                      />
                    </label>
                  </div>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="image"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    Images
                    <br />
                    {filePath.length !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        {filePath.map((item: string, outerIndex: number) => {
                          return (
                            <>
                              <img
                                src={item}
                                width={40}
                                height={40}
                                alt="repairOrder"
                                onClick={() => {
                                  setFileIndex(outerIndex);
                                  setImagePopup(true);
                                }}
                              />
                              <RemoveCircleIcon
                                sx={{
                                  fontSize: "14px",
                                  color: colorConfigs.topbar.bg,
                                  fontWeight: "bold",
                                  "&: hover": {
                                    color: "red",
                                  },
                                }}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to delete this image?"
                                    )
                                  ) {
                                    let newFileSet: string[] = filePath.filter(
                                      (_, index: number) => index !== outerIndex
                                    );
                                    setFilePath(() => newFileSet);
                                  }
                                }}
                              />
                            </>
                          );
                        })}
                      </div>
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      ref={inputFileRef}
                      onChangeCapture={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "8px",
                        marginTop: "8px",
                      }}
                    >
                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={(e) => {
                          if (inputFileRef.current !== null) {
                            inputFileRef.current.click();
                          }
                        }}
                      >
                        {" "}
                        <AddIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                        Add Image
                      </p>

                      <p
                        style={{
                          border: "1px dashed black",
                          borderRadius: "3px",
                          padding: "3px 5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                          backgroundColor: "white",
                        }}
                        onClick={() => setShowWebcam(true)}
                      >
                        {" "}
                        <LocalSeeIcon
                          style={{
                            fontSize: "14px",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        />
                      </p>
                    </div>
                  </label>
                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ visibility: "hidden" }}>
                  <label
                    htmlFor=" vehicleKey"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Vehicle Key
                    <br />
                    <input
                      type="text"
                      name="vehicleKey"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                    />
                  </label>
                </div>

                <div style={{ marginTop: "20px", visibility: "hidden" }}>
                  <label
                    htmlFor="vehicleMode"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Damages
                    <br />
                    <input
                      type="text"
                      name="vehicle"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={damages}
                      onChange={(e) => setDamages(e.target.value)}
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="vehiclSecondaryClass"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Status
                    <br />
                    <select
                      name="vehicleType"
                      id="cars"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Skipped">Skipped</option>
                      <option value="Done">Done</option>
                    </select>
                  </label>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "10px" }}>
              <label
                htmlFor="odemeter"
                style={{ fontSize: "12px", fontStyle: "bold" }}
              >
                Notes
              </label>
              <br />

              <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={setEditorState}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />
            </div>

            <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Vehicle Out Information
            </h3>
            <hr />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="dateOut"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Date Out
                  <br />
                  <input
                    type="date"
                    data-date-format="MM-DD-YYYY"
                    name="dateOut"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={dateOut}
                    onChange={(e) => setDateOut(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="timeOut"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Time Out
                  <br />
                  <input
                    type="time"
                    name="timeOut"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={timeOut}
                    onChange={(e) => setTimeOut(e.target.value)}
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="dateDue"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Date Due
                  <br />
                  <input
                    type="date"
                    data-date-format="MM-DD-YYYY"
                    name="dateDue"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={dateDue}
                    onChange={(e) => setDateDue(e.target.value)}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "25%", marginRight: "3%" }}>
                <label
                  htmlFor="timeDue"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Time Due
                  <br />
                  <input
                    type="time"
                    name="timeDue"
                    style={{
                      width: "100%",
                      fontSize: "14px",
                      paddingBlock: "5px",
                    }}
                    value={timeDue}
                    onChange={(e) => setTimeDue(e.target.value)}
                  />
                </label>
              </div>
            </div>

            <h3 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
              Internal Management
            </h3>
            <hr />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ width: "50%", marginRight: "3%" }}>
                <label
                  htmlFor="mechnaic"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Mechanic
                  <br />
                  <Select
                    styles={{ menu: (base) => ({ ...base, fontSize: "12px" }) }}
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={false}
                    name="mechanic"
                    value={mechanicOption.find(
                      (item: any) => item.value === mechanic
                    )}
                    onChange={(mechanic: any) =>
                      setMechanic(mechanic ? mechanic.value : "")
                    }
                    options={mechanicOption}
                    required
                  />
                </label>
              </div>

              <div style={{ width: "100%", marginRight: "3%" }}>
                <label
                  htmlFor="timeDue"
                  style={{ fontSize: "12px", fontStyle: "bold" }}
                >
                  Comments
                </label>
                <br />

                <textarea
                  style={{ width: "100%", height: "15%", resize: "none" }}
                  value={mechanicComments}
                  onChange={(e) => setMechanicComments(e.target.value)}
                />
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        open={showWebcam}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-16%",
          },
        }}
      >
        <DialogContent sx={{ padding: "0px" }}>
          <Webcam
            width={480}
            height={320}
            ref={webRef}
            screenshotFormat="image/png"
            audio={false}
          />

          <div
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBlock: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              backgroundColor: "#eeeeee",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: colorConfigs.sidebar.color,
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: "#e0dee0",
                    color: colorConfigs.sidebar.color,
                  },
                }}
                onClick={() => setShowWebcam(false)}
              >
                Cancel
              </Button>
            </div>

            <div
              style={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                  fontSize: "12px",
                  "&: hover": {
                    backgroundColor: colorConfigs.topbar.bg,
                    color: "white",
                  },
                }}
                onClick={() => {
                  if (webRef.current !== null) {
                    //@ts-ignore
                    const imageSrc = webRef.current.getScreenshot();

                    handleCapture(imageSrc, `${baseURL}repairOrderFile/upload`, (imageFilePath: string) => {
                      if (imageFilePath) {
                        setFilePath((prevFileString: string[]) => [
                          ...prevFileString,
                          `${baseURL}repairOrderFile/Get?name=${imageFilePath}`,
                        ]);
                      }
                    })

                    setShowWebcam(false);
                  }
                }}
              >
                Capture an Image
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={imagePopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            marginTop: "-14%",
          },
        }}
      >
        <DialogTitle sx={{ padding: "0px", width: "100%" }}>
          <div
            style={{
              height: "50px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 8px 16px",
            }}
          >
            <p style={{ fontSize: "14px", marginLeft: "5px" }}>
              Repair Order Image
            </p>
            <CloseIcon
              sx={{ fontSize: "14px", marginRight: "5px" }}
              onClick={() => setImagePopup(false)}
            />
          </div>
          <hr style={{ marginBlock: "0px" }} />
          <div
            style={{
              width: "100%",
              height: "5%",
              backgroundColor: colorConfigs.sidebar.bg,
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            Preview
          </div>
          <hr style={{ marginTop: "0px" }} />
        </DialogTitle>

        <DialogContent sx={{ padding: "5px" }}>
          {filePath.length !== 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  left: "0",
                  top: "50%",
                }}
              >
                <ArrowBackIosIcon
                  sx={{
                    cursor: fileIndex !== 0 ? "pointer" : "",
                    opacity: fileIndex === 0 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== 0) {
                      setFileIndex(fileIndex - 1);
                    }
                  }}
                />
              </div>
              <img
                width={700}
                height={350}
                src={filePath[fileIndex]}
                alt="repairOrder"
              />

              <div
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  padding: "10px",
                  right: "0",
                  top: "50%",
                }}
              >
                <ArrowForwardIosIcon
                  sx={{
                    cursor: fileIndex !== filePath.length - 1 ? "pointer" : "",
                    opacity: fileIndex === filePath.length - 1 ? "0.5" : "1",
                  }}
                  onClick={() => {
                    if (fileIndex !== filePath.length - 1) {
                      setFileIndex(fileIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={pleaseWaitPopup}
        maxWidth="md"
        fullScreen={false}
        PaperProps={{
          style: {
            width: "32%",
          },
        }}
      >
        <DialogContent sx={{ padding: "5px" }}>
          <div
            style={{
              padding: "8px",
              backgroundColor: "white",
              color: "black",
              textAlign: "center",
            }}
          >
            Please Wait...
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
