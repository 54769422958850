import React from 'react'
import { ThumbUp, Paid, ThumbDown, DirectionsCar, Computer, ShoppingCartCheckout, ShoppingCartOutlined } from '@mui/icons-material';
import colorConfigs from '../../configs/colorConfigs';
import { Box, Typography } from '@mui/material';

type Props = {
    bookingData: any;
}

const AmountPresentation = ({ bookingData }: Props) => {
    return (
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", padding: "24px 0px 24px 24px", gap: "8px" }}>

            <PresentationBox
                name={"Revenue"}
                balance={bookingData?.revenue}
                icon={<ThumbUp style={{ color: "white" }} />} />

            <PresentationBox
                name={"Amount Paid"}
                balance={bookingData?.amountPaid}
                bgColor={"#31B06E"}
                icon={<Paid style={{ color: "white" }} />} />

            <PresentationBox
                name={"Outstanding Balance"}
                balance={bookingData?.outstandingBalance}
                bgColor={"#FA8564"}
                icon={<ThumbDown style={{ color: "white" }} />} />

            <PresentationBox
                name={"Reservations"}
                balance={bookingData?.reservations}
                icon={<DirectionsCar style={{ color: "white" }} />} />

            <PresentationBox
                name={"Reservation Average"}
                balance={bookingData?.reservationAverage}
                icon={<ThumbUp style={{ color: "white" }} />} />

            <PresentationBox
                name={"Reservations From Website"}
                balance={bookingData?.reservationFromWebSite}
                icon={<Computer style={{ color: "white" }} />} />

            <PresentationBox
                name={"Reservations with Additional Charges"}
                balance={bookingData?.reservationWithAditionalCharges}
                icon={<ShoppingCartCheckout style={{ color: "white" }} />} />

            <PresentationBox
                name={"Average Rental Days"}
                balance={bookingData?.averageRentalDays}
                icon={<ShoppingCartOutlined style={{ color: "white" }} />} />

        </div>
    )
}

export default AmountPresentation

type PresentationBoxProps = {
    name: string;
    balance: number;
    bgColor?: string;
    icon: any;
}

const PresentationBox = ({ name, balance, bgColor = colorConfigs.topbar.bg, icon }: PresentationBoxProps) => {
    const monetaryItems = ["Revenue", "Amount Paid", "Outstanding Balance", "Reservation Average"];

    return (
        <Box sx={{ background: "#ECEFF1", padding: "8px 16px", width: { sm: "16%", md: "24%" } }}>
            <Box sx={{ display: "flex", flexDirection: { sm: "column", md: "row" }, alignItems: "center", gap: { sm: "4px", md: "8px" } }}>
                <Box sx={{ width: { sm: 32, md: 40 }, height: { sm: 32, md: 40 }, backgroundColor: bgColor, padding: { sm: "4px", md: "8px" }, borderRadius: "50%" }}>
                    {icon}
                </Box>
                <div>
                    <Typography variant="h3" sx={{ fontSize: { sm: "12px", md: "16px" }, fontWeight: "bold" }}>
                    {monetaryItems.includes(name) ? "$" : ""}{new Intl.NumberFormat('en-us').format(balance)}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: { sm: "8px", md: "12px" } }}>
                        {name}
                    </Typography>
                </div>
            </Box>
        </Box>
    )
}