import { Toolbar, Box, Typography, Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import baseURL from '../../../../configs/api';
import colorConfigs from '../../../../configs/colorConfigs';
import LayersIcon from '@mui/icons-material/Layers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import moment from 'moment-timezone';
import Select from 'react-select';
import { validateMin } from '../../../../utils/validateMin';

export default function AddBranch() {
  const [isFinancialSpecifications, setIsFinancialSpecifications] = useState<boolean>(false);
  const [isAdvancedBranchSettings, setIsAdvancedBranchSettings] = useState<boolean>(false);
  const [isTextSettings, setIsTextSettings] = useState<boolean>(false);
  const [isOnlineBookings, setIsOnlineBookings] = useState<boolean>(false);

  const [branchName, setBranchName] = useState<string>("");
  const [websiteLink, setWebsiteLink] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [prefixForReservation, setPrefixForReservation] = useState<string>("");
  const [supportedCurrencies, setSupportedCurrencies] = useState<string>("");
  const [locationFeeLabel, setLocationFeeLabel] = useState<string>("");
  const [locationFee, setLocationFee] = useState<string>("");
  const [taxLabel, setTaxLabel] = useState<string>("");
  const [tax, setTax] = useState<string>("");
  const [defaultFuelPricePerLiterOrGallon, setDefaultFuelPricePerLiterOrGallon] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [minHoursForDay, setMinHoursForDay] = useState<string>("");
  const [minAllowedHoursToBook, setMinAllowedHoursToBook] = useState<string>("");
  const [timeZone, setTimeZone] = useState('');
  const [timezoneOptions, setTimezoneOptions] = useState<any[]>([]);

  useEffect(() => {
    const getTimezoneOptions = () => {
      const timezones = moment.tz.names();
      return timezones.map((timezone) => {
        const offset = moment.tz(timezone).format('Z');
        const value = `(GMT ${offset}) ${timezone} ${moment.tz(timezone).zoneAbbr()}`;
        const label = `(GMT ${offset}) ${timezone} ${moment.tz(timezone).zoneAbbr()}`;
        return { value, label };
      });
    };

    setTimezoneOptions(getTimezoneOptions());
  }, []);

  const navigate = useNavigate();

  const onChangePhoneNumber = (value: string) => {
    const { length } = value;

    const regex = () => value.replace(/[^\d.]+/g, '');

    const areaCode = () => `(${regex().slice(0, 3)})`;

    const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`;

    const trailer = (start: number) => `${regex().slice(start, regex().length)}`;
    if (length < 3) {
      setPhoneNumber(regex());
    } else if (length >= 3 && length < 10) {
      setPhoneNumber(`${areaCode()} ${trailer(3)}`);
    } else if (length === 5) {
      setPhoneNumber(`${areaCode().replace(")", "")}`);
    } else if (length >= 10 && length < 15) {
      setPhoneNumber(`${firstSix()}-${trailer(6)}`);
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await axios
      .post(
        `${baseURL}branch`,
        JSON.stringify({
          branchName,
          websiteLink,
          emailAddress,
          prefixForReservation,
          supportedCurrencies,
          locationFeeLabel,
          locationFee,
          taxLabel,
          tax,
          defaultFuelPricePerLiterOrGallon,
          isImages: false,
          isActive: false,
          isCalenderView: false,
          defaultLocationId: 0,
          governmentTax: 0,
          phoneNumber: phoneNumber,
          minHoursForDay: minHoursForDay,
          timeZone,
          minAllowedHoursToBook: minAllowedHoursToBook
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  return (
    <>
      <Toolbar sx={{ width: "100%", justifyContent: "space-between" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <LayersIcon sx={{ fontSize: "24px", marginRight: "5px" }} />
          <Typography variant="body1" sx={{ fontSize: "20px" }}>
            Branches - Add Branch
          </Typography>
        </Box>
      </Toolbar>

      <hr style={{ boxShadow: "0 20px 20px -20px #333" }} />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingBottom: "24px",
          paddingLeft: "30px",
          paddingRight: "30px"
        }}
      >
        <div
          style={{
            width: "100%",
            marginRight: "3%",
          }}
        >
          <h5 style={{ paddingBottom: "0px", marginBottom: "0px" }}>
            Branch Overview
          </h5>
          <hr />

          <form
            onSubmit={(e) => handleSubmit(e)}>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >

              <div
                style={{
                  width: "50%",
                  marginRight: "3%",
                }}
              >

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="name"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Name
                    <br />
                    <input
                      type="text"
                      name="name"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={branchName}
                      onChange={(e) => setBranchName(e.target.value)}
                      required
                    />
                  </label>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="emailAddress"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Email Address
                      <br />
                      <input
                        type="email"
                        name="emailAddress"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="image"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Phone Number
                      <br />
                      <input
                        type="text"
                        name="phoneNumber"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={phoneNumber}
                        onChange={(e) => onChangePhoneNumber(e.target.value)}
                        required
                      />
                    </label>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="minAllowedHoursToBook"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      Minimum Allowed Hours To Book
                      <br />
                      <input
                        type="number"
                        min={0}
                        name="minAllowedHoursToBook"
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          paddingBlock: "5px",
                        }}
                        value={minAllowedHoursToBook}
                        onChange={(e) => setMinAllowedHoursToBook(validateMin(e.target.value, 0).toString())}
                        required
                      />
                    </label>
                  </div>

                </div>
              </div>

              <div
                style={{
                  width: "50%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="websiteLink"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Website Link
                    <br />
                    <input
                      type="text"
                      name="websiteLink"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={websiteLink}
                      onChange={(e) => setWebsiteLink(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="prefixForReservation"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Prefix for Reservation #
                    <HelpOutlineOutlinedIcon sx={{ fontSize: "12px" }} />
                    <br />
                    <input
                      type="text"
                      name="prefixForReservation"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={prefixForReservation}
                      onChange={(e) => setPrefixForReservation(e.target.value)}
                      required
                    />
                  </label>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <label
                    htmlFor="image"
                    style={{ fontSize: "12px", fontStyle: "bold" }}
                  >
                    Minimum Hours For Day
                    <br />
                    <input
                      type="number"
                      min={0}
                      name="minHoursForDay"
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        paddingBlock: "5px",
                      }}
                      value={minHoursForDay}
                      onChange={(e) => setMinHoursForDay(validateMin(e.target.value, 0).toString())}
                      required
                    />
                  </label>
                </div>

              </div>
            </div>

            <h5 style={{ paddingBottom: "0px", marginBottom: "0px", display: "flex", justifyContent: "start", alignItems: "center", cursor: "pointer" }} onClick={() => setIsFinancialSpecifications(!isFinancialSpecifications)}>
              {isFinancialSpecifications ? <ExpandMoreIcon sx={{ fontSize: "20px" }} /> : <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />}  Financial Specifications
            </h5>
            <hr />

            {isFinancialSpecifications &&
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >

                  <div
                    style={{
                      width: "50%",
                      marginRight: "3%",
                    }}
                  >

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="supportedCurrencies"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Supported Currencies
                        <br />
                        <input
                          type="text"
                          name="supportedCurrencies"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={supportedCurrencies}
                          onChange={(e) => setSupportedCurrencies(e.target.value)}
                          required
                        />
                      </label>

                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="locationFee"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Location Fee (%)
                        <br />
                        <input
                          type="number"
                          min={0}
                          name="locationFee"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={locationFee}
                          onChange={(e) => setLocationFee(validateMin(e.target.value, 0).toString())}
                          required
                        />
                      </label>

                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="tax"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Tax (%)
                        <br />
                        <input
                          type="number"
                          min={0}
                          name="tax"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={tax}
                          onChange={(e) => setTax(validateMin(e.target.value, 0).toString())}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="defaultFuelPricePerLiterOrGallon"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Default Fuel Price Per Liter or Gallon
                        <br />
                        <input
                          type="text"
                          name="emadefaultFuelPricePerLiterOrGallonilAddress"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={defaultFuelPricePerLiterOrGallon}
                          onChange={(e) => setDefaultFuelPricePerLiterOrGallon(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                  </div>

                  <div
                    style={{
                      width: "50%",
                    }}
                  >
                    <div style={{ marginTop: "10px", visibility: "hidden" }}>
                      <label
                        htmlFor="locationFeeLabel"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Location Fee Label
                        <br />
                        <input
                          type="text"
                          name="locationFeeLabel"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={locationFeeLabel}
                          onChange={(e) => setLocationFeeLabel(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="locationFeeLabel"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Location Fee Label
                        <br />
                        <input
                          type="text"
                          name="locationFeeLabel"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={locationFeeLabel}
                          onChange={(e) => setLocationFeeLabel(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      <label
                        htmlFor="taxLabel"
                        style={{ fontSize: "12px", fontStyle: "bold" }}
                      >
                        Tax Label
                        <br />
                        <input
                          type="text"
                          name="taxLabel"
                          style={{
                            width: "100%",
                            fontSize: "14px",
                            paddingBlock: "5px",
                          }}
                          value={taxLabel}
                          onChange={(e) => setTaxLabel(e.target.value)}
                          required
                        />
                      </label>
                    </div>

                  </div>
                </div>
              </>
            }

            <h5 style={{ paddingBottom: "0px", marginBottom: "0px", display: "flex", justifyContent: "start", alignItems: "center", cursor: "pointer" }} onClick={() => setIsAdvancedBranchSettings(!isAdvancedBranchSettings)}>
              {isAdvancedBranchSettings ? <ExpandMoreIcon sx={{ fontSize: "20px" }} /> : <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />}  Advanced Branch Settings
            </h5>
            <hr />

            {isAdvancedBranchSettings &&
              <>
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  <div style={{ marginTop: "10px" }}>
                    <label
                      htmlFor="timezone"
                      style={{ fontSize: "12px", fontStyle: "bold" }}
                    >
                      TimeZone
                      <br />
                      <Select
                        placeholder='Select Time Zone'
                        value={timezoneOptions.find(option => option.value === timeZone)}
                        options={timezoneOptions}
                        onChange={(selectedOption) => setTimeZone(selectedOption ? selectedOption.value : '')}
                        isSearchable
                        styles={{
                          control: (styles) => ({
                            ...styles,
                            width: "100%",
                            fontSize: "14px",
                          }),
                          menu: (styles) => ({
                            ...styles,
                            marginBottom: "40px",
                            height: "auto",
                          }),
                        }}
                      />
                    </label>
                  </div>
                </div>
              </>
            }

            <h5 style={{ paddingBottom: "0px", marginBottom: "0px", display: "flex", justifyContent: "start", alignItems: "center", cursor: "pointer" }} onClick={() => setIsTextSettings(!isTextSettings)}>
              {isTextSettings ? <ExpandMoreIcon sx={{ fontSize: "20px" }} /> : <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />}  Text Settings
            </h5>
            <hr />

            {isTextSettings &&
              <>
                <label>Text Settings</label>
              </>
            }

            <h5 style={{ paddingBottom: "0px", marginBottom: "0px", display: "flex", justifyContent: "start", alignItems: "center", cursor: "pointer" }} onClick={() => setIsOnlineBookings(!isOnlineBookings)}>
              {isOnlineBookings ? <ExpandMoreIcon sx={{ fontSize: "20px" }} /> : <KeyboardArrowRightIcon sx={{ fontSize: "20px" }} />}  Online Bookings
            </h5>
            <hr />

            {isOnlineBookings &&
              <>
                <label>Online Bookings</label>
              </>
            }

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                marginTop: "20px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: colorConfigs.topbar.bg,
                  color: "white",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Save
              </Button>

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#e0dee0",
                  color: "black",
                  borderRadius: "0",
                  border: "none",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </div>

          </form>

        </div>


      </div>
    </>
  );
}
